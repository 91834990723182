import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Dropdown, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import Axios from "axios";

function Workout() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [newData, setNewData] = useState({
    title: "",
    description: "",
    tags: "",
    url: "",
    thumbnailUrl: "",
    workoutType: [],
    focusArea: [],
    difficulty: "n",
  });

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50px symbol-light mr-4">
              <img src={row?.thumbnail} className="img-fluid" onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/media/img/fitnessLogo.png";
              }} />
            </div>
            <div>
              <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {cell ? cell : "-"}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "Tags",
      text: "Tags",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell.join(", ") : "-"}</div>;
      },
    },
    {
      dataField: "difficulty",
      text: "Difficulty",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {cell[0] === "0"
              ? "Advanced"
              : cell[0] === "1"
                ? "Haladó (Harcosképző)"
                : cell[0] === "2"
                  ? "Kezdő (Light)"
                  : "-"}
          </div>
        );
      },
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell && moment(cell).format("DD-MM-YYYY HH:mm A")}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => editBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>

            <a
              title="Delete customer"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to Delete ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Delete`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onDelete(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </a>
          </>
        );
      },
    },
  ];

  const handleChange = (e) => {
    const { value, name } = e.target;
    setNewData({ ...newData, [name]: value });
  };
  console.log("newData", newData);
  const editBtnClick = (row) => {
    let typeArr = [];
    let areaArr = [];
    console.log("row", row);
    setIsEdit(true);
    setModal(true);
    let tag = row?.Tags?.length > 1 ? row.Tags.join(", ") : String(row?.Tags);
    let a = row?.workoutType?.map((v) => {
      typeArr.push({ value: v, label: v });
    });
    let b = row?.focusArea?.map((v) => {
      areaArr.push({ value: v, label: v });
    });
    setNewData({
      id: row?._id,
      title: row?.title,
      description: row?.description,
      tags: tag,
      url: row?.url,
      thumbnailUrl: row?.thumbnail,
      workoutType: typeArr,
      focusArea: areaArr,
      difficulty: row?.difficulty[0],
    });
  };
  const onDelete = async (Id) => {
    await ApiPost(`/workout/delete/${Id}`, {})
      .then(() => {
        Swal.fire({
          text: "You have Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onUpdate = async () => {
    let tagArr = [];
    let typeArr = [];
    let areaArr = [];
    if (newData?.tags.includes(",")) {
      tagArr = newData?.tags.split(", ");
    } else {
      tagArr = [newData?.tags];
    }
    if (newData?.workoutType) {
      let a = newData?.workoutType?.filter((v) => {
        typeArr.push(v?.value);
        return;
      });
    }
    if (newData?.focusArea) {
      let b = newData?.focusArea?.filter((v) => {
        areaArr.push(v?.value);
        return;
      });
    }
    let body = {
      id: newData?.id,
      title: newData?.title,
      thumbnail: newData?.thumbnailUrl,
      url: newData?.url,
      description: newData?.description,
      workoutType: typeArr.length === 0 ? null : typeArr,
      focusArea: areaArr.length === 0 ? null : areaArr,
      difficulty: newData?.difficulty,
      Tags: tagArr,
    };
    console.log("body", body);
    await ApiPut("/workout/update", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const handleSubmit = async () => {
    let tagArr = [];
    let typeArr = [];
    let areaArr = [];
    if (newData?.tags.includes(",")) {
      tagArr = newData?.tags.split(", ");
    } else {
      tagArr = [newData?.tags];
    }
    if (newData?.workoutType) {
      let a = newData?.workoutType?.filter((v) => {
        typeArr.push(v?.value);
        return;
      });
    }
    if (newData?.focusArea) {
      let b = newData?.focusArea?.filter((v) => {
        areaArr.push(v?.value);
        return;
      });
    }

    let body = {
      title: newData?.title,
      thumbnail: newData?.thumbnailUrl,
      url: newData?.url,
      description: newData?.description,
      workoutType: typeArr.length === 0 ? null : typeArr,
      focusArea: areaArr.length === 0 ? null : areaArr,
      difficulty: newData?.difficulty,
      Tags: tagArr,
    };

    await ApiPost("/workout/add", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  // const getPlaylistData = async () => {
  //   await ApiGet("/video_playlist")
  //     .then((res) => setPlaylistData(res.data.data))
  //     .catch((err) => ErrorToast(err?.message));
  // };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  const imageChange = async (e) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("upload/compress_image/profile_image", formData)
      .then((res) => {
        setNewData({ ...newData, thumbnailUrl: res?.data?.data?.image });
      })
      .catch((err) => console.log("res_blob", err));
  };
  const handlesearch = (e) => {
    setsearching(e.target.value);
    fetchData(currentpage, pagesize, e.target.value);
  };

  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    await ApiPost("/workout/get", body)
      .then((res) => {
        console.log("/workout/get", res?.data?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_count);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Workout List</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Workout
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              {/* <h3 class="card-label">Video Playlist</h3> */}
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Name"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="card-toolbar">
              <a
                class="btn btn-color font-weight-bolder btn-sm"
                onClick={() => {
                  setIsEdit(false);
                  setModal(true);
                  setNewData({
                    title: "",
                    description: "",
                    tags: "",
                    url: "",
                    thumbnailUrl: "",
                    workoutType: [],
                    focusArea: [],
                    difficulty: "n",
                  });
                }}
              >
                Add Workout
              </a>
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => <NoDataTable />}
            // filter={filterFactory()}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Workout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group className="col-md-12">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                label="Title"
                id="title"
                required
                name="title"
                onChange={handleChange}
                value={newData.title}
              />
              <span className="errorInput">
                {/* {newData.title?.length > 0 ? "" : errors["title"]} */}
              </span>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Tag</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tags"
                label="tags"
                id="tags"
                required
                name="tags"
                onChange={handleChange}
                value={newData.tags}
              />
              <span className="errorInput">
                {/* {newData.tag?.length > 0 ? "" : errors["tag"]} */}
              </span>
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Workout Type (Edzés típusa)</Form.Label>
              <Select
                isMulti
                name="colors"
                placeholder="Select Workout Type"
                isClearable={false}
                options={[
                  { value: "Hasizom", label: "Hasizom" },
                  { value: "Farizom", label: "Farizom" },
                  { value: "Feszes Láb", label: "Feszes Láb" },
                  { value: "Felsőtest", label: "Felsőtest" },
                  {
                    value: "Ugrálós Teljes Test",
                    label: "Ugrálós Teljes Test",
                  },
                  {
                    value: "Ugrálás-Mentes Teljes Test",
                    label: "Ugrálás-Mentes Teljes Test",
                  },
                  { value: "8-20p Teljes Test", label: "8-20p Teljes Test" },
                  { value: "30-60p Teljes Test", label: "30-60p Teljes Test" },
                  { value: "Jóga", label: "Jóga" },
                  { value: "Split Challenge", label: "Split Challenge" },
                  { value: "Meditáció", label: "Meditáció" },
                  { value: "Original Cardio", label: "Original Cardio" },
                  { value: "LIVE", label: "LIVE" },
                  { value: "Erősítő Gumiszalag", label: "Erősítő Gumiszalag" },
                ]}
                className="basic-multi-select"
                classNamePrefix="selected"
                value={newData?.workoutType}
                onChange={(e) => setNewData({ ...newData, workoutType: e })}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                    padding: "0 10px",
                    fontSize: "1.1rem",
                    "&:hover": {
                      backgroundColor: "#007eff",
                      color: "white",
                      fontWeight: "500",
                    },
                    "&:focus-visible": {
                      backgroundColor: "red",
                      color: "white",
                      fontWeight: "500",
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    margin: "0",
                  }),
                  placeholder: (provided) => {
                    return {
                      ...provided,
                      color: "#181c32",
                      fontWeight: "500",
                    };
                  },
                }}
              />
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label>Focus Area (Mire edzenél?)</Form.Label>
              <Select
                isMulti
                name="colors"
                placeholder="Select Focus Area"
                isClearable={false}
                options={[
                  { value: "Hasizom", label: "Hasizom" },
                  { value: "Farizom", label: "Farizom" },
                  { value: "Láb", label: "Láb" },
                  { value: "Felsőtest", label: "Felsőtest" },
                  { value: "Hát", label: "Hát" },
                  { value: "Kar", label: "Kar" },
                  { value: "Teljes Test", label: "Teljes Test" },
                ]}
                className="basic-multi-select"
                classNamePrefix="selected"
                value={newData?.focusArea}
                onChange={(e) => setNewData({ ...newData, focusArea: e })}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                    padding: "0 10px",
                    fontSize: "1.1rem",
                    "&:hover": {
                      backgroundColor: "#007eff",
                      color: "white",
                      fontWeight: "500",
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    margin: "0",
                  }),
                  placeholder: (provided) => {
                    return {
                      ...provided,
                      color: "#181c32",
                      fontWeight: "500",
                    };
                  },
                }}
              />
              {/* <span className="errorInput">
                {data.playlist?.length > 0 ? "" : errors["playlist"]}
              </span> */}
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label>Difficulty (Nehézségi szint)</Form.Label>
              <Form.Control
                as="select"
                custom
                id="difficulty"
                required
                className="form-control"
                name="difficulty"
                value={newData.difficulty}
                onChange={handleChange}
              >
                <option value="n" disabled>
                  Select Difficulty
                </option>
                {/* <option value="0">Advanced</option> */}
                <option value="1">Haladó (Harcosképző)</option>
                <option value="2">Kezdő (Light)</option>
              </Form.Control>
              {/* <span className="errorInput">
                {data.playlist?.length > 0 ? "" : errors["playlist"]}
              </span> */}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Video URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter video Url"
                label="url"
                id="url"
                required
                name="url"
                onChange={handleChange}
                value={newData.url}
              />
              <span className="errorInput">
                {/* {newData.url?.length > 0 ? "" : errors["url"]} */}
              </span>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Thumbnail Image</Form.Label>
              <Form.Control
                type="file"
                label="thumbnailUrl"
                id="thumbnailUrl"
                required
                className="form-control"
                name="thumbnailUrl"
                onChange={imageChange}
              />
              <span className="errorInput">
                {/* {newData.thumbnailUrl?.length > 0 ? "" : errors["thumbnailUrl"]} */}
              </span>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Description"
                label="url"
                id="description"
                required
                name="description"
                onChange={handleChange}
                value={newData.description}
              />
              <span className="errorInput">
                {/* {newData.description?.length > 0 ? "" : errors["description"]} */}
              </span>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button className="btn btn-color" onClick={onUpdate}>
              Update Changes
            </button>
          ) : (
            <button className="btn btn-color" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Workout;
