import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import { FaPlus } from "react-icons/fa";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPostInce,
  ApiPut,
  ApiUpload,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { AiOutlineMinusCircle } from "react-icons/ai";
import PlanPreview from "./PlanPreview";
import { MdCancel } from "react-icons/md";

function Plan() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [tabPan, setTabPan] = useState(1);
  const [loading, setLoading] = useState(false);
  const [workoutData, setWorkoutData] = useState([]);
  const [planRes, setPlanRes] = useState({});
  const [chkOutErr, setChkOutErr] = useState({});
  const [updateLoading, setUpdateLoading] = useState(false);
  const [equipmentData, setEquipmentData] = useState([]);
  const [mainLoader, setMainLoader] = useState(false);
  const [step1Data, setStep1Data] = useState({
    id: "",
    title: "",
    description: "",
    intensity: "",
    duration: "",
    equipment: [],
    thumbnail: "",
    certificateBackgroundImage: ""
  });
  const [step2Data, setStep2Data] = useState({
    calories: "",
    dayId: "",
    exercise: "",
  });
  const [step3Data, setStep3Data] = useState({
    calories: "",
    dayId: "",
    exercise: "",
  });
  const [step4Data, setStep4Data] = useState({
    calories: "",
    dayId: "",
    exercise: "",
  });
  const [step5Data, setStep5Data] = useState({
    calories: "",
    dayId: "",
    exercise: "",
  });
  const [step6Data, setStep6Data] = useState({
    calories: "",
    dayId: "",
    exercise: "",
  });
  const [step7Data, setStep7Data] = useState({
    calories: "",
    dayId: "",
    exercise: "",
  });
  const [step8Data, setStep8Data] = useState({
    calories: "",
    dayId: "",
    exercise: "",
  });
  const [restDay1, setRestDay1] = useState(false);
  const [restDay2, setRestDay2] = useState(false);
  const [restDay3, setRestDay3] = useState(false);
  const [restDay4, setRestDay4] = useState(false);
  const [restDay5, setRestDay5] = useState(false);
  const [restDay6, setRestDay6] = useState(false);
  const [restDay7, setRestDay7] = useState(false);
  const [newData, setNewData] = useState({
    title: "",
    description: "",
    tags: "",
    url: "",
    thumbnailUrl: "",
    workoutType: [],
    focusArea: [],
    difficulty: "n",
    certificateBackgroundImage: ""
  });
  console.log("workoutData", workoutData);
  const [inputFields1, setInputFields1] = useState([
    { videoId: "", videoLabel: "" },
  ]);
  const [inputFields2, setInputFields2] = useState([
    { videoId: "", videoLabel: "" },
  ]);
  const [inputFields3, setInputFields3] = useState([
    { videoId: "", videoLabel: "" },
  ]);
  const [inputFields4, setInputFields4] = useState([
    { videoId: "", videoLabel: "" },
  ]);
  const [inputFields5, setInputFields5] = useState([
    { videoId: "", videoLabel: "" },
  ]);
  const [inputFields6, setInputFields6] = useState([
    { videoId: "", videoLabel: "" },
  ]);
  const [inputFields7, setInputFields7] = useState([
    { videoId: "", videoLabel: "" },
  ]);
  const addPlanBtn = () => {
    setChkOutErr({});
    setIsEdit(false);
    setModal(true);
    setTabPan(1);
    setStep1Data({
      id: "",
      title: "",
      description: "",
      intensity: "",
      duration: "",
      equipment: [],
      thumbnail: "",
    });
    setRestDay1(false);
    setRestDay2(false);
    setRestDay3(false);
    setRestDay4(false);
    setRestDay5(false);
    setRestDay6(false);
    setRestDay7(false);
    setStep2Data({ calories: "", dayId: "", exercise: "" });
    setStep3Data({ calories: "", dayId: "", exercise: "" });
    setStep4Data({ calories: "", dayId: "", exercise: "" });
    setStep5Data({ calories: "", dayId: "", exercise: "" });
    setStep6Data({ calories: "", dayId: "", exercise: "" });
    setStep7Data({ calories: "", dayId: "", exercise: "" });
    setStep8Data({ calories: "", dayId: "", exercise: "" });
    setInputFields1([{ videoId: "", videoLabel: "" }]);
    setInputFields2([{ videoId: "", videoLabel: "" }]);
    setInputFields3([{ videoId: "", videoLabel: "" }]);
    setInputFields4([{ videoId: "", videoLabel: "" }]);
    setInputFields5([{ videoId: "", videoLabel: "" }]);
    setInputFields6([{ videoId: "", videoLabel: "" }]);
    setInputFields7([{ videoId: "", videoLabel: "" }]);
  };
  // ************************************************Step 1
  const onChangeStep1 = (e) => {
    setStep1Data({ ...step1Data, [e.target.name]: e.target.value });
  };
  console.log("checkouterr", chkOutErr);
  console.log("step1Data", step1Data);
  const validate = (values, name) => {
    const err = {};

    if (name === "planDetails") {
      if (!values.title) {
        err.title = "Plan Name required!";
      }
      if (!values.description) {
        err.description = "Description required!";
      }
      if (!values.thumbnail) {
        err.thumbnail = "Image required!";
      }
      if (!values.certificateBackgroundImage) {
        err.certificateBackgroundImage = "Certificate Image required!";
      }
      if (!values.intensity) {
        err.intensity = "Intensity required!";
      }
      if (!values.duration) {
        err.duration = "Duration required!";
      }
    } else {
      if (!values.calories) {
        err.calories = "Calories required";
      }
      if (!values.exercise) {
        err.exercise = "Exercise Name required";
      }
    }
    return err;
  };
  console.log("step1Data", step1Data);

  const submitStep1 = async () => {
    let equip = [];
    if (step1Data?.equipment !== []) {
      let b = step1Data?.equipment?.filter((v) => {
        equip.push(v?.value);
      });
    }
    setChkOutErr(validate(step1Data, "planDetails"));
    if (step1Data?.title &&
      step1Data?.description &&
      step1Data?.thumbnail &&
      step1Data?.intensity &&
      step1Data?.duration &&
      step1Data?.certificateBackgroundImage) {
      setLoading(true)
      if (!isEdit) {
        let body = {
          title: step1Data?.title,
          description: step1Data?.description,
          intensity: step1Data?.intensity,
          duration: step1Data?.duration,
          equipment: equip,
          thumbnail: step1Data?.thumbnail || "",
          certificateBackgroundImage: step1Data?.certificateBackgroundImage,
          status: "0",
        };
        await ApiPost("/plan/add", body)
          .then((res) => {
            console.log("/plan/add", res);
            setPlanRes(res?.data?.data);
            setTabPan(tabPan + 1);
            setStep1Data({ ...step1Data, id: res?.data?.data?._id });
            setChkOutErr({});
          })
          .catch((err) => {
            ErrorToast(err?.message);
          });

      } else {
        let body = {
          id: step1Data?.id,
          title: step1Data?.title,
          description: step1Data?.description,
          intensity: step1Data?.intensity,
          duration: step1Data?.duration,
          equipment: equip,
          thumbnail: step1Data?.thumbnail || "",
          certificateBackgroundImage: step1Data?.certificateBackgroundImage || "",
          status: "0",
        };
        await ApiPut("/plan/update", body)
          .then((res) => {
            console.log("/plan/update", res);
            setPlanRes(res?.data?.data);
            setTabPan(tabPan + 1);
            setChkOutErr({});
          })
          .catch((err) => {
            ErrorToast(err?.message);
          });

      }
      setLoading(false)
    }

  };
  // ************************************************Step 2
  const handleFormChange1 = (index, event) => {
    let data = [...inputFields1];
    data[index]["videoId"] = event.value;
    data[index]["videoLabel"] = event.label;
    setInputFields1(data);
  };
  const addFields1 = () => {
    let newField = { videoId: "", videoLabel: "" };
    setInputFields1([...inputFields1, newField]);
  };
  const removeFields1 = (index) => {
    let data = [...inputFields1];
    data.splice(index, 1);
    setInputFields1(data);
  };
  console.log("inputFields1", inputFields1);
  const submitStep2 = async (loading) => {
    setChkOutErr({});
    let workoutArr = [];
    let a = inputFields1?.map((v) => {
      if (v.videoId !== "") {
        workoutArr.push(v?.videoId);
      }
      return;
    });
    let body = {};
    if (!isEdit) {
      if (restDay1) {
        body = {
          day: 0,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
        };
      } else {
        body = {
          day: 0,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step2Data?.calories ? `${step2Data?.calories} Kcal` : "",
          exercise: step2Data?.exercise || "",
        };
      }
      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (isEdit && step2Data?.dayId) {
      if (restDay1) {
        console.log(restDay1);
        body = {
          day: 0,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
          _id: step2Data?.dayId,
        };
      } else {
        body = {
          day: 0,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step2Data?.calories ? `${step2Data?.calories} Kcal` : "",
          exercise: step2Data?.exercise || "",
          _id: step2Data?.dayId,
        };
      }
      ApiPut("/admin_plan/update", body)
        .then((res) => {
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    } else {
      body = {
        day: 0,
        videoId: workoutArr,
        planId: (planRes && planRes?._id) || step1Data?.id,
        calories: step2Data?.calories ? `${step2Data?.calories} Kcal` : "",
        exercise: step2Data?.exercise || "",
      };

      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };
  // ************************************************Step 3
  const handleFormChange2 = (index, event) => {
    let data = [...inputFields2];
    data[index]["videoId"] = event.value;
    data[index]["videoLabel"] = event.label;
    setInputFields2(data);
  };
  const addFields2 = () => {
    let newField = { videoId: "", videoLabel: "" };
    setInputFields2([...inputFields2, newField]);
  };
  const removeFields2 = (index) => {
    let data = [...inputFields2];
    data.splice(index, 1);
    setInputFields2(data);
  };
  console.log("inputFields2", inputFields2);
  // const submitStep3 = async () => {
  //   let workoutArr = [];
  //   let a = inputFields2?.map((v) => {
  //     if (v.videoId !== "") {
  //       workoutArr.push(v?.videoId);
  //     }
  //     return;
  //   });
  //   if (!isEdit) {
  //     let body = {
  //       day: 1,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step3Data?.calories ? `${step3Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else if (isEdit && step3Data?.dayId) {
  //     let body = {
  //       day: 1,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step3Data?.calories ? `${step3Data?.calories} Kcal` : "",
  //       _id: step3Data?.dayId,
  //     };
  //     console.log("body", body);
  //     await ApiPut("/admin_plan/update", body)
  //       .then((res) => {
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else {
  //     let body = {
  //       day: 1,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step3Data?.calories ? `${step3Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   }
  // };
  const submitStep3 = async (loading) => {
    setChkOutErr({});
    let workoutArr = [];
    let a = inputFields2?.map((v) => {
      if (v.videoId !== "") {
        workoutArr.push(v?.videoId);
      }
      return;
    });
    let body = {};
    if (!isEdit) {
      if (restDay2) {
        body = {
          day: 1,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
        };
      } else {
        body = {
          day: 1,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step3Data?.calories ? `${step3Data?.calories} Kcal` : "",
          exercise: step3Data?.exercise || "",
        };
      }
      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (isEdit && step3Data?.dayId) {
      if (restDay2) {
        console.log(restDay2);
        body = {
          day: 1,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
          _id: step3Data?.dayId,
        };
      } else {
        body = {
          day: 1,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step3Data?.calories ? `${step3Data?.calories} Kcal` : "",
          exercise: step3Data?.exercise || "",
          _id: step3Data?.dayId,
        };
        setChkOutErr({});
      }
      ApiPut("/admin_plan/update", body)
        .then((res) => {
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    } else {
      body = {
        day: 1,
        videoId: workoutArr,
        planId: (planRes && planRes?._id) || step1Data?.id,
        calories: step3Data?.calories ? `${step3Data?.calories} Kcal` : "",
        exercise: step3Data?.exercise || "",
      };

      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };
  // ************************************************Step 4
  const handleFormChange3 = (index, event) => {
    let data = [...inputFields3];
    data[index]["videoId"] = event.value;
    data[index]["videoLabel"] = event.label;
    setInputFields3(data);
  };
  const addFields3 = () => {
    let newField = { videoId: "", videoLabel: "" };
    setInputFields3([...inputFields3, newField]);
  };
  const removeFields3 = (index) => {
    let data = [...inputFields3];
    data.splice(index, 1);
    setInputFields3(data);
  };
  console.log("inputFields3", inputFields3);
  // const submitStep4 = async () => {
  //   let workoutArr = [];
  //   let a = inputFields3?.map((v) => {
  //     if (v.videoId !== "") {
  //       workoutArr.push(v?.videoId);
  //     }
  //     return;
  //   });
  //   if (!isEdit) {
  //     let body = {
  //       day: 2,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step4Data?.calories ? `${step4Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else if (isEdit && step4Data?.dayId) {
  //     let body = {
  //       day: 2,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step4Data?.calories ? `${step4Data?.calories} Kcal` : "",
  //       _id: step4Data?.dayId,
  //     };
  //     console.log("body", body);
  //     await ApiPut("/admin_plan/update", body)
  //       .then((res) => {
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else {
  //     let body = {
  //       day: 2,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step4Data?.calories ? `${step4Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   }
  // };
  const submitStep4 = async (loading) => {
    setChkOutErr({});
    let workoutArr = [];
    let a = inputFields3?.map((v) => {
      if (v.videoId !== "") {
        workoutArr.push(v?.videoId);
      }
      return;
    });
    let body = {};
    if (!isEdit) {
      if (restDay3) {
        body = {
          day: 2,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
        };
      } else {
        body = {
          day: 2,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step4Data?.calories ? `${step4Data?.calories} Kcal` : "",
          exercise: step4Data?.exercise || "",
        };
      }
      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (isEdit && step4Data?.dayId) {
      if (restDay3) {
        console.log(restDay3);
        body = {
          day: 2,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
          _id: step4Data?.dayId,
        };
      } else {
        body = {
          day: 2,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step4Data?.calories ? `${step4Data?.calories} Kcal` : "",
          exercise: step4Data?.exercise || "",
          _id: step4Data?.dayId,
        };
      }
      ApiPut("/admin_plan/update", body)
        .then((res) => {
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    } else {
      body = {
        day: 2,
        videoId: workoutArr,
        planId: (planRes && planRes?._id) || step1Data?.id,
        calories: step4Data?.calories ? `${step4Data?.calories} Kcal` : "",
        exercise: step4Data?.exercise || "",
      };

      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };
  // ************************************************Step 5
  const handleFormChange4 = (index, event) => {
    let data = [...inputFields4];
    data[index]["videoId"] = event.value;
    data[index]["videoLabel"] = event.label;
    setInputFields4(data);
  };
  const addFields4 = () => {
    let newField = { videoId: "", videoLabel: "" };
    setInputFields4([...inputFields4, newField]);
  };
  const removeFields4 = (index) => {
    let data = [...inputFields4];
    data.splice(index, 1);
    setInputFields4(data);
  };
  console.log("inputFields4", inputFields4);
  // const submitStep5 = async () => {
  //   let workoutArr = [];
  //   let a = inputFields4?.map((v) => {
  //     if (v.videoId !== "") {
  //       workoutArr.push(v?.videoId);
  //     }
  //     return;
  //   });
  //   if (!isEdit) {
  //     let body = {
  //       day: 3,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step5Data?.calories ? `${step5Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else if (isEdit && step5Data?.dayId) {
  //     let body = {
  //       day: 3,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step5Data?.calories ? `${step5Data?.calories} Kcal` : "",
  //       _id: step5Data?.dayId,
  //     };
  //     console.log("body", body);
  //     await ApiPut("/admin_plan/update", body)
  //       .then((res) => {
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else {
  //     let body = {
  //       day: 3,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step5Data?.calories ? `${step5Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   }
  // };
  const submitStep5 = async (loading) => {
    setChkOutErr({});
    let workoutArr = [];
    let a = inputFields4?.map((v) => {
      if (v.videoId !== "") {
        workoutArr.push(v?.videoId);
      }
      return;
    });
    let body = {};
    if (!isEdit) {
      if (restDay4) {
        body = {
          day: 3,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
        };
      } else {
        body = {
          day: 3,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step5Data?.calories ? `${step5Data?.calories} Kcal` : "",
          exercise: step5Data?.exercise || "",
        };
      }
      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (isEdit && step5Data?.dayId) {
      if (restDay4) {
        console.log(restDay4);
        body = {
          day: 3,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
          _id: step5Data?.dayId,
        };
      } else {
        body = {
          day: 3,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step5Data?.calories ? `${step5Data?.calories} Kcal` : "",
          exercise: step5Data?.exercise || "",
          _id: step5Data?.dayId,
        };
      }
      ApiPut("/admin_plan/update", body)
        .then((res) => {
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    } else {
      body = {
        day: 3,
        videoId: workoutArr,
        planId: (planRes && planRes?._id) || step1Data?.id,
        calories: step5Data?.calories ? `${step5Data?.calories} Kcal` : "",
        exercise: step5Data?.exercise || "",
      };

      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };
  // ************************************************Step 6
  const handleFormChange5 = (index, event) => {
    let data = [...inputFields5];
    data[index]["videoId"] = event.value;
    data[index]["videoLabel"] = event.label;
    setInputFields5(data);
  };
  const addFields5 = () => {
    let newField = { videoId: "", videoLabel: "" };
    setInputFields5([...inputFields5, newField]);
  };
  const removeFields5 = (index) => {
    let data = [...inputFields5];
    data.splice(index, 1);
    setInputFields5(data);
  };
  console.log("inputFields5", inputFields5);
  // const submitStep6 = async () => {
  //   let workoutArr = [];
  //   let a = inputFields5?.map((v) => {
  //     if (v.videoId !== "") {
  //       workoutArr.push(v?.videoId);
  //     }
  //     return;
  //   });
  //   if (!isEdit) {
  //     let body = {
  //       day: 4,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step6Data?.calories ? `${step6Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else if (isEdit && step6Data?.dayId) {
  //     let body = {
  //       day: 4,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step6Data?.calories ? `${step6Data?.calories} Kcal` : "",
  //       _id: step6Data?.dayId,
  //     };
  //     console.log("body", body);
  //     await ApiPut("/admin_plan/update", body)
  //       .then((res) => {
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else {
  //     let body = {
  //       day: 4,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step6Data?.calories ? `${step6Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   }
  // };
  const submitStep6 = async (loading) => {
    setChkOutErr({});
    let workoutArr = [];
    let a = inputFields5?.map((v) => {
      if (v.videoId !== "") {
        workoutArr.push(v?.videoId);
      }
      return;
    });
    let body = {};
    if (!isEdit) {
      if (restDay5) {
        body = {
          day: 4,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
        };
      } else {
        body = {
          day: 4,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step6Data?.calories ? `${step6Data?.calories} Kcal` : "",
          exercise: step6Data?.exercise || "",
        };
      }
      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (isEdit && step6Data?.dayId) {
      if (restDay5) {
        console.log(restDay5);
        body = {
          day: 4,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
          _id: step6Data?.dayId,
        };
      } else {
        body = {
          day: 4,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step6Data?.calories ? `${step6Data?.calories} Kcal` : "",
          exercise: step6Data?.exercise || "",
          _id: step6Data?.dayId,
        };
      }
      ApiPut("/admin_plan/update", body)
        .then((res) => {
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    } else {
      body = {
        day: 4,
        videoId: workoutArr,
        planId: (planRes && planRes?._id) || step1Data?.id,
        calories: step6Data?.calories ? `${step6Data?.calories} Kcal` : "",
        exercise: step6Data?.exercise || "",
      };

      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };
  // ************************************************Step 7
  const handleFormChange6 = (index, event) => {
    let data = [...inputFields6];
    data[index]["videoId"] = event.value;
    data[index]["videoLabel"] = event.label;
    setInputFields6(data);
  };
  const addFields6 = () => {
    let newField = { videoId: "", videoLabel: "" };
    setInputFields6([...inputFields6, newField]);
  };
  const removeFields6 = (index) => {
    let data = [...inputFields6];
    data.splice(index, 1);
    setInputFields6(data);
  };
  console.log("inputFields6", inputFields6);
  // const submitStep7 = async () => {
  //   let workoutArr = [];
  //   let a = inputFields6?.map((v) => {
  //     if (v.videoId !== "") {
  //       workoutArr.push(v?.videoId);
  //     }
  //     return;
  //   });
  //   if (!isEdit) {
  //     let body = {
  //       day: 5,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step7Data?.calories ? `${step7Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else if (isEdit && step7Data?.dayId) {
  //     let body = {
  //       day: 5,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step7Data?.calories ? `${step7Data?.calories} Kcal` : "",
  //       _id: step7Data?.dayId,
  //     };
  //     console.log("body", body);
  //     await ApiPut("/admin_plan/update", body)
  //       .then((res) => {
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else {
  //     let body = {
  //       day: 5,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step7Data?.calories ? `${step7Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //         setTabPan(tabPan + 1);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   }
  // };
  const submitStep7 = async (loading) => {
    setChkOutErr({});
    let workoutArr = [];
    let a = inputFields6?.map((v) => {
      if (v.videoId !== "") {
        workoutArr.push(v?.videoId);
      }
      return;
    });
    let body = {};
    if (!isEdit) {
      if (restDay6) {
        body = {
          day: 5,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
        };
      } else {
        body = {
          day: 5,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step7Data?.calories ? `${step7Data?.calories} Kcal` : "",
          exercise: step7Data?.exercise || "",
        };
      }
      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (isEdit && step7Data?.dayId) {
      if (restDay6) {
        console.log(restDay6);
        body = {
          day: 5,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
          _id: step7Data?.dayId,
        };
      } else {
        body = {
          day: 5,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step7Data?.calories ? `${step7Data?.calories} Kcal` : "",
          exercise: step7Data?.exercise || "",
          _id: step7Data?.dayId,
        };
      }
      ApiPut("/admin_plan/update", body)
        .then((res) => {
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    } else {
      body = {
        day: 5,
        videoId: workoutArr,
        planId: (planRes && planRes?._id) || step1Data?.id,
        calories: step7Data?.calories ? `${step7Data?.calories} Kcal` : "",
        exercise: step7Data?.exercise || "",
      };

      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
          setTabPan(tabPan + 1);
          if (loading) {
            setModal(false);
            fetchData(currentpage, pagesize, searching);
          }
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };
  // ************************************************Step 8
  const handleFormChange7 = (index, event) => {
    let data = [...inputFields7];
    data[index]["videoId"] = event.value;
    data[index]["videoLabel"] = event.label;
    setInputFields7(data);
  };
  const addFields7 = () => {
    let newField = { videoId: "", videoLabel: "" };
    setInputFields7([...inputFields7, newField]);
  };
  const removeFields7 = (index) => {
    let data = [...inputFields7];
    data.splice(index, 1);
    setInputFields7(data);
  };
  console.log("inputFields7", inputFields7);
  // const submitStep8 = async () => {
  //   let workoutArr = [];
  //   let a = inputFields7?.map((v) => {
  //     if (v.videoId !== "") {
  //       workoutArr.push(v?.videoId);
  //     }
  //     return;
  //   });
  //   if (!isEdit) {
  //     let body = {
  //       day: 6,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step8Data?.calories ? `${step8Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         SuccessToast("Workout Added SuccessFully!!!");
  //         console.log(res);
  //         fetchData(currentpage, pagesize, searching);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else if (isEdit && step8Data?.dayId) {
  //     let body = {
  //       day: 6,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step8Data?.calories ? `${step8Data?.calories} Kcal` : "",
  //       _id: step8Data?.dayId,
  //     };
  //     console.log("body", body);
  //     await ApiPut("/admin_plan/update", body)
  //       .then((res) => {
  //         setTabPan(tabPan + 1);
  //         fetchData(currentpage, pagesize, searching);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else {
  //     let body = {
  //       day: 6,
  //       workout: workoutArr,
  //       planId: (planRes && planRes?._id) || step1Data?.id,
  //       calories: step8Data?.calories ? `${step8Data?.calories} Kcal` : "",
  //     };
  //     console.log("body", body);
  //     await ApiPost("/admin_plan/add", body)
  //       .then((res) => {
  //         console.log(res);
  //         setTabPan(9);
  //         fetchData(currentpage, pagesize, searching);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   }
  // };
  const submitStep8 = async () => {
    setChkOutErr({});
    let workoutArr = [];
    let a = inputFields7?.map((v) => {
      if (v.videoId !== "") {
        workoutArr.push(v?.videoId);
      }
      return;
    });
    let body = {};
    if (!isEdit) {
      if (restDay7) {
        body = {
          day: 6,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
        };
      } else {
        body = {
          day: 6,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step8Data?.calories ? `${step8Data?.calories} Kcal` : "",
          exercise: step8Data?.exercise || "",
        };
      }
      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
          SuccessToast("Plan Created Successfully !!!");
          setModal(false);
          fetchData(currentpage, pagesize, searching);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (isEdit && step8Data?.dayId) {
      if (restDay7) {
        console.log(restDay7);
        body = {
          day: 6,
          videoId: [],
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: "",
          exercise: "",
          _id: step8Data?.dayId,
        };
      } else {
        body = {
          day: 6,
          videoId: workoutArr,
          planId: (planRes && planRes?._id) || step1Data?.id,
          calories: step8Data?.calories ? `${step8Data?.calories} Kcal` : "",
          exercise: step8Data?.exercise || "",
          _id: step8Data?.dayId,
        };
      }
      ApiPut("/admin_plan/update", body)
        .then((res) => {
          fetchData(currentpage, pagesize, searching);
          setModal(false);
          SuccessToast("Plan Updated Successfully !!!");
        })
        .catch((err) => ErrorToast(err?.message));
    } else {
      body = {
        day: 6,
        videoId: workoutArr,
        planId: (planRes && planRes?._id) || step1Data?.id,
        calories: step8Data?.calories ? `${step8Data?.calories} Kcal` : "",
        exercise: step8Data?.exercise || "",
      };

      await ApiPost("/admin_plan/add", body)
        .then((res) => {
          console.log(res);
          setModal(false);
          fetchData(currentpage, pagesize, searching);
          // setTabPan(tabPan + 1);
          SuccessToast("Plan Updated Successfully !!!");
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };
  const submitWorkouts = async () => {
    setLoading(true);
    await submitStep2();
    await submitStep3();
    await submitStep4();
    await submitStep5();
    await submitStep6();
    await submitStep7();
    await submitStep8();
    setTabPan(9);
    setLoading(false);
  };
  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div
            onClick={() => history.push(`/plan-details/${row?._id}`)}
            role="button"
          >
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50 symbol-light mr-4">
                <img
                  src={row?.thumbnail}
                  className="img-fluid"
                  style={{ width: "50px" }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/media/img/fitnessLogo.png";
                  }}
                />
              </div>
              <div>
                <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                  {cell ? cell : "-"}
                </a>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "intensity",
      text: "Intensity",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: true,
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <span className="label font-weight-bold label-lg label-light-success label-inline">
              Active
            </span>
          </>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              // onClick={() => editBtnClick(row)}
              onClick={() => {
                editBtnClick(row);
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>

            <a
              title="Delete customer"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to Delete Plan ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Delete`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onDelete(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </a>
          </>
        );
      },
    },
  ];
  const editBtnClick = async (row) => {
    let equipArr = [];
    setUpdateLoading(false);
    setIsEdit(true);
    setTabPan(1);
    setModal(true);
    setChkOutErr({});
    let a = await row?.equipment_Data?.map((v) => {
      equipArr.push({ value: v?._id, label: v?.title });
    });
    setStep1Data({
      id: row?._id,
      title: row?.title,
      intensity: row?.intensity,
      duration: row?.duration,
      equipment: equipArr,
      thumbnail: row?.thumbnail,
      description: row?.description,
      certificateBackgroundImage: row?.certificateBackgroundImage
    });
    await ApiGet(`/plan/get/${row?._id}`)
      .then((res) => {
        console.log("first", res.data.data[0].adminData);
        if (res.data.data[0].adminData) {
          let b = res.data.data[0].adminData?.map((v, index) => {
            if (v?.day === 0) {
              if (v?.video_data.length === 0) {
                setRestDay1(true);
              } else {
                setRestDay1(false);
              }
              setStep2Data({
                calories: v?.calories.split(" K")[0],
                dayId: v?._id,
                exercise: v?.exercise,
              });
              setInputFields1([]);
              let a = [];
              let c = v?.video_data?.map((w, ind) => {
                console.log("bodyw", ind, w);
                a.push({ ["videoId"]: w?._id, ["videoLabel"]: w?.title });
              });
              setInputFields1(a);
            }
            if (v?.day === 1) {
              if (v?.video_data.length === 0) {
                setRestDay2(true);
              } else {
                setRestDay2(false)
              }
              setStep3Data({
                calories: v?.calories.split(" K")[0],
                dayId: v?._id,
                exercise: v?.exercise,
              });
              setInputFields2([]);
              let a = [];
              let c = v?.video_data?.map((w, ind) => {
                console.log("bodyw", ind, w);
                a.push({ ["videoId"]: w?._id, ["videoLabel"]: w?.title });
              });
              setInputFields2(a);
            }
            if (v?.day === 2) {
              if (v?.video_data.length === 0) {
                setRestDay3(true);
              } else {
                setRestDay3(false);
              }
              setStep4Data({
                calories: v?.calories.split(" K")[0],
                dayId: v?._id,
                exercise: v?.exercise,
              });
              setInputFields3([]);
              let a = [];
              let c = v?.video_data?.map((w, ind) => {
                console.log("bodyw", ind, w);
                a.push({ ["videoId"]: w?._id, ["videoLabel"]: w?.title });
              });
              setInputFields3(a);
            }
            if (v?.day === 3) {
              if (v?.video_data.length === 0) {
                setRestDay4(true);
              } else {
                setRestDay4(false);
              }
              setStep5Data({
                calories: v?.calories.split(" K")[0],
                dayId: v?._id,
                exercise: v?.exercise,
              });
              setInputFields4([]);
              let a = [];
              let c = v?.video_data?.map((w, ind) => {
                console.log("bodyw", ind, w);
                a.push({ ["videoId"]: w?._id, ["videoLabel"]: w?.title });
              });
              setInputFields4(a);
            }
            if (v?.day === 4) {
              if (v?.video_data.length === 0) {
                setRestDay5(true);
              } else {
                setRestDay5(false);
              }
              setStep6Data({
                calories: v?.calories.split(" K")[0],
                dayId: v?._id,
                exercise: v?.exercise,
              });
              setInputFields5([]);
              let a = [];
              let c = v?.video_data?.map((w, ind) => {
                console.log("bodyw", ind, w);
                a.push({ ["videoId"]: w?._id, ["videoLabel"]: w?.title });
              });
              setInputFields5(a);
            }
            if (v?.day === 5) {
              if (v?.video_data.length === 0) {
                setRestDay6(true);
              } else {
                setRestDay6(false);
              }
              setStep7Data({
                calories: v?.calories.split(" K")[0],
                dayId: v?._id,
                exercise: v?.exercise,
              });
              setInputFields6([]);
              let a = [];
              let c = v?.video_data?.map((w, ind) => {
                console.log("bodyw", ind, w);
                a.push({ ["videoId"]: w?._id, ["videoLabel"]: w?.title });
              });
              setInputFields6(a);
            }
            if (v?.day === 6) {
              if (v?.video_data.length === 0) {
                setRestDay7(true);
              } else {
                setRestDay7(false);
              }
              setStep8Data({
                calories: v?.calories.split(" K")[0],
                dayId: v?._id,
                exercise: v?.exercise,
              });
              setInputFields7([]);
              let a = [];
              let c = v?.video_data?.map((w, ind) => {
                console.log("bodyw", ind, w);
                a.push({ ["videoId"]: w?._id, ["videoLabel"]: w?.title });
              });
              setInputFields7(a);
            }
          });
        }
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onDelete = async (rowId) => {
    await ApiDelete(`/plan/delete/${rowId}`).then((res) => {
      Swal.fire({
        text: "Plan Deleted Successfully!!!",
        icon: "success",
        confirmButtonText: "Ok, Got it!",
        confirmButtonColor: "#338DE6",
      });
      fetchData(currentpage, pagesize, searching);
    });
  };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  const imageChange = async (e, name) => {
    let file = e.target.files[0];
    const isValidImageType = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
    if (!isValidImageType) {
      ErrorToast("Invalid file type. Please select a valid image.");
      document.getElementById(name).value = null;
      return;
    }

    setLoading(true);
    setMainLoader(true)
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);

    if (name == "thumbnailUrl") {
      await ApiUpload("upload/compress_image/profile_image", formData)
        .then((res) => {
          setNewData({ ...newData, thumbnailUrl: res?.data?.publicUrl[0] });
          setStep1Data({ ...step1Data, thumbnail: res?.data?.publicUrl[0] });
          setLoading(false);
        })
        .catch((err) => setLoading(false));
      setMainLoader(false)
    } else {
      await ApiUpload("upload/compress_image/profile_image", formData)
        .then((res) => {
          setStep1Data({ ...step1Data, certificateBackgroundImage: res?.data?.publicUrl[0] })
          setLoading(false);
        })
        .catch((err) => setLoading(false));
      setMainLoader(false)
    }
  };
  const imageChange1 = async (e) => {
    setLoading(true);
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("upload/compress_image/profile_image", formData)
      .then((res) => {
        setStep1Data({ ...step1Data, certificateBackgroundImage: res?.data?.publicUrl })
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };
  const handlesearch = (e) => {
    setsearching(e.target.value);
    fetchData(currentpage, pagesize, e.target.value);
  };
  const getEquipment = async () => {
    await ApiGet("/equipment")
      .then((res) => setEquipmentData(res?.data?.data?.map((v) => {
        return { value: v?._id, label: v?.title }
      })))
      .catch((err) => ErrorToast(err?.message));
  };
  const getWorkout = async () => {
    let b = [];
    await ApiGet("/video")
      .then((res) => {
        let qa = res?.data?.data?.map((v) => {
          b.push({ value: v?._id, label: v?.title });
          return;
        });
        setWorkoutData(b);
      })
      .catch((err) => console.log("err", err));
  };

  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    await ApiPost("/plan/get", body)
      .then((res) => {
        console.log("/plan/get", res?.data?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
    getWorkout();
    getEquipment();
  }, []);
  return (
    <>
      <div className="plan-list">
        <div className="d-flex justify-content-between mb-4 ">
          <div className="title">
            <div className="fs-20px fw-bolder">Plan List</div>
            <div>
              <span
                role="button"
                onClick={() => history.push("/dashboard")}
                className="text-hover-info text-muted"
              >
                Home
              </span>{" "}
              -{" "}
              <span className="text-muted" role="button">
                {" "}
                Plan
              </span>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-column flex-column-fluid h-100"
          id="kt_content"
        >
          <div className="card card-custom">
            <div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
              <div className="card-title ">
                {/* <h3 className="card-label">Video Playlist</h3> */}
                <div className="input-icon">
                  <input
                    type="text"
                    className="form-control bg-light"
                    name="searchText"
                    placeholder="Search by Name"
                    value={searching}
                    onChange={(e) => handlesearch(e)}
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i className="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </div>

              <div className="card-toolbar">
                <a
                  className="btn btn-color font-weight-bolder btn-sm"
                  onClick={addPlanBtn}
                >
                  Add Plan
                </a>
              </div>
            </div>
            <div className="card-body mb-5">
              <BootstrapTable
                wrapperClasses="table-responsive"
                headerWrapperClasses="border-0"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="_id"
                // selectRow={selectRow}
                data={data || []}
                columns={columns}
                // pagination={paginationFactory(options)}
                // defaultSorted={defaultSorted}
                noDataIndication={() => <NoDataTable />}
              // filter={filterFactory()}
              />
              <div className="d-flex justify-content-between  pt-10">
                <div className="my-2">
                  <Pagination
                    count={totalpage}
                    page={currentpage}
                    onChange={onPaginationChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination_"
                  />
                </div>
                <div className="my-2">
                  <div className="d-flex align-items-center pagination-drpdown">
                    <select
                      className="form-control pagination-drpdown1 dropdownPage"
                      id="kt_datatable_search_status"
                      onChange={(e) => handleonchnagespagination(e)}
                      value={pagesize}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={modal}
          onHide={() => {
            setModal(!modal);
            fetchData(currentpage, pagesize, searching);
          }}
          backdrop="static"
          className="add-plan-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {isEdit ? "Update Existing Plan" : "Add New Plan"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <ul className="p-0">
                  <li
                    className={
                      tabPan === 1
                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                    }
                    onClick={() => {
                      if (isEdit) {
                        setTabPan(1);
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon align-self-center">
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Thunder.svg"
                            )}
                          />
                        </div>
                      </div>
                      <div className="second ms-3">
                        <div className="sidebarTitle">Plan Details</div>
                        <div className="subTitle">Setup Your Plan Details</div>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      tabPan === 2
                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                    }
                    onClick={() => {
                      if (isEdit) {
                        setTabPan(2);
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon align-self-center">
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Safe.svg"
                            )}
                          />
                        </div>
                      </div>
                      <div className="second ms-3">
                        <div className="sidebarTitle">Monday</div>
                        <div className="subTitle">Set Workout For Monday</div>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      tabPan === 3
                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                    }
                    onClick={() => {
                      if (isEdit) {
                        setTabPan(3);
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon align-self-center">
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Safe.svg"
                            )}
                          />
                        </div>
                      </div>
                      <div className="second ms-3">
                        <div className="sidebarTitle">Tuesday</div>
                        <div className="subTitle">Set Workout For Tuesday</div>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      tabPan === 4
                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                    }
                    onClick={() => {
                      if (isEdit) {
                        setTabPan(4);
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon align-self-center">
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Safe.svg"
                            )}
                          />
                        </div>
                      </div>
                      <div className="second ms-3">
                        <div className="sidebarTitle">Wednesday</div>
                        <div className="subTitle">Set Workout For Wednesday</div>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      tabPan === 5
                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                    }
                    onClick={() => {
                      if (isEdit) {
                        setTabPan(5);
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon align-self-center">
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Safe.svg"
                            )}
                          />
                        </div>
                      </div>
                      <div className="second ms-3">
                        <div className="sidebarTitle">Thursday</div>
                        <div className="subTitle">Set Workout For Thursday</div>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      tabPan === 6
                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                    }
                    onClick={() => {
                      if (isEdit) {
                        setTabPan(6);
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon align-self-center">
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Safe.svg"
                            )}
                          />
                        </div>
                      </div>
                      <div className="second ms-3">
                        <div className="sidebarTitle">Friday</div>
                        <div className="subTitle">Set Workout For Friday</div>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      tabPan === 7
                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                    }
                    onClick={() => {
                      if (isEdit) {
                        setTabPan(7);
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon align-self-center">
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Safe.svg"
                            )}
                          />
                        </div>
                      </div>
                      <div className="second ms-3">
                        <div className="sidebarTitle">Saturday</div>
                        <div className="subTitle">Set Workout For Saturday</div>
                      </div>
                    </div>
                  </li>
                  <li
                    className={
                      tabPan === 8
                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                    }
                    onClick={() => {
                      if (isEdit) {
                        setTabPan(8);
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div className="icon align-self-center">
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Safe.svg"
                            )}
                          />
                        </div>
                      </div>
                      <div className="second ms-3">
                        <div className="sidebarTitle">Sunday</div>
                        <div className="subTitle">Set Workout For Sunday</div>
                      </div>
                    </div>
                  </li>
                  {/* <li
                  className={
                    tabPan === 9
                      ? `bg-light-primary side-arrow rounded ps-4 py-3`
                      : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"}`
                  }
                  onClick={() => {
                    if (isEdit) {
                      setTabPan(9);
                    }
                  }}
                >
                  <div className="d-flex">
                    <div className="icon align-self-center">
                      <div className="">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Design/Position.svg"
                          )}
                        />
                      </div>
                    </div>
                    <div className="second ms-3">
                      <div className="sidebarTitle">Preview</div>
                      <div className="subTitle">Your Plan Preview</div>
                    </div>
                  </div>
                </li> */}
                </ul>
              </div>
              {/* {mainLoader ? <div className="col-md-8 col-sm-9"> <div style={{ height: "100%", width: "100%" }} className="d-flex justify-content-center align-items-center"><div class="spinner-border" role="status"></div></div> </div> : */}
              <div
                className="col-md-8 col-lg-9 ps-lg-20"
                style={{
                  height: "555px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="row">
                  {tabPan === 1 && (
                    <>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Plan Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Plan Name"
                          label="url"
                          id="url"
                          name="title"
                          onChange={onChangeStep1}
                          value={step1Data?.title}
                          disabled={mainLoader}
                        />
                        {chkOutErr.title && (
                          <div style={{ color: "red" }}>{chkOutErr.title}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>Description<span className="text-danger"> *</span></Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Description"
                          label="description"
                          id="description"
                          name="description"
                          onChange={onChangeStep1}
                          value={step1Data?.description}
                          disabled={mainLoader}
                        />
                        {chkOutErr.description && (
                          <div style={{ color: "red" }}>{chkOutErr.description}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-6">
                        <Form.Label>
                          Image<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          label="thumbnailUrl"
                          id="thumbnailUrl"
                          className="form-control"
                          name="thumbnailUrl"
                          accept=".pdf, .png, .jpg, .jpeg"
                          onChange={(e) => imageChange(e, e.target.id)}
                          disabled={mainLoader}
                        />
                        {chkOutErr.thumbnail && (
                          <div style={{ color: "red" }}>
                            {chkOutErr.thumbnail}
                          </div>
                        )}
                        {step1Data?.thumbnail && <div className="position-relative w-auto mt-3">
                          <img
                            src={step1Data?.thumbnail}
                            alt="image"
                            style={{ width: "100px", height: "100px" }}
                            className="rounded-3"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/media/img/fitnessLogo.png";
                            }}
                          />
                          <span
                            onClick={() => {
                              setNewData({ ...newData, thumbnailUrl: "" });
                              setStep1Data({ ...step1Data, thumbnail: "" });
                              document.getElementById("thumbnailUrl").value = null;
                            }}
                          >
                            <MdCancel color="red" fontSize={20} />
                          </span>
                        </div>}
                      </Form.Group>
                      <Form.Group className="col-md-6">
                        <Form.Label>
                          Upload Certificate<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          label="certificateBackgroundImage"
                          id="certificateBackgroundImage"
                          className="form-control"
                          name="certificateBackgroundImage"
                          // onChange={imageChange1}
                          accept=".pdf, .png, .jpg, .jpeg"
                          onChange={(e) => imageChange(e, e.target.id)}
                          disabled={mainLoader}
                        />
                        {chkOutErr.certificateBackgroundImage && (
                          <div style={{ color: "red" }}>
                            {chkOutErr.certificateBackgroundImage}
                          </div>
                        )}
                        {step1Data?.certificateBackgroundImage && <div className="position-relative w-auto mt-3">
                          <img
                            src={step1Data?.certificateBackgroundImage}
                            alt="certificate image"
                            style={{ width: "100px", height: "100px" }}
                            className="rounded-3"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/media/img/fitnessLogo.png";
                            }}
                          />
                          <span
                            onClick={() => {
                              setStep1Data({ ...step1Data, certificateBackgroundImage: "" })
                              document.getElementById("thumbnailUrl").value = null;
                            }}
                          >
                            <MdCancel color="red" fontSize={20} />
                          </span>
                        </div>}
                      </Form.Group>
                      <Form.Group className="col-md-6">
                        <Form.Label>
                          Intensity (Intenzitás)
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          id="intensity"
                          className="form-control"
                          name="intensity"
                          onChange={onChangeStep1}
                          value={step1Data?.intensity || "n"}
                          disabled={mainLoader}
                        >
                          <option value="n" disabled>
                            Select Intensity
                          </option>
                          {/* <option value="advance">Advanced</option> */}
                          {/* <option value="high">High</option> */}
                          <option value="Haladó (Harcosképző)">
                            Haladó (Harcosképző)
                          </option>
                          <option value="Kezdő (Light)">Kezdő (Light)</option>
                        </Form.Control>
                        {chkOutErr.intensity && (
                          <div style={{ color: "red" }}>
                            {chkOutErr.intensity}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-6">
                        <Form.Label>
                          Duration(in minutes)
                          <span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          id="duration"
                          className="form-control"
                          name="duration"
                          onChange={onChangeStep1}
                          value={step1Data?.duration || "n"}
                          disabled={mainLoader}
                        >
                          <option value="n" disabled>
                            Select Duration
                          </option>
                          <option value="10-20">10 to 20</option>
                          <option value="20-30">20 to 30</option>
                          <option value="30-40">30 to 40</option>
                          <option value="40-50">40 to 50</option>
                          <option value="50-60">50 to 60</option>
                        </Form.Control>
                        {chkOutErr.duration && (
                          <div style={{ color: "red" }}>{chkOutErr.duration}</div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>Equipments (Eszköz)</Form.Label>
                        <Select
                          isMulti
                          name="equipment"
                          placeholder="Select Equipments"
                          isClearable={false}
                          options={equipmentData}
                          className="basic-multi-select"
                          classNamePrefix="selected"
                          value={step1Data?.equipment}
                          isDisabled={mainLoader}
                          onChange={(e) =>
                            setStep1Data({ ...step1Data, equipment: e })
                          }
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              color: "black",
                              padding: "0 10px",
                              fontSize: "1.1rem",
                              "&:hover": {
                                backgroundColor: "#007eff",
                                color: "white",
                                fontWeight: "500",
                              },
                              "&:focus-visible": {
                                backgroundColor: "red",
                                color: "white",
                                fontWeight: "500",
                              },
                            }),
                            menu: (provided) => ({
                              ...provided,
                              margin: "0",
                            }),
                            placeholder: (provided) => {
                              return {
                                ...provided,
                                color: "#181c32",
                                fontWeight: "500",
                              };
                            },
                          }}
                        />
                      </Form.Group>

                      <div className="text-end">
                        {isEdit && (
                          <button
                            className="btn btn-secondary me-4"
                            onClick={async () => {
                              setUpdateLoading(true);
                              await submitStep1();
                              if (step1Data?.title && step1Data?.description && step1Data?.thumbnail && step1Data?.intensity && step1Data?.duration && step1Data?.certificateBackgroundImage) {
                                fetchData(currentpage, pagesize, searching);
                                setModal(false);
                              }
                              setUpdateLoading(false);
                            }}
                            disabled={updateLoading}
                          >
                            {loading ? "Loading" : "Save & Close"}
                          </button>
                        )}
                        <button
                          className="btn btn-primary"
                          // onClick={() => setTabPan(tabPan + 1)}
                          onClick={() => {
                            submitStep1();
                          }}
                        >
                          {loading ? "Loading..." : "Next"}
                        </button>
                      </div>
                    </>
                  )}
                  {tabPan === 2 && (
                    <>
                      <h3 className="ms-4 mb-5">Monday Workout</h3>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Calories<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="example: 100-250"
                          label="calories"
                          id="calories"
                          required
                          name="calories"
                          onChange={(e) => {
                            setStep2Data({
                              ...step2Data,
                              calories: e.target.value,
                            });
                          }}
                          value={step2Data?.calories}
                          disabled={restDay1}
                        />
                        {!step2Data?.calories && !restDay1 && chkOutErr.calories && (<div style={{ color: "red" }} className={restDay1 && "pointer-events-none opacity-25"} > {chkOutErr.calories} </div>)}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Exercise Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Exercise Name"
                          label="exercise"
                          id="exercise"
                          required
                          name="exercise"
                          onChange={(e) => {
                            setStep2Data({
                              ...step2Data,
                              exercise: e.target.value,
                            });
                          }}
                          value={step2Data?.exercise}
                          disabled={restDay1}
                        />
                        {!step2Data?.exercise && !restDay1 && chkOutErr.exercise && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay1 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.exercise}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12 mb-0">
                        <Form.Label>
                          Add Workout Video<span className="text-danger"> *</span>
                        </Form.Label>
                        {inputFields1.map((input, index) => {
                          let a = {
                            value: input.videoId,
                            label: input.videoLabel,
                          };
                          console.log("ainput", a);
                          return (
                            <div key={index}>
                              <div className="row mb-4">
                                <div className="col-11 pe-0">
                                  <Select
                                    name="colors"
                                    maxMenuHeight={130}
                                    placeholder="Select video"
                                    isClearable={false}
                                    options={workoutData}
                                    className="basic-multi-select"
                                    isDisabled={restDay1}
                                    classNamePrefix="selected"
                                    value={a && a}
                                    onChange={(event) => {
                                      handleFormChange1(index, event);
                                    }}
                                    styles={{
                                      option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        padding: "5px 10px",
                                        fontSize: "1.1rem",
                                        "&:hover": {
                                          backgroundColor: "#007eff",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                        "&:focus-visible": {
                                          backgroundColor: "red",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        margin: "0",
                                      }),
                                      placeholder: (provided) => {
                                        return {
                                          ...provided,
                                          color: "#181c32",
                                          fontWeight: "500",
                                        };
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-1 ps-0">
                                  <div
                                    className={`h-100 text-center pt-2 text-danger ${restDay1 &&
                                      "pointer-events-none opacity-25"}`}
                                    role="button"
                                    onClick={() => removeFields1(index)}
                                  >
                                    <AiOutlineMinusCircle fontSize="25" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Form.Group>
                      <div className="mb-3 ps-4">
                        <button
                          className="btn btn-primary px-3 py-2 fs-7"
                          onClick={addFields1}
                          disabled={restDay1}
                        >
                          Add More
                        </button>
                      </div>
                      <div className="d-flex my-5">
                        {" "}
                        <input
                          type="checkbox"
                          id="restday1"
                          name="restday1"
                          className="me-3"
                          onClick={(e) => setRestDay1(e.target.checked)}
                          checked={restDay1}
                        />
                        <label
                          htmlFor="restday1"
                          className="m-0 user-select-none"
                        >
                          Rest Day
                        </label>
                      </div>

                      <div className="d-flex justify-content-between px-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            if (isEdit) {
                              setTabPan(tabPan - 1);
                            }
                          }}
                          disabled={!isEdit}
                        >
                          Previous
                        </button>
                        <div>
                          {isEdit && (
                            <button
                              className="btn btn-secondary me-4"
                              onClick={() => {
                                // setUpdateLoading(true);
                                let workoutArr = [];
                                let a = inputFields1?.map((v) => {
                                  if (v.videoId !== "") {
                                    workoutArr.push(v?.videoId);
                                  }
                                  return;
                                });
                                if (
                                  (!restDay1 && !step2Data?.calories) ||
                                  (!restDay1 && !step2Data?.exercise) ||
                                  (!restDay1 && workoutArr.length === 0)
                                ) {
                                  if (!restDay1 && workoutArr.length === 0) {
                                    ErrorToast(
                                      "Atleast one workout video required !!!"
                                    );
                                  }
                                  setChkOutErr(validate(step2Data));
                                } else {
                                  if (isEdit) {
                                    setUpdateLoading(true);
                                    submitStep2(true);
                                    setUpdateLoading(false);
                                  } else {
                                    setTabPan(tabPan + 1);
                                  }
                                }
                              }}
                              disabled={updateLoading}
                            >
                              Save & Close
                            </button>
                          )}
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              let workoutArr = [];
                              let a = inputFields1?.map((v) => {
                                if (v.videoId !== "") {
                                  workoutArr.push(v?.videoId);
                                }
                                return;
                              });
                              if (
                                (!restDay1 && !step2Data?.calories) ||
                                (!restDay1 && !step2Data?.exercise) ||
                                (!restDay1 && workoutArr.length === 0)
                              ) {
                                if (!restDay1 && workoutArr.length === 0) {
                                  ErrorToast(
                                    "Atleast one workout video required !!!"
                                  );
                                }
                                setChkOutErr(validate(step2Data));
                              } else {
                                if (isEdit) {
                                  submitStep2();
                                } else {
                                  setTabPan(tabPan + 1);
                                }
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {tabPan === 3 && (
                    <>
                      <h3 className="ms-4 mb-5">Tuesday Workout</h3>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Calories<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="example: 100-250"
                          label="calories"
                          id="calories"
                          required
                          name="calories"
                          onChange={(e) => {
                            setStep3Data({
                              ...step3Data,
                              calories: e.target.value,
                            });
                          }}
                          value={step3Data?.calories}
                          disabled={restDay2}
                        />
                        {!step3Data?.calories && !restDay2 && chkOutErr.calories && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay2 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.calories}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Exercise Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Exercise Name"
                          label="exercise"
                          id="exercise"
                          required
                          name="exercise"
                          onChange={(e) => {
                            setStep3Data({
                              ...step3Data,
                              exercise: e.target.value,
                            });
                          }}
                          value={step3Data?.exercise}
                          disabled={restDay2}
                        />
                        {!step3Data?.exercise && !restDay2 && chkOutErr.exercise && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay2 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.exercise}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12 mb-0">
                        <Form.Label>
                          Add Workout Video<span className="text-danger"> *</span>
                        </Form.Label>
                        {inputFields2.map((input, index) => {
                          let a = {
                            value: input.videoId,
                            label: input.videoLabel,
                          };
                          console.log("ainput", a);
                          return (
                            <div key={index}>
                              <div className="row mb-4">
                                <div className="col-11 pe-0">
                                  <Select
                                    name="colors"
                                    maxMenuHeight={130}
                                    placeholder="Select video"
                                    isClearable={false}
                                    options={workoutData}
                                    className="basic-multi-select"
                                    classNamePrefix="selected"
                                    value={a && a}
                                    isDisabled={restDay2}
                                    onChange={(event) => {
                                      handleFormChange2(index, event);
                                    }}
                                    styles={{
                                      option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        padding: "5px 10px",
                                        fontSize: "1.1rem",
                                        "&:hover": {
                                          backgroundColor: "#007eff",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                        "&:focus-visible": {
                                          backgroundColor: "red",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        margin: "0",
                                      }),
                                      placeholder: (provided) => {
                                        return {
                                          ...provided,
                                          color: "#181c32",
                                          fontWeight: "500",
                                        };
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-1 ps-0">
                                  <div
                                    className={`h-100 text-center pt-2 text-danger ${restDay2 &&
                                      "pointer-events-none opacity-25"}`}
                                    role="button"
                                    onClick={() => removeFields2(index)}
                                  >
                                    <AiOutlineMinusCircle fontSize="25" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Form.Group>
                      <div className="mb-3 ps-4">
                        <button
                          className="btn btn-primary px-3 py-2 fs-7"
                          onClick={addFields2}
                          disabled={restDay2}
                        >
                          Add More
                        </button>
                      </div>
                      <div className="d-flex my-5">
                        {" "}
                        <input
                          type="checkbox"
                          id="restday1"
                          name="restday1"
                          className="me-3"
                          onClick={(e) => setRestDay2(e.target.checked)}
                          checked={restDay2}
                        />
                        <label
                          htmlFor="restday1"
                          className="m-0 user-select-none"
                        >
                          Rest Day
                        </label>
                      </div>

                      <div className="d-flex justify-content-between px-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            setTabPan(tabPan - 1);
                          }}
                        >
                          Previous
                        </button>
                        <div>
                          {isEdit && (
                            <button
                              className="btn btn-secondary me-4"
                              onClick={() => {
                                // setUpdateLoading(true);
                                let workoutArr = [];
                                let a = inputFields2?.map((v) => {
                                  if (v.videoId !== "") {
                                    workoutArr.push(v?.videoId);
                                  }
                                  return;
                                });
                                if (
                                  (!restDay2 && !step3Data?.calories) ||
                                  (!restDay2 && !step3Data?.exercise) ||
                                  (!restDay2 && workoutArr.length === 0)
                                ) {
                                  if (!restDay2 && workoutArr.length === 0) {
                                    ErrorToast(
                                      "Atleast one workout video required !!!"
                                    );
                                  }
                                  setChkOutErr(validate(step3Data));
                                } else {
                                  if (isEdit) {
                                    setUpdateLoading(true);
                                    submitStep3(true);
                                    setUpdateLoading(false);
                                  } else {
                                    setTabPan(tabPan + 1);
                                  }
                                }
                              }}
                            >
                              Save & Close
                            </button>
                          )}

                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              let workoutArr = [];
                              let a = inputFields2?.map((v) => {
                                if (v.videoId !== "") {
                                  workoutArr.push(v?.videoId);
                                }
                                return;
                              });
                              if (
                                (!restDay2 && !step3Data?.calories) ||
                                (!restDay2 && !step3Data?.exercise) ||
                                (!restDay2 && workoutArr.length === 0)
                              ) {
                                if (!restDay2 && workoutArr.length === 0) {
                                  ErrorToast(
                                    "Atleast one workout video required !!!"
                                  );
                                }
                                setChkOutErr(validate(step3Data));
                              } else {
                                if (isEdit) {
                                  submitStep3();
                                } else {
                                  setTabPan(tabPan + 1);
                                }
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {tabPan === 4 && (
                    <>
                      <h3 className="ms-4 mb-5">Wednesday Workout</h3>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Calories<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="example: 100-250"
                          label="calories"
                          id="calories"
                          required
                          name="calories"
                          onChange={(e) => {
                            setStep4Data({
                              ...step4Data,
                              calories: e.target.value,
                            });
                          }}
                          value={step4Data?.calories}
                          disabled={restDay3}
                        />
                        {!step4Data?.calories && !restDay3 && chkOutErr.calories && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay3 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.calories}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Exercise Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Exercise Name"
                          label="exercise"
                          id="exercise"
                          required
                          name="exercise"
                          onChange={(e) => {
                            setStep4Data({
                              ...step4Data,
                              exercise: e.target.value,
                            });
                          }}
                          value={step4Data?.exercise}
                          disabled={restDay3}
                        />
                        {!step4Data?.exercise && !restDay3 && chkOutErr.exercise && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay3 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.exercise}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12 mb-0">
                        <Form.Label>
                          Add Workout Video<span className="text-danger"> *</span>
                        </Form.Label>
                        {inputFields3.map((input, index) => {
                          let a = {
                            value: input.videoId,
                            label: input.videoLabel,
                          };
                          console.log("ainput", a);
                          return (
                            <div key={index}>
                              <div className="row mb-4">
                                <div className="col-11 pe-0">
                                  <Select
                                    name="colors"
                                    maxMenuHeight={130}
                                    placeholder="Select video"
                                    isClearable={false}
                                    options={workoutData}
                                    className="basic-multi-select"
                                    classNamePrefix="selected"
                                    value={a && a}
                                    isDisabled={restDay3}
                                    onChange={(event) => {
                                      handleFormChange3(index, event);
                                    }}
                                    styles={{
                                      option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        padding: "5px 10px",
                                        fontSize: "1.1rem",
                                        "&:hover": {
                                          backgroundColor: "#007eff",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                        "&:focus-visible": {
                                          backgroundColor: "red",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        margin: "0",
                                      }),
                                      placeholder: (provided) => {
                                        return {
                                          ...provided,
                                          color: "#181c32",
                                          fontWeight: "500",
                                        };
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-1 ps-0">
                                  <div
                                    className={`h-100 text-center pt-2 text-danger ${restDay3 &&
                                      "pointer-events-none opacity-25"}`}
                                    role="button"
                                    onClick={() => removeFields3(index)}
                                  >
                                    <AiOutlineMinusCircle fontSize="25" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Form.Group>
                      <div className="mb-3 ps-4">
                        <button
                          className="btn btn-primary px-3 py-2 fs-7"
                          onClick={addFields3}
                          disabled={restDay3}
                        >
                          Add More
                        </button>
                      </div>
                      <div className="d-flex my-5">
                        {" "}
                        <input
                          type="checkbox"
                          id="restday1"
                          name="restday1"
                          className="me-3"
                          onClick={(e) => setRestDay3(e.target.checked)}
                          checked={restDay3}
                        />
                        <label
                          htmlFor="restday1"
                          className="m-0 user-select-none"
                        >
                          Rest Day
                        </label>
                      </div>
                      <div className="d-flex justify-content-between px-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            setTabPan(tabPan - 1);
                          }}
                        >
                          Previous
                        </button>
                        <div>
                          {isEdit && (
                            <button
                              className="btn btn-secondary me-4"
                              onClick={() => {
                                let workoutArr = [];
                                let a = inputFields3?.map((v) => {
                                  if (v.videoId !== "") {
                                    workoutArr.push(v?.videoId);
                                  }
                                  return;
                                });
                                if (
                                  (!restDay3 && !step4Data?.calories) ||
                                  (!restDay3 && !step4Data?.exercise) ||
                                  (!restDay3 && workoutArr.length === 0)
                                ) {
                                  if (!restDay3 && workoutArr.length === 0) {
                                    ErrorToast(
                                      "Atleast one workout video required !!!"
                                    );
                                  }
                                  setChkOutErr(validate(step4Data));
                                } else {
                                  if (isEdit) {
                                    submitStep4(true);
                                  } else {
                                    setTabPan(tabPan + 1);
                                  }
                                }
                              }}
                            >
                              Save & Close
                            </button>
                          )}
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              let workoutArr = [];
                              let a = inputFields3?.map((v) => {
                                if (v.videoId !== "") {
                                  workoutArr.push(v?.videoId);
                                }
                                return;
                              });
                              if (
                                (!restDay3 && !step4Data?.calories) ||
                                (!restDay3 && !step4Data?.exercise) ||
                                (!restDay3 && workoutArr.length === 0)
                              ) {
                                if (!restDay3 && workoutArr.length === 0) {
                                  ErrorToast(
                                    "Atleast one workout video required !!!"
                                  );
                                }
                                setChkOutErr(validate(step4Data));
                              } else {
                                if (isEdit) {
                                  submitStep4();
                                } else {
                                  setTabPan(tabPan + 1);
                                }
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {tabPan === 5 && (
                    <>
                      <h3 className="ms-4 mb-5">Thursday Workout</h3>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Calories<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="example: 100-250"
                          label="calories"
                          id="calories"
                          required
                          name="calories"
                          onChange={(e) => {
                            setStep5Data({
                              ...step5Data,
                              calories: e.target.value,
                            });
                          }}
                          value={step5Data?.calories}
                          disabled={restDay4}
                        />
                        {!step5Data?.calories && !restDay4 && chkOutErr.calories && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay4 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.calories}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Exercise Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Exercise Name"
                          label="exercise"
                          id="exercise"
                          required
                          name="exercise"
                          onChange={(e) => {
                            setStep5Data({
                              ...step5Data,
                              exercise: e.target.value,
                            });
                          }}
                          value={step5Data?.exercise}
                          disabled={restDay4}
                        />
                        {!step5Data?.exercise && !restDay4 && chkOutErr.exercise && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay4 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.exercise}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12 mb-0">
                        <Form.Label>
                          Add Workout Video<span className="text-danger"> *</span>
                        </Form.Label>
                        {inputFields4.map((input, index) => {
                          let a = {
                            value: input.videoId,
                            label: input.videoLabel,
                          };
                          console.log("ainput", a);
                          return (
                            <div key={index}>
                              <div className="row mb-4">
                                <div className="col-11 pe-0">
                                  <Select
                                    name="colors"
                                    maxMenuHeight={130}
                                    placeholder="Select video"
                                    isClearable={false}
                                    options={workoutData}
                                    className="basic-multi-select"
                                    classNamePrefix="selected"
                                    value={a && a}
                                    isDisabled={restDay4}
                                    onChange={(event) => {
                                      handleFormChange4(index, event);
                                    }}
                                    styles={{
                                      option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        padding: "5px 10px",
                                        fontSize: "1.1rem",
                                        "&:hover": {
                                          backgroundColor: "#007eff",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                        "&:focus-visible": {
                                          backgroundColor: "red",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        margin: "0",
                                      }),
                                      placeholder: (provided) => {
                                        return {
                                          ...provided,
                                          color: "#181c32",
                                          fontWeight: "500",
                                        };
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-1 ps-0">
                                  <div
                                    className={`h-100 text-center pt-2 text-danger ${restDay4 &&
                                      "pointer-events-none opacity-25"}`}
                                    role="button"
                                    onClick={() => removeFields4(index)}
                                  >
                                    <AiOutlineMinusCircle fontSize="25" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Form.Group>
                      <div className="mb-3 ps-4">
                        <button
                          className="btn btn-primary px-3 py-2 fs-7"
                          onClick={addFields4}
                          disabled={restDay4}
                        >
                          Add More
                        </button>
                      </div>
                      <div className="d-flex my-5">
                        {" "}
                        <input
                          type="checkbox"
                          id="restday1"
                          name="restday1"
                          className="me-3"
                          onClick={(e) => setRestDay4(e.target.checked)}
                          checked={restDay4}
                        />
                        <label
                          htmlFor="restday1"
                          className="m-0 user-select-none"
                        >
                          Rest Day
                        </label>
                      </div>

                      <div className="d-flex justify-content-between px-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            setTabPan(tabPan - 1);
                          }}
                        >
                          Previous
                        </button>
                        <div>
                          {isEdit && (
                            <button
                              className="btn btn-secondary me-4"
                              onClick={() => {
                                let workoutArr = [];
                                let a = inputFields4?.map((v) => {
                                  if (v.videoId !== "") {
                                    workoutArr.push(v?.videoId);
                                  }
                                  return;
                                });
                                if (
                                  (!restDay4 && !step5Data?.calories) ||
                                  (!restDay4 && !step5Data?.exercise) ||
                                  (!restDay4 && workoutArr.length === 0)
                                ) {
                                  if (!restDay4 && workoutArr.length === 0) {
                                    ErrorToast(
                                      "Atleast one workout video required !!!"
                                    );
                                  }
                                  setChkOutErr(validate(step5Data));
                                } else {
                                  if (isEdit) {
                                    submitStep5(true);
                                  } else {
                                    setTabPan(tabPan + 1);
                                  }
                                }
                              }}
                            >
                              Save & Close
                            </button>
                          )}
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              let workoutArr = [];
                              let a = inputFields4?.map((v) => {
                                if (v.videoId !== "") {
                                  workoutArr.push(v?.videoId);
                                }
                                return;
                              });
                              if (
                                (!restDay4 && !step5Data?.calories) ||
                                (!restDay4 && !step5Data?.exercise) ||
                                (!restDay4 && workoutArr.length === 0)
                              ) {
                                if (!restDay4 && workoutArr.length === 0) {
                                  ErrorToast(
                                    "Atleast one workout video required !!!"
                                  );
                                }
                                setChkOutErr(validate(step5Data));
                              } else {
                                if (isEdit) {
                                  submitStep5();
                                } else {
                                  setTabPan(tabPan + 1);
                                }
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {tabPan === 6 && (
                    <>
                      <h3 className="ms-4 mb-5">Friday Workout</h3>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Calories<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="example: 100-250"
                          label="calories"
                          id="calories"
                          required
                          name="calories"
                          onChange={(e) => {
                            setStep6Data({
                              ...step6Data,
                              calories: e.target.value,
                            });
                          }}
                          value={step6Data?.calories}
                          disabled={restDay5}
                        />
                        {!step6Data?.calories && !restDay5 && chkOutErr.calories && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay5 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.calories}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Exercise Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Exercise Name"
                          label="exercise"
                          id="exercise"
                          required
                          name="exercise"
                          onChange={(e) => {
                            setStep6Data({
                              ...step6Data,
                              exercise: e.target.value,
                            });
                          }}
                          value={step6Data?.exercise}
                          disabled={restDay5}
                        />
                        {!step6Data?.exercise && !restDay5 && chkOutErr.exercise && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay5 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.exercise}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12 mb-0">
                        <Form.Label>
                          Add Workout Video<span className="text-danger"> *</span>
                        </Form.Label>
                        {inputFields5.map((input, index) => {
                          let a = {
                            value: input.videoId,
                            label: input.videoLabel,
                          };
                          console.log("ainput", a);
                          return (
                            <div key={index}>
                              <div className="row mb-4">
                                <div className="col-11 pe-0">
                                  <Select
                                    name="colors"
                                    maxMenuHeight={130}
                                    placeholder="Select video"
                                    isClearable={false}
                                    options={workoutData}
                                    className="basic-multi-select"
                                    classNamePrefix="selected"
                                    value={a && a}
                                    isDisabled={restDay5}
                                    onChange={(event) => {
                                      handleFormChange5(index, event);
                                    }}
                                    styles={{
                                      option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        padding: "5px 10px",
                                        fontSize: "1.1rem",
                                        "&:hover": {
                                          backgroundColor: "#007eff",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                        "&:focus-visible": {
                                          backgroundColor: "red",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        margin: "0",
                                      }),
                                      placeholder: (provided) => {
                                        return {
                                          ...provided,
                                          color: "#181c32",
                                          fontWeight: "500",
                                        };
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-1 ps-0">
                                  <div
                                    className={`h-100 text-center pt-2 text-danger ${restDay5 &&
                                      "pointer-events-none opacity-25"}`}
                                    role="button"
                                    onClick={() => removeFields5(index)}
                                  >
                                    <AiOutlineMinusCircle fontSize="25" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Form.Group>
                      <div className="mb-3 ps-4">
                        <button
                          className="btn btn-primary px-3 py-2 fs-7"
                          onClick={addFields5}
                          disabled={restDay5}
                        >
                          Add More
                        </button>
                      </div>
                      <div className="d-flex my-5">
                        {" "}
                        <input
                          type="checkbox"
                          id="restday1"
                          name="restday1"
                          className="me-3"
                          checked={restDay5}
                          onClick={(e) => setRestDay5(e.target.checked)}
                        />
                        <label
                          htmlFor="restday1"
                          className="m-0 user-select-none"
                        >
                          Rest Day
                        </label>
                      </div>
                      <div className="d-flex justify-content-between px-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            setTabPan(tabPan - 1);
                          }}
                        >
                          Previous
                        </button>
                        <div>
                          {isEdit && (
                            <button
                              className="btn btn-secondary me-4"
                              onClick={() => {
                                let workoutArr = [];
                                let a = inputFields5?.map((v) => {
                                  if (v.videoId !== "") {
                                    workoutArr.push(v?.videoId);
                                  }
                                  return;
                                });
                                if (
                                  (!restDay5 && !step6Data?.calories) ||
                                  (!restDay5 && !step6Data?.exercise) ||
                                  (!restDay5 && workoutArr.length === 0)
                                ) {
                                  if (!restDay5 && workoutArr.length === 0) {
                                    ErrorToast(
                                      "Atleast one workout video required !!!"
                                    );
                                  }
                                  setChkOutErr(validate(step6Data));
                                } else {
                                  if (isEdit) {
                                    submitStep6(true);
                                  } else {
                                    setTabPan(tabPan + 1);
                                  }
                                }
                              }}
                            >
                              Save & Close
                            </button>
                          )}
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              let workoutArr = [];
                              let a = inputFields5?.map((v) => {
                                if (v.videoId !== "") {
                                  workoutArr.push(v?.videoId);
                                }
                                return;
                              });
                              if (
                                (!restDay5 && !step6Data?.calories) ||
                                (!restDay5 && !step6Data?.exercise) ||
                                (!restDay5 && workoutArr.length === 0)
                              ) {
                                if (!restDay5 && workoutArr.length === 0) {
                                  ErrorToast(
                                    "Atleast one workout video required !!!"
                                  );
                                }
                                setChkOutErr(validate(step6Data));
                              } else {
                                if (isEdit) {
                                  submitStep6();
                                } else {
                                  setTabPan(tabPan + 1);
                                }
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {tabPan === 7 && (
                    <>
                      <h3 className="ms-4 mb-5">Saturday Workout</h3>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Calories<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="example: 100-250"
                          label="calories"
                          id="calories"
                          required
                          name="calories"
                          onChange={(e) => {
                            setStep7Data({
                              ...step7Data,
                              calories: e.target.value,
                            });
                          }}
                          value={step7Data?.calories}
                          disabled={restDay6}
                        />
                        {!step7Data?.calories && !restDay6 && chkOutErr.calories && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay6 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.calories}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Exercise Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Exercise Name"
                          label="exercise"
                          id="exercise"
                          required
                          name="exercise"
                          onChange={(e) => {
                            setStep7Data({
                              ...step7Data,
                              exercise: e.target.value,
                            });
                          }}
                          value={step7Data?.exercise}
                          disabled={restDay6}
                        />
                        {!step7Data?.exercise && !restDay6 && chkOutErr.exercise && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay6 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.exercise}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12 mb-0">
                        <Form.Label>
                          Add Workout Video<span className="text-danger"> *</span>
                        </Form.Label>
                        {inputFields6.map((input, index) => {
                          let a = {
                            value: input.videoId,
                            label: input.videoLabel,
                          };
                          console.log("ainput", a);
                          return (
                            <div key={index}>
                              <div className="row mb-4">
                                <div className="col-11 pe-0">
                                  <Select
                                    name="colors"
                                    maxMenuHeight={130}
                                    placeholder="Select video"
                                    isClearable={false}
                                    options={workoutData}
                                    className="basic-multi-select"
                                    classNamePrefix="selected"
                                    value={a && a}
                                    isDisabled={restDay6}
                                    onChange={(event) => {
                                      handleFormChange6(index, event);
                                    }}
                                    styles={{
                                      option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        padding: "5px 10px",
                                        fontSize: "1.1rem",
                                        "&:hover": {
                                          backgroundColor: "#007eff",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                        "&:focus-visible": {
                                          backgroundColor: "red",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        margin: "0",
                                      }),
                                      placeholder: (provided) => {
                                        return {
                                          ...provided,
                                          color: "#181c32",
                                          fontWeight: "500",
                                        };
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-1 ps-0">
                                  <div
                                    className={`h-100 text-center pt-2 text-danger ${restDay6 &&
                                      "pointer-events-none opacity-25"}`}
                                    role="button"
                                    onClick={() => removeFields6(index)}
                                  >
                                    <AiOutlineMinusCircle fontSize="25" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Form.Group>
                      <div className="mb-3 ps-4">
                        <button
                          className="btn btn-primary px-3 py-2 fs-7"
                          onClick={addFields6}
                          disabled={restDay6}
                        >
                          Add More
                        </button>
                      </div>
                      <div className="d-flex my-5">
                        {" "}
                        <input
                          type="checkbox"
                          id="restday1"
                          name="restday1"
                          className="me-3"
                          onClick={(e) => setRestDay6(e.target.checked)}
                          checked={restDay6}
                        />
                        <label
                          htmlFor="restday1"
                          className="m-0 user-select-none"
                        >
                          Rest Day
                        </label>
                      </div>
                      <div className="d-flex justify-content-between px-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            setTabPan(tabPan - 1);
                          }}
                        >
                          Previous
                        </button>
                        <div>
                          {isEdit && (
                            <button
                              className="btn btn-secondary me-4"
                              onClick={() => {
                                let workoutArr = [];
                                let a = inputFields6?.map((v) => {
                                  if (v.videoId !== "") {
                                    workoutArr.push(v?.videoId);
                                  }
                                  return;
                                });
                                if (
                                  (!restDay6 && !step7Data?.calories) ||
                                  (!restDay6 && !step7Data?.exercise) ||
                                  (!restDay6 && workoutArr.length === 0)
                                ) {
                                  if (!restDay6 && workoutArr.length === 0) {
                                    ErrorToast(
                                      "Atleast one workout video required !!!"
                                    );
                                  }
                                  setChkOutErr(validate(step7Data));
                                } else {
                                  if (isEdit) {
                                    submitStep7(true);
                                  } else {
                                    setTabPan(tabPan + 1);
                                  }
                                }
                              }}
                            >
                              Save & Close
                            </button>
                          )}
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              let workoutArr = [];
                              let a = inputFields6?.map((v) => {
                                if (v.videoId !== "") {
                                  workoutArr.push(v?.videoId);
                                }
                                return;
                              });
                              if (
                                (!restDay6 && !step7Data?.calories) ||
                                (!restDay6 && !step7Data?.exercise) ||
                                (!restDay6 && workoutArr.length === 0)
                              ) {
                                if (!restDay6 && workoutArr.length === 0) {
                                  ErrorToast(
                                    "Atleast one workout video required !!!"
                                  );
                                }
                                setChkOutErr(validate(step7Data));
                              } else {
                                if (isEdit) {
                                  submitStep7();
                                } else {
                                  setTabPan(tabPan + 1);
                                }
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {tabPan === 8 && (
                    <>
                      <h3 className="ms-4 mb-5">Sunday Workout</h3>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Calories<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="example: 100-250"
                          label="calories"
                          id="calories"
                          required
                          name="calories"
                          onChange={(e) => {
                            setStep8Data({
                              ...step8Data,
                              calories: e.target.value,
                            });
                          }}
                          value={step8Data?.calories}
                          disabled={restDay7}
                        />
                        {!step8Data?.calories && !restDay7 && chkOutErr.calories && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay7 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.calories}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label>
                          Exercise Name<span className="text-danger"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Exercise Name"
                          label="exercise"
                          id="exercise"
                          required
                          name="exercise"
                          onChange={(e) => {
                            setStep8Data({
                              ...step8Data,
                              exercise: e.target.value,
                            });
                          }}
                          value={step8Data?.exercise}
                          disabled={restDay7}
                        />
                        {!step8Data?.exercise && !restDay7 && chkOutErr.exercise && (
                          <div
                            style={{ color: "red" }}
                            className={
                              restDay7 && "pointer-events-none opacity-25"
                            }
                          >
                            {chkOutErr.exercise}
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="col-md-12 mb-0">
                        <Form.Label>
                          Add Workout Video<span className="text-danger"> *</span>
                        </Form.Label>
                        {inputFields7.map((input, index) => {
                          let a = {
                            value: input.videoId,
                            label: input.videoLabel,
                          };
                          console.log("ainput", a);
                          return (
                            <div key={index}>
                              <div className="row mb-4">
                                <div className="col-11 pe-0">
                                  <Select
                                    name="colors"
                                    maxMenuHeight={130}
                                    placeholder="Select video"
                                    isClearable={false}
                                    options={workoutData}
                                    className="basic-multi-select"
                                    classNamePrefix="selected"
                                    value={a && a}
                                    isDisabled={restDay7}
                                    onChange={(event) => {
                                      handleFormChange7(index, event);
                                    }}
                                    styles={{
                                      option: (provided) => ({
                                        ...provided,
                                        color: "black",
                                        padding: "5px 10px",
                                        fontSize: "1.1rem",
                                        "&:hover": {
                                          backgroundColor: "#007eff",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                        "&:focus-visible": {
                                          backgroundColor: "red",
                                          color: "white",
                                          fontWeight: "500",
                                        },
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        margin: "0",
                                      }),
                                      placeholder: (provided) => {
                                        return {
                                          ...provided,
                                          color: "#181c32",
                                          fontWeight: "500",
                                        };
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-1 ps-0">
                                  <div
                                    className={`h-100 text-center pt-2 text-danger ${restDay7 &&
                                      "pointer-events-none opacity-25"}`}
                                    role="button"
                                    onClick={() => removeFields7(index)}
                                  >
                                    <AiOutlineMinusCircle fontSize="25" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Form.Group>
                      <div className="mb-3 ps-4">
                        <button
                          className="btn btn-primary px-3 py-2 fs-7"
                          onClick={addFields7}
                          disabled={restDay7}
                        >
                          Add More
                        </button>
                      </div>
                      <div className="d-flex my-5">
                        {" "}
                        <input
                          type="checkbox"
                          id="restday1"
                          name="restday1"
                          className="me-3"
                          onClick={(e) => setRestDay7(e.target.checked)}
                          checked={restDay7}
                        />
                        <label
                          htmlFor="restday1"
                          className="m-0 user-select-none"
                        >
                          Rest Day
                        </label>
                      </div>
                      <div className="d-flex justify-content-between px-4">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            setTabPan(tabPan - 1);
                          }}
                        >
                          Previous
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            let workoutArr = [];
                            let a = inputFields7?.map((v) => {
                              if (v.videoId !== "") {
                                workoutArr.push(v?.videoId);
                              }
                              return;
                            });
                            if (
                              (!restDay7 && !step8Data?.calories) ||
                              (!restDay7 && !step8Data?.exercise) ||
                              (!restDay7 && workoutArr.length === 0)
                            ) {
                              if (!restDay7 && workoutArr.length === 0) {
                                ErrorToast(
                                  "Atleast one workout video required !!!"
                                );
                              }
                              setChkOutErr(validate(step8Data));
                            } else {
                              if (isEdit) {
                                submitStep8(true);
                              } else {
                                submitWorkouts();
                              }
                            }
                          }}
                          disabled={loading}
                        >
                          {loading
                            ? "Loading..."
                            : isEdit
                              ? "Save & Close"
                              : "Create"}
                        </button>
                      </div>
                    </>
                  )}
                  {tabPan === 9 && <PlanPreview row={step1Data?.id} />}
                </div>
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Plan;
