import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NoDataTable from "../../../common/noDataTable";
import { ApiDelete, ApiPost, ApiPut, ApiUpload, Bucket } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import thumbDefault from "../../../media/thumbDefault.jpg";
import Select from "react-select";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

function Recipe() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searching, setsearching] = useState("");
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [meal, setMeal] = useState([]);
  const [dietary, setDietary] = useState([]);
  const [nutrition, setNutrition] = useState([]);
  const [tabPan, setTabPan] = useState(1);
  const [newData, setNewData] = useState({
    title: "",
    url: [],
    time: "",
    decription: "",
    mealtype: [],
    difficulty: "",
    dietary: [],
  });
  console.log("newData", newData);
  const [ingredientFields, setIngredientFields] = useState([
    {
      image: "",
      details: "",
      volume: "",
    },
  ]);
  const [stepFields, setStepFields] = useState([
    {
      step: "",
      ingredients: [
        {
          image: "",
          details: "",
          volume: "",
        },
      ],
      stepNumber: 1,
    },
  ]);
  const [nutritionFields, setNutritionFields] = useState([
    {
      nutritionCategory: "",
      // image: "",
      details: "",
    },
  ]);
  const [loading, setLoading] = useState(false);

  const handleAddNutrition = () => {
    setNutritionFields((prevFields) => [
      ...prevFields,
      {
        nutritionCategory: "",
        // image: "",
        details: "",
      },
    ]);
  };
  console.log("stepFields", stepFields);
  // const handleNutritionChange = (index, event) => {
  //   let data = [...nutritionFields];
  //   data[index][event.target.name] = event.target.value;
  //   setNutritionFields(data);
  // };
  const handleNutritionChange = (index, event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trimStart();

    let data = [...nutritionFields];
    data[index][name] = trimmedValue;
    setNutritionFields(data);
  };

  // const handleIngredientChange = (index, index1, event) => {
  //   const data = [...stepFields];
  //   const b = [...data[index]?.ingredients];
  //   b[index1][event.target.name] = event.target.value;
  //   console.log("basd", b);
  //   data[index] = {
  //     ...data[index],
  //     b,

  //   };
  //   setStepFields(data);
  // };
  const submitStep = () => {
    setTabPan(2);
  };
  const handlePrevious = () => {
    setTabPan(1);
  };
  const handleIngredientChange = (index, index1, event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trimStart();

    const data = [...stepFields];
    const b = [...data[index]?.ingredients];
    b[index1][name] = trimmedValue;
    console.log("basd", b);
    data[index] = {
      ...data[index],
      b,
    };
    setStepFields(data);
  };

  // Define the handleRemoveNutrition function
  const handleRemoveNutrition = (index) => {
    let data = [...nutritionFields];
    data.splice(index, 1);
    setNutritionFields(data);
  };

  const handleAddStep = () => {
    setStepFields((prevFields) => [
      ...prevFields,
      {
        step: "",
        ingredients: [
          {
            image: "",
            details: "",
            volume: "",
          },
        ],
        stepNumber: stepFields?.length + 1,
      },
    ]);
  };
  // const handleStepChange = (index, event) => {
  //   let data = [...stepFields];
  //   data[index][event.target.name] = event.target.value;
  //   setStepFields(data);
  // };
  const handleStepChange = (index, event) => {
    const { name, value } = event.target;
    // const trimmedValue = value.trim();

    let data = [...stepFields];
    data[index][name] = value;
    setStepFields(data);
  };

  const handleRemoveStep = (index) => {
    let data = [...stepFields];
    data.splice(index, 1);
    setStepFields(data);
  };
  const handleAddIngredient = (index) => {
    let data = [...stepFields];
    data[index] = {
      ...data[index],
      ingredients: [
        ...data[index]?.ingredients,
        {
          image: "",
          details: "",
          volume: "",
        },
      ],
    };
    setStepFields(data);
  };
  const handleRemoveIngredient = (index, index1) => {
    let data = [...stepFields];
    data[index] = {
      ...data[index],
      ingredients: data[index]?.ingredients?.filter((v, i) => {
        if (i !== index1) {
          return v;
        } else {
          console.log("vasd", v);
        }
      }),
    };
    setStepFields(data);
  };

  const columns = [
    {
      dataField: "image",
      text: "Image",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50px symbol-light mr-4">
                <img src={row?.image ? row?.image?.[0] : thumbDefault} className="img-fluid" onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/media/img/fitnessLogo.png";
                }} />
              </div>
              {/* <div>
                <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                  {cell ? cell : "-"}
                </a>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "decription",
      text: "Decription",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "time",
      text: "Time",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },

    {
      dataField: "mealType_Data",
      text: "MealType",
      sort: true,
      formatter: (cell, row) => {
        console.log("rowmeal", row);
        return <div>{row?.mealType_Data?.length > 0 ? (row?.mealType_Data?.length > 1 ? row?.mealType_Data[0]?.details + "..." : row?.mealType_Data[0]?.details) : "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell && moment(cell).format("DD-MM-YYYY HH:mm A")}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex">
              <a
                title="Edit customer"
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => editBtnClick(row)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
                </span>
              </a>

              <a
                title="Delete customer"
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => {
                  Swal.fire({
                    text: `Are you sure you want to Delete ?`,
                    icon: "warning",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: `Yes, Delete`,
                    confirmButtonColor: "#D72852",
                    cancelButtonColor: "transparent",
                    cancelButtonText: "No, Cancel",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      onDelete(row?._id);
                    }
                  });
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                </span>
              </a>
            </div>
          </>
        );
      },
    },
  ];
  // console.log("newData", newData);
  const handleChange = (e) => {
    const { value, name } = e.target;
    const trimmedValue = value.trimStart();
    setNewData({ ...newData, [name]: trimmedValue });
  };

  const editBtnClick = (row) => {
    console.log("editrow", row);
    setTabPan(1);
    setIsEdit(true);
    setModal(true);
    let dietaryid = [];
    dietaryid = row?.dietary_Data?.map((val) => {
      console.log("dietaryidval", val?.details);
      if (val?.details) {
        return { value: val?._id, label: val?.details };
      }
      console.log("dietaryid", dietaryid);
    });
    setNewData({
      id: row?._id,
      title: row?.title,
      url: row?.image,
      time: row?.time,
      decription: row?.decription,
      mealtype: row?.mealType,
      difficulty: row?.difficulty,
      dietary: dietaryid,
      // dietary: row?.dietary_Data[0]?.details,
      // steps: row?.stepFields,
    });
    const nutritionFields = row?.nutrition?.map((nutritionItem) => ({
      nutritionCategory: nutritionItem.nutritionCategory,
      image: nutritionItem.image,
      details: nutritionItem.details,
    }));

    setNutritionFields(nutritionFields);
    setStepFields(
      row?.steps?.map((stepItem) => {
        const ingredients = stepItem?.ingredients?.map((ingredientItem) => ({
          image: ingredientItem?.image || "",
          details: ingredientItem?.details || "",
          volume: ingredientItem?.volume || "",
        }));

        return {
          step: stepItem?.step || "",
          stepNumber: stepItem?.stepNumber || 0,
          ingredients,
        };
      })
    );

    // setStepFields([
    //   {
    //     step: "",
    //     ingredients: [
    //       {
    //         image: "",
    //         details: "",
    //         volume: "",
    //       },
    //     ],
    //     stepNumber: 1,
    //   },
    // ])
  };
  const onDelete = async (Id) => {
    await ApiDelete(`/recipe/${Id}`)
      .then((res) => {
        fetchData(currentpage, pagesize, "");
        Swal.fire({
          text: "You have Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
      })
      .catch((err) => ErrorToast(err?.message));
  };
  // const onUpdate = async () => {
  //   let dietaryid = [];
  //   dietaryid = newData?.dietary?.map((val) => {
  //     if (val?.value) {
  //       return val?.value;
  //     }
  //   });
  //   let body = {
  //     id: newData?.id,
  //     image: newData?.url,
  //     title: newData?.title,
  //     time: newData?.time,
  //     decription: newData?.decription,
  //     mealType: newData?.mealtype,
  //     dietary: dietaryid,
  //     nutrition: nutritionFields,
  //     steps: stepFields,
  //   };
  //   await ApiPut("/recipe", body)
  //     .then((res) => {
  //       SuccessToast(res?.data?.message);
  //       setModal(false);
  //       fetchData(currentpage, pagesize, "");
  //     })
  //     .catch((err) => ErrorToast(err?.message));
  // };
  // const validateForm = () => {
  //   console.log("valid");
  //   let errors = {};
  //   let formIsValid = true;

  //   if (!data.title) {
  //     console.log("a");
  //     formIsValid = false;
  //     document.getElementById("title").style.border = "2px solid #f64e60";
  //     errors["title"] = "Please Enter title";
  //   }
  //   if (!data.description) {
  //     console.log("a");
  //     formIsValid = false;
  //     document.getElementById("description").style.border = "2px solid #f64e60";
  //     errors["description"] = "Please Enter Description";
  //   }
  //   setError(errors);

  //   return formIsValid;
  // };
  // const handleSubmit = async () => {
  //   let dietaryid = [];
  //   dietaryid = newData?.dietary?.map((val) => {
  //     if (val?.value) {
  //       return val?.value;
  //     }
  //   });
  //   const emptyNutritionFields = nutritionFields.filter(
  //     (field) =>
  //       field.nutritionCategory.trim().length === 0 ||
  //       field.image.trim().length === 0 ||
  //       field.details.trim().length === 0
  //   );
  //   const emptyStepFields = stepFields.filter(
  //     (field) =>
  //       field.step.trim().length === 0 ||
  //       field.ingredients.some(
  //         (ingredient) =>
  //           ingredient.image.trim().length === 0 ||
  //           ingredient.details.trim().length === 0 ||
  //           ingredient.volume.trim().length === 0
  //       )
  //   );

  //   let body = {
  //     image: newData.url,
  //     title: newData.title,
  //     time: newData.time,
  //     decription: newData?.decription,
  //     mealType: newData?.mealtype,
  //     dietary: dietaryid,
  //     nutrition: nutritionFields,
  //     steps: stepFields,
  //   };
  //   if (
  //     newData?.url &&
  //     newData?.title.trim().length > 0 &&
  //     newData?.time.trim().length > 0 &&
  //     (newData?.decription ?? "").trim().length > 0 &&
  //     newData?.mealtype &&
  //     newData?.mealtype.length > 0 &&
  //     newData?.mealtype !== "" &&
  //     newData?.dietary?.length > 0 &&
  //     emptyNutritionFields.length === 0 &&
  //     emptyStepFields.length === 0
  //   ) {
  //     await ApiPost("/recipe", body)
  //       .then((res) => {
  //         SuccessToast(res?.data?.message);
  //         setModal(false);
  //         fetchData(currentpage, pagesize, "");
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   } else if (!newData?.url) {
  //     ErrorToast("Image is Required");
  //   } else if (!newData?.title || newData?.title.trim().length === 0) {
  //     ErrorToast("Title is Required!");
  //   } else if (!newData?.time || newData?.time.trim().length === 0) {
  //     ErrorToast("time is Required!");
  //   } else if (
  //     !newData?.decription ||
  //     (newData?.decription ?? "").trim().length === 0
  //   ) {
  //     ErrorToast("decription is Required!");
  //   } else if (
  //     !newData?.mealtype ||
  //     newData?.mealtype.length === 0 ||
  //     newData?.mealtype === ""
  //   ) {
  //     ErrorToast("mealtype is Required!");
  //   } else if (!newData?.dietary || newData?.dietary.length === 0) {
  //     ErrorToast("Dietary is Required!");
  //   } else {
  //     emptyNutritionFields.forEach((field, index) => {
  //       if (field.nutritionCategory.trim().length === 0) {
  //         ErrorToast(`Nutrition Category is required for field ${index + 1}`);
  //       } else if (field.image.trim().length === 0) {
  //         ErrorToast(`Nutrition Image is required for field ${index + 1}`);
  //       } else if (field.details.trim().length === 0) {
  //         ErrorToast(`Details is required for field ${index + 1}`);
  //       }
  //     });
  //     emptyStepFields.forEach((field, index) => {
  //       if (field.step.trim().length === 0) {
  //         ErrorToast(`Step is required for field ${index + 1}`);
  //       } else {
  //         field.ingredients.forEach((ingredient, ingredientIndex) => {
  //           if (ingredient.image.trim().length === 0) {
  //             ErrorToast(
  //               `Ingredient Image is required for step ${index +
  //                 1}, ingredient ${ingredientIndex + 1}`
  //             );
  //           } else if (ingredient.details.trim().length === 0) {
  //             ErrorToast(
  //               `Ingredient Details is required for step ${index +
  //                 1}, ingredient ${ingredientIndex + 1}`
  //             );
  //           } else if (ingredient.volume.trim().length === 0) {
  //             ErrorToast(
  //               `Ingredient Volume is required for step ${index +
  //                 1}, ingredient ${ingredientIndex + 1}`
  //             );
  //           }
  //         });
  //       }
  //     });
  //   }
  // };
  const onUpdate = async () => {
    let dietaryid = [];
    dietaryid = newData?.dietary?.map((val) => {
      if (val?.value) {
        return val?.value;
      }
    });

    const emptyNutritionFields = nutritionFields.filter(
      (field) =>
        field.nutritionCategory.trim().length === 0 ||
        // field.image.trim().length === 0 ||
        field.details.length === 0
    );

    const emptyStepFields = stepFields.filter(
      (field) =>
        field.step.trim().length === 0 ||
        field.ingredients.some(
          (ingredient) =>
            // ingredient.image.trim().length === 0 ||
            // ingredient.details.trim().length === 0 ||
            ingredient.volume.length === 0
        )
    );

    let body = {
      id: newData?.id,

      image: newData.url,
      title: newData.title,
      time: newData.time,
      decription: newData?.decription,
      mealType: newData?.mealtype,
      difficulty: newData?.difficulty,
      dietary: dietaryid,
      nutrition: nutritionFields,
      steps: stepFields,
    };

    if (
      newData?.url &&
      newData?.title.trim().length > 0 &&
      newData?.time.trim().length > 0 &&
      (newData?.decription ?? "").trim().length > 0 &&
      newData?.mealtype &&
      newData?.mealtype.length > 0 &&
      newData?.mealtype !== "" &&
      newData?.dietary?.length > 0 &&
      emptyNutritionFields.length === 0 &&
      emptyStepFields.length === 0
    ) {
      await ApiPut("/recipe", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModal(false);
          fetchData(currentpage, pagesize, "");
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (!newData?.url) {
      ErrorToast("Image is Required");
    } else if (!newData?.title || newData?.title.trim().length === 0) {
      ErrorToast("Title is Required!");
    } else if (!newData?.time || newData?.time.trim().length === 0) {
      ErrorToast("Time is Required!");
    } else if (!newData?.decription || (newData?.decription ?? "").trim().length === 0) {
      ErrorToast("Description is Required!");
    } else if (!newData?.mealtype || newData?.mealtype.length === 0 || newData?.mealtype === "") {
      ErrorToast("Meal Type is Required!");
    } else if (!newData?.dietary || newData?.dietary.length === 0) {
      ErrorToast("Dietary is Required!");
    } else if (emptyNutritionFields.length > 0) {
      const firstEmptyNutritionField = emptyNutritionFields[0];
      if (firstEmptyNutritionField.nutritionCategory.trim().length === 0) {
        ErrorToast(`Nutrition Category is required `);
      }
      // else if (firstEmptyNutritionField.image.trim().length === 0) {
      //   ErrorToast(`Nutrition Image is required `);
      // }
      else if (firstEmptyNutritionField.details.length === 0) {
        ErrorToast(`Nutrition Volume is required `);
      }
    } else if (emptyStepFields.length > 0) {
      const firstEmptyStepField = emptyStepFields[0];
      if (firstEmptyStepField.step.trim().length === 0) {
        ErrorToast(`Step is required `);
      } else {
        const firstEmptyIngredient = firstEmptyStepField.ingredients.find(
          (ingredient) =>
            // ingredient.image.trim().length === 0 ||
            ingredient.details.trim().length === 0 || ingredient.volume.trim().length === 0
        );
        if (firstEmptyIngredient) {
          // if (firstEmptyIngredient.image.trim().length === 0) {
          //   ErrorToast(`Ingredient Image is required `);
          // }
          //  else if (firstEmptyIngredient.details.trim().length === 0) {
          //   ErrorToast(`Ingredient Details is required `);
          // }
          // else
          if (firstEmptyIngredient.volume.length === 0) {
            ErrorToast(`Ingredient Volume is required `);
          }
        }
      }
    }
    setTabPan(1);
  };

  const handleSubmit = async () => {
    let dietaryid = [];
    dietaryid = newData?.dietary?.map((val) => {
      if (val?.value) {
        return val?.value;
      }
    });

    const emptyNutritionFields = nutritionFields.filter(
      (field) =>
        field.nutritionCategory.trim().length === 0 ||
        // field.image.trim().length === 0 ||
        field.details.length === 0
    );

    const emptyStepFields = stepFields.filter(
      (field) =>
        field.step.trim().length === 0 ||
        field.ingredients.some(
          (ingredient) =>
            // ingredient.image.trim().length === 0 ||
            // ingredient.details.trim().length === 0 ||
            ingredient.volume.length === 0
        )
    );

    let body = {
      title: newData.title,
      image: newData.url,
      time: newData.time,
      decription: newData?.decription,
      mealType: newData?.mealtype,
      difficulty: newData?.difficulty,
      dietary: dietaryid,
      nutrition: nutritionFields,
      steps: stepFields,
    };
    console.log("submitbody", body);
    if (
      newData?.title.trim().length > 0 &&
      newData?.url &&
      newData?.time.trim().length > 0 &&
      (newData?.decription ?? "").trim().length > 0 &&
      newData?.mealtype &&
      newData?.mealtype.length > 0 &&
      newData?.mealtype !== "" &&
      newData?.dietary?.length > 0 &&
      newData?.difficulty &&
      newData?.difficulty.length > 0 &&
      newData?.difficulty !== "" &&
      emptyNutritionFields.length === 0 &&
      emptyStepFields.length === 0
    ) {
      await ApiPost("/recipe", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModal(false);
          fetchData(currentpage, pagesize, "");
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (!newData?.title || newData?.title.trim().length === 0) {
      ErrorToast("Title is Required!");
    } else if (!newData?.url) {
      ErrorToast("Image is Required");
    } else if (!newData?.time || newData?.time.trim().length === 0) {
      ErrorToast("Time is Required!");
    } else if (!newData?.decription || (newData?.decription ?? "").trim().length === 0) {
      ErrorToast("Description is Required!");
    } else if (!newData?.mealtype || newData?.mealtype.length === 0 || newData?.mealtype === "") {
      ErrorToast("Meal Type is Required!");
    } else if (!newData?.difficulty || newData?.difficulty.length === 0 || newData?.difficulty === "") {
      ErrorToast("difficulty Type is Required!");
    } else if (!newData?.dietary || newData?.dietary.length === 0) {
      ErrorToast("Dietary is Required!");
    } else if (emptyNutritionFields.length > 0) {
      const firstEmptyNutritionField = emptyNutritionFields[0];
      if (firstEmptyNutritionField.nutritionCategory.length === 0) {
        ErrorToast(`Nutrition Category is required `);
      }
      // else if (firstEmptyNutritionField.image.trim().length === 0) {
      //   ErrorToast(`Nutrition Image is required `);
      // }
      else if (firstEmptyNutritionField.details.trim().length === 0) {
        ErrorToast(`Nutrition Volume is required `);
      }
    } else if (emptyStepFields.length > 0) {
      const firstEmptyStepField = emptyStepFields[0];
      if (firstEmptyStepField.step.trim().length === 0) {
        ErrorToast(`Step is required `);
      } else {
        const firstEmptyIngredient = firstEmptyStepField.ingredients.find(
          (ingredient) =>
            // ingredient.image.trim().length === 0 ||
            ingredient.details.trim().length === 0 || ingredient.volume.trim().length === 0
        );
        if (firstEmptyIngredient) {
          // if (firstEmptyIngredient.image.trim().length === 0) {
          //   ErrorToast(`Ingredient Image is required `);
          // } else
          if (firstEmptyIngredient.details.trim().length === 0) {
            ErrorToast(`Ingredient Details is required `);
          } else if (firstEmptyIngredient.volume.trim().length === 0) {
            ErrorToast(`Ingredient Volume is required `);
          }
        }
      }
    }
  };

  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), "");
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, "");
  };
  const handlesearch = (e) => {
    setsearching(e.target.value);
    fetchData(currentpage, pagesize, e.target.value);
  };
  // const imageChange = async (e) => {
  //   let file = e.target.files[0];
  //   const isValidImageType = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
  //   if (!isValidImageType) {
  //     ErrorToast("Invalid file type. Please select a valid image.");
  //     document.getElementById("url").value = null;
  //     return;
  //   }
  //   setLoading(true);
  //   let fileURL = URL.createObjectURL(file);
  //   file.fileURL = fileURL;
  //   let formData = new FormData();
  //   formData.append("image", file);
  //   await ApiUpload("upload/compress_image/profile_image", formData)
  //     .then((res) => {
  //       setNewData({ ...newData, url: res?.data?.publicUrl });
  //     })
  //     .catch((err) => console.log("res_blob", err));
  //   setLoading(false);
  // };

  const imageChange = async (e) => {
    let files = e.target.files;
    const isValidImageType = Array.from(files).every((file) =>
      ["image/jpeg", "image/png", "image/gif"].includes(file.type)
    );
    if (!isValidImageType) {
      ErrorToast("Invalid file type. Please select valid images.");
      document.getElementById("url").value = null;
      return;
    }
    setLoading(true);

    let newUrls = [...newData.url];
    await Promise.all(
      Array.from(files).map(async (file) => {
        let formData = new FormData();
        formData.append("image", file);
        try {
          const res = await ApiUpload("upload/compress_image/profile_image", formData);
          newUrls.push(res?.data?.publicUrl[0]);
        } catch (err) {
          console.log("Error uploading image:", err);
        }
      })
    );
    setNewData({ ...newData, url: newUrls });
    setLoading(false);
  };

  const fetchData = async (page, limit, search) => {
    let body = {
      page,
      limit,
      search,
    };
    await ApiPost("/recipe/get", body)
      .then((res) => {
        console.log("resData", res?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.count?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };

  const getMeal = async () => {
    let body = {
      page: 1,
      limit: 50,
      search: "",
    };
    await ApiPost("/meal/get", body)
      .then((res) => {
        console.log("mealres", res?.data?.data?.response);
        setMeal(res?.data?.data?.response);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const getDietary = async () => {
    let body = {
      page: 1,
      limit: 50,
      search: "",
    };
    await ApiPost("/dietary/get", body)
      .then((res) => {
        console.log("mealdie", res?.data?.data?.response);
        setDietary(res?.data?.data?.response);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const getNutrition = async () => {
    let body = {
      page: 1,
      limit: 50,
      search: "",
    };
    await ApiPost("/nutrition/get", body)
      .then((res) => {
        console.log("mealnutri", res?.data?.data?.response);
        setNutrition(res?.data?.data?.response);
      })
      .catch((err) => ErrorToast(err?.message));
  };

  useEffect(() => {
    fetchData(currentpage, pagesize, "");
    getMeal();
    getDietary();
    getNutrition();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Recipe Lists</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              recipe
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              {/* <h3 class="card-label">Video Playlist</h3> */}
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Name"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="card-toolbar">
              <a
                class="btn btn-color font-weight-bolder btn-sm"
                onClick={() => {
                  setIsEdit(false);
                  setModal(true);
                  setTabPan(1);
                  setNewData({
                    title: "",
                    description: "",
                    url: "",
                    time: "",
                    mealtype: "",
                    difficulty: "",
                    dietary: [],
                  });
                  setIngredientFields([
                    {
                      image: "",
                      details: "",
                      volume: "",
                    },
                  ]);
                  setStepFields([
                    {
                      step: "",
                      ingredients: [
                        {
                          image: "",
                          details: "",
                          volume: "",
                        },
                      ],
                      stepNumber: 1,
                    },
                  ]);
                  setNutritionFields([
                    {
                      nutritionCategory: "",
                      image: "",
                      details: "",
                    },
                  ]);
                }}
              >
                Add recipe
              </a>
            </div>
          </div>
          <div className="card-body mb-5">
            {/* <div class="row align-items-center">
              <div class="col-lg-12 col-xl-12">
                <div class="row align-items-center">
                  <div class="col-md-3 my-2">
                    <div class="input-icon">
                      <input
                        type="text"
                        class="form-control bg-light"
                        name="searchText"
                        placeholder="Search by Name"
                        value={searching}
                        onChange={(e) => handlesearch(e)}
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div class="row align-items-center">
              <div class="col-lg-12 col-xl-12">
                <div class="row align-items-center">
                  <div className="col-md-3 my-2">
                    <Dropdown
                      onSelect={handleonchnagestatus}
                      defaultValue="request"
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="text-capitalize"
                      >
                        {state1}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="approve">
                          Apporved
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="request">
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="reject">
                          Rejected
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-md-3 my-2">
                    <DateRangePicker onApply={apply} onCancel={cancel}>
                      <input
                        type="text"
                        className="form-control bg-light"
                        value={valll}
                        placeholder="Select Date Range"
                      />
                    </DateRangePicker>
                  </div>
                  <div className="col-md-3 my-2">
                    <Dropdown onSelect={handleonchnagestatus2}>
                      <Dropdown.Toggle id="dropdown-basic">
                        {state2 === true ? "Active" : "Inactive"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                        <Dropdown.Item eventKey="Inactive">
                          Inactive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div> */}
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => <NoDataTable />}
            // filter={filterFactory()}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        backdrop="static"
        // style={{ width: "300px" }}
        className="add-plan-modal2"
        onHide={() => {
          setModal(false);
          setLoading(false);
        }}
        centered
      >
        <Modal.Header closeButton={!loading} onClick={() => setTabPan(1)}>
          <Modal.Title>{!isEdit ? " Add Recipe" : "Recipe Edit Details"} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              {tabPan === 1 && (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Title"
                          label="Title"
                          id="title"
                          required
                          name="title"
                          onChange={handleChange}
                          value={newData.title}
                          disabled={loading}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group>
                        <Form.Label> Time To Cook</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter time"
                          label="time"
                          id="time"
                          required
                          name="time"
                          onChange={handleChange}
                          value={newData.time}
                          disabled={loading}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group>
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                          multiple
                          type="file"
                          label="url"
                          id="url"
                          required
                          className="form-control"
                          name="url"
                          accept=".pdf, .png, .jpg, .jpeg, .gif"
                          onChange={imageChange}
                          disabled={loading}
                        />
                        <span className="errorInput">
                          {/* {newData.url?.length > 0 ? "" : errors["url"]} */}
                        </span>
                        {/* {newData?.url && (
                          <div className="position-relative w-auto mt-3">
                            <img
                              src={newData?.url}
                              alt="Recipe image"
                              style={{ width: "100px", height: "100px" }}
                              className="rounded-3"
                            />
                            <span
                              onClick={() => {
                                setNewData({ ...newData, url: "" });
                                document.getElementById("url").value = null;
                              }}
                            >
                              <MdCancel color="red" fontSize={20} />
                            </span>
                          </div>
                        )} */}

                        {newData?.url && (
                          <div className="d-flex flex-wrap gap-3 position-relative w-auto mt-3">
                            {newData.url.map((imageUrl, index) => (
                              <div key={index} className="image-container">
                                <img
                                  src={imageUrl}
                                  alt={`Recipe img ${index + 1}`}
                                  style={{ width: "100px", height: "100px" }}
                                  className="rounded-3"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/media/img/fitnessLogo.png";
                                  }}
                                />
                                <span
                                  onClick={() => {
                                    const updatedUrls = [...newData.url];
                                    updatedUrls.splice(index, 1);
                                    setNewData({ ...newData, url: updatedUrls });
                                  }}
                                >
                                  <MdCancel color="red" fontSize={20} />
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="col-md-12">
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          disabled={loading}
                          rows={3}
                          placeholder="Enter description"
                          label="description"
                          id="description"
                          required
                          name="decription"
                          onChange={handleChange}
                          value={newData.decription}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group>
                        <Form.Label>Meal Type</Form.Label>

                        {/* <Form.Control
                          as="select"
                          custom
                          disabled={loading}
                          id="country"
                          required
                          className="form-control"
                          name="mealtype"
                          onChange={handleChange}
                          value={newData.mealtype}
                        >
                          <option value="">Select Meal</option>
                          {meal.map((item, index) => {
                            return <option value={item._id}>{item.details}</option>;
                          })}
                        </Form.Control> */}

                        <Select
                          isMulti
                          name="mealtype"
                          placeholder="Select mealtype"
                          isClearable={false}
                          isDisabled={loading}
                          options={meal.map((item) => ({
                            value: item._id,
                            label: item.details,
                          }))}
                          className="basic-multi-select"
                          classNamePrefix="selected"
                          // value={newData?.dietary}
                          // onChange={(e) => setNewData({ ...newData, dietary: e })}
                          value={
                            newData?.mealtype
                              ? newData?.mealtype.map((selectedValue) => {
                                const selectedItem = meal.find((item) => item._id === selectedValue);
                                return {
                                  value: selectedValue,
                                  label: selectedItem ? selectedItem.details : selectedValue,
                                };
                              })
                              : []
                          }
                          // onChange={(selectedOption) => {
                          //   setNewData({ ...newData, mealtype: selectedOption });
                          //   // console.log("selectefOption", selectedOption);
                          // }}
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions?.map((option) => option.value) || [];
                            setNewData({ ...newData, mealtype: selectedValues });
                          }}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              color: "black",
                              padding: "0 10px",
                              fontSize: "1.1rem",
                              "&:hover": {
                                backgroundColor: "#007eff",
                                color: "white",
                                fontWeight: "500",
                              },
                              "&:focus-visible": {
                                backgroundColor: "red",
                                color: "white",
                                fontWeight: "500",
                              },
                            }),
                            menu: (provided) => ({
                              ...provided,
                              margin: "0",
                            }),
                            placeholder: (provided) => {
                              return {
                                ...provided,
                                color: "#181c32",
                                fontWeight: "500",
                              };
                            },
                          }}
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="col-md-4">
                  <Form.Group>
                    <Form.Label>dietary</Form.Label>

                    <Form.Control
                      as="select"
                      custom
                      id="country"
                      required
                      className="form-control"
                      name="dietary"
                      onChange={handleChange}

                      // value={newData.category}
                    >
                      <option value="">Select Meal</option>

                      <option value="FoundationsofTermLifeInsurance">
                        Foundations of Term Life Insurance1
                      </option>
                      <option value="TypesofTermLifeInsurance">
                        Types of Term Life Insurance
                      </option>
                      <option value="FrequentlyAskedQuestions">
                        Frequently Asked Questions
                      </option>
                    </Form.Control>
                  </Form.Group>
                </div> */}
                    <div className="col-md-4">
                      <Form.Group>
                        <Form.Label>Dietary</Form.Label>
                        <Select
                          isMulti
                          name="dietary"
                          placeholder="Select dietarys"
                          isClearable={false}
                          isDisabled={loading}
                          options={dietary.map((item) => ({
                            value: item._id,
                            label: item.details,
                          }))}
                          className="basic-multi-select"
                          classNamePrefix="selected"
                          // value={newData?.dietary}
                          // onChange={(e) => setNewData({ ...newData, dietary: e })}
                          value={newData?.dietary}
                          onChange={(selectedOption) => {
                            setNewData({ ...newData, dietary: selectedOption });
                            // console.log("selectefOption", selectedOption);
                          }}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              color: "black",
                              padding: "0 10px",
                              fontSize: "1.1rem",
                              "&:hover": {
                                backgroundColor: "#007eff",
                                color: "white",
                                fontWeight: "500",
                              },
                              "&:focus-visible": {
                                backgroundColor: "red",
                                color: "white",
                                fontWeight: "500",
                              },
                            }),
                            menu: (provided) => ({
                              ...provided,
                              margin: "0",
                            }),
                            placeholder: (provided) => {
                              return {
                                ...provided,
                                color: "#181c32",
                                fontWeight: "500",
                              };
                            },
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group>
                        <Form.Label>Difficulty</Form.Label>

                        <Form.Control
                          as="select"
                          custom
                          id="country"
                          required
                          className="form-control"
                          name="difficulty"
                          onChange={handleChange}
                          value={newData.difficulty}
                          disabled={loading}
                        >
                          <option value="">Select difficulty</option>

                          <option value="1">Könnyű</option>
                          <option value="2">Nehéz</option>
                        </Form.Control>
                      </Form.Group>
                    </div>

                    <div>
                      {nutritionFields.map((field, index) => (
                        <div className="row" key={field.id}>
                          <div className="col-md-4">
                            <Form.Group>
                              <Form.Label>Nutrition</Form.Label>
                              <Form.Control
                                as="select"
                                custom
                                id={`nutrition_${field.id}`}
                                required
                                className="form-control"
                                name={`nutritionCategory`}
                                value={field?.nutritionCategory}
                                onChange={(event) => handleNutritionChange(index, event)}
                                disabled={loading}
                              >
                                <option value="">Select Nutrition</option>

                                {nutrition.map((item, index) => {
                                  return <option value={item._id}>{item.name}</option>;
                                })}
                              </Form.Control>
                            </Form.Group>
                          </div>
                          {/* <div className="col-md-4">
                        <Form.Group>
                          <Form.Label>Image</Form.Label>
                          <Form.Control
                            type="file"
                            label="url"
                            required
                            className="form-control"
                            name={`image`}
                            onChange={async (e) => {
                              let data = [...nutritionFields];
                              let file = e.target.files[0];
                              let fileURL = URL.createObjectURL(file);
                              file.fileURL = fileURL;
                              let formData = new FormData();
                              formData.append("image", file);
                              await ApiUpload(
                                "upload/compress_image/profile_image",
                                formData
                              )
                                .then((res) => {
                                  data[index]["image"] = res?.data?.data?.image;
                                  console.log(
                                    "res?.data?.data?.image",
                                    res?.data?.data?.image
                                  );
                                  setNutritionFields(data);
                                })
                                .catch((err) => console.log("res_blob", err));
                            }}
                          />
                        </Form.Group>
                      </div> */}
                          <div className="col-md-3">
                            <Form.Group>
                              <Form.Label>Volume</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter details"
                                label="detail"
                                required
                                name={`details`}
                                onChange={(event) => handleNutritionChange(index, event)}
                                value={field?.details}
                                disabled={loading}
                              />
                            </Form.Group>
                          </div>
                          {/* {index > 0 && ( */}
                          <div className="col-md-1 text-end">
                            <div
                              className={`h-100 text-center pt-12 text-danger `}
                              role="button"
                              onClick={() => handleRemoveNutrition(index)}
                            >
                              <AiOutlineMinusCircle fontSize="25" />
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                      ))}

                      <div className="d-flex ">
                        <label className="lablebtn" onClick={handleAddNutrition}>
                          <AiOutlinePlusCircle fontSize="20" /> Add more nutrition
                        </label>
                      </div>
                    </div>
                    <div className="col-md-10"> </div>
                    <div className="col-md-2 d-flex justify-content-end">
                      {" "}
                      <button
                        className="btn btn-color"
                        disabled={loading}
                        onClick={() => {
                          submitStep();
                        }}
                      >
                        {loading ? "Loading..." : "Next"}
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* <div>
                  <div className="row">
                    <div className="col-md-8 mt-5 ">
                      <p className="font-weight-bold">Steps</p>
                    </div>
                    <div className="col-md-4 text-end mt-5">
                      <button className="btn btn-color" onClick={handleAddStep}>
                        Add More Steps
                      </button>
                    </div>
                  </div>
                  {stepFields.map((step, index) => (
                    <div className="row" key={index}>
                      <div className="col-md-12">
                        <Form.Group>
                          <Form.Label>Step</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            placeholder="Enter step"
                            label="step"
                            id={`step_${index}`}
                            required
                            name={`step_${index}`}
                            onChange={(event) => handleChange(event, index)}
                            // value={step}
                          />
                        </Form.Group>
                      </div>
                      {index > 0 && (
                        <div className="col-md-12 text-end">
                          <button
                            className="btn btn-danger"
                            onClick={() => handleRemoveStep(index)}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="col-md-12">
                  <p className="font-weight-bold">Ingredients</p>
                </div> */}
              {tabPan === 2 && (
                <>
                  <div>
                    <div className="row">
                      <div className="col-md-8 mt-5 ">
                        <p className="font-weight-bold fs-20px">Steps</p>
                      </div>
                      <div className="col-md-4 text-end mt-5">
                        <label className="lablebtn" onClick={handleAddStep}>
                          <AiOutlinePlusCircle fontSize="20" /> Add More Steps
                        </label>
                      </div>
                    </div>
                    {stepFields.map((item, index) => (
                      <>
                        <div className="row" key={index}>
                          <div className="col-md-11">
                            <Form.Group>
                              <Form.Label className="fs-4">Step {index + 1}</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter step"
                                label="step"
                                required
                                name={`step`}
                                onChange={(event) => handleStepChange(index, event)}
                                value={item?.step}
                                disabled={loading}
                              />
                            </Form.Group>
                          </div>
                          {index > 0 && (
                            <div className="col-md-1 text-end">
                              <div
                                className={`h-100 text-center pt-20 text-danger `}
                                role="button"
                                onClick={() => handleRemoveStep(index)}
                              >
                                <AiOutlineMinusCircle fontSize="25" />
                              </div>
                            </div>
                          )}
                        </div>
                        {item?.ingredients.map((item1, index1) => {
                          return (
                            <>
                              <div className="row" key={index1}>
                                <div className="col-md-4">
                                  <Form.Group>
                                    <Form.Label>Ingredients Detail</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter detail"
                                      label="detail"
                                      required
                                      name={`details`}
                                      value={item1?.details}
                                      disabled={loading}
                                      onChange={(event) => handleIngredientChange(index, index1, event)}
                                    />
                                  </Form.Group>
                                </div>
                                {/* <div className="col-md-4">
                                  <Form.Group>
                                    <Form.Label>Ingredients Image</Form.Label>
                                    <Form.Control
                                      id={`ingredients${index1}`}
                                      type="file"
                                      label="ingredientsimg"
                                      required
                                      className="form-control"
                                      name={`image`}
                                      onChange={async (e) => {
                                        setLoading(true);
                                        const data = [...stepFields];
                                        let file = e.target.files[0];
                                        let fileURL = URL.createObjectURL(file);
                                        file.fileURL = fileURL;
                                        let formData = new FormData();
                                        formData.append("image", file);
                                        await ApiUpload("upload/compress_image/profile_image", formData)
                                          .then((res) => {
                                            const newData = stepFields.map((step, i) =>
                                              i === index
                                                ? {
                                                    ...step,
                                                    ingredients: step.ingredients.map((ingredient, j) =>
                                                      j === index1
                                                        ? { ...ingredient, image: res?.data?.publicUrl }
                                                        : ingredient
                                                    ),
                                                  }
                                                : step
                                            );

                                            setStepFields(newData);
                                          })
                                          .catch((err) => console.log("res_blob", err));
                                        setLoading(false);
                                      }}
                                    />
                                    <span className="errorInput"></span>
                                    {item1?.image && (
                                      <div className="position-relative w-auto mt-3">
                                        <img
                                          src={item1?.image}
                                          alt="Ingredients image"
                                          style={{ width: "100px", height: "100px" }}
                                          className="rounded-3"
                                        />
                                        <span
                                          onClick={() => {
                                            const newData = stepFields.map((step, i) =>
                                              i === index
                                                ? {
                                                    ...step,
                                                    ingredients: step.ingredients.map((ingredient, j) =>
                                                      j === index1 ? { ...ingredient, image: "" } : ingredient
                                                    ),
                                                  }
                                                : step
                                            );

                                            setStepFields(newData);
                                            document.getElementById(`ingredients${index1}`).value = null;
                                          }}
                                        >
                                          <MdCancel color="red" fontSize={20} />
                                        </span>
                                      </div>
                                    )}
                                  </Form.Group>
                                </div> */}

                                <div className="col-md-4">
                                  <Form.Group>
                                    <Form.Label>Ingredients Image</Form.Label>
                                    <Form.Control
                                      id={`ingredients${index1}`}
                                      type="file"
                                      label="ingredientsimg"
                                      className="form-control"
                                      name={`image`}
                                      disabled={loading}
                                      onChange={async (e) => {
                                        setLoading(true);
                                        const data = [...stepFields];
                                        let file = e.target.files[0];

                                        if (file) {
                                          let fileURL = URL.createObjectURL(file);
                                          file.fileURL = fileURL;

                                          let formData = new FormData();
                                          formData.append("image", file);

                                          await ApiUpload("upload/compress_image/profile_image", formData)
                                            .then((res) => {
                                              const newData = stepFields.map((step, i) =>
                                                i === index
                                                  ? {
                                                    ...step,
                                                    ingredients: step.ingredients.map((ingredient, j) =>
                                                      j === index1
                                                        ? { ...ingredient, image: res?.data?.publicUrl[0] }
                                                        : ingredient
                                                    ),
                                                  }
                                                  : step
                                              );

                                              setStepFields(newData);
                                            })
                                            .catch((err) => console.log("res_blob", err));
                                        } else {
                                          // No file selected, handle it as needed
                                          // For example, you may want to set a default image or do nothing
                                        }

                                        setLoading(false);
                                      }}
                                    />
                                    <span className="errorInput"></span>
                                    {item1?.image && (
                                      <div className="position-relative w-auto mt-3">
                                        <img
                                          src={item1?.image}
                                          alt="Ingredients image"
                                          style={{ width: "100px", height: "100px" }}
                                          className="rounded-3"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "/media/img/fitnessLogo.png";
                                          }}
                                        />
                                        <span
                                          onClick={() => {
                                            const newData = stepFields.map((step, i) =>
                                              i === index
                                                ? {
                                                  ...step,
                                                  ingredients: step.ingredients.map((ingredient, j) =>
                                                    j === index1 ? { ...ingredient, image: "" } : ingredient
                                                  ),
                                                }
                                                : step
                                            );

                                            setStepFields(newData);
                                            document.getElementById(`ingredients${index1}`).value = null;
                                          }}
                                        >
                                          <MdCancel color="red" fontSize={20} />
                                        </span>
                                      </div>
                                    )}
                                  </Form.Group>
                                </div>

                                <div className="col-md-3">
                                  <Form.Group>
                                    <Form.Label>Ingredients Volume</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter volume"
                                      label="volume"
                                      required
                                      name={`volume`}
                                      onChange={(event) => handleIngredientChange(index, index1, event)}
                                      value={item1?.volume}
                                      disabled={loading}
                                    />
                                  </Form.Group>
                                </div>
                                {index1 > 0 && (
                                  <div className="col-md-1 text-end">
                                    <div
                                      className={`h-100 text-center pt-12 text-danger `}
                                      role="button"
                                      onClick={() => handleRemoveIngredient(index, index1)}
                                    >
                                      <AiOutlineMinusCircle fontSize="25" />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <label className="lablebtn" onClick={() => handleAddIngredient(index)}>
                              <AiOutlinePlusCircle fontSize="20" /> Add more Ingredients
                            </label>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  {/* <Modal.Footer> */}
                  {isEdit ? (
                    <div className="d-flex  justify-content-between ">
                      <button disabled={loading} className=" btn btn-secondary" onClick={handlePrevious}>
                        Previous
                      </button>
                      <button disabled={loading} className="btn btn-color" onClick={onUpdate}>
                        {loading ? "Loading..." : "Update Changes"}
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex  justify-content-between ">
                      <button disabled={loading} className=" btn btn-secondary" onClick={handlePrevious}>
                        Previous
                      </button>
                      <button disabled={loading} className="  btn btn-color" onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  )}
                  {/* </Modal.Footer> */}
                </>
              )}

              {/* <div>
                  <div className="row">
                    <div className="col-md-8 mt-5 ">
                      <p className="font-weight-bold fs-20px">Steps</p>
                    </div>
                    <div className="col-md-4 text-end mt-5">
                      <button
                        className="btn btn-color btn-sm"
                        onClick={handleAddStep}
                      >
                        Add More Steps
                      </button>
                    </div>
                  </div>
                  {stepFields.map((item, index) => (
                    <>
                      <div className="row" key={index}>
                        <div className="col-md-11">
                          <Form.Group>
                            <Form.Label>Step {index + 1}</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter step"
                              label="step"
                              required
                              name={`step`}
                              onChange={(event) =>
                                handleStepChange(index, event)
                              }
                              value={item?.step}
                            />
                          </Form.Group>
                        </div>
                        {index > 0 && (
                          <div className="col-md-1 text-end">
                            <div
                              className={`h-100 text-center pt-12 text-danger `}
                              role="button"
                              onClick={() => handleRemoveStep(index)}
                            >
                              <AiOutlineMinusCircle fontSize="25" />
                            </div>
                          </div>
                        )}
                      </div>
                      {item?.ingredients.map((item1, index1) => (
                        <>
                          <div className="row" key={index1}>
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                  type="file"
                                  label="ingredientsimg"
                                  required
                                  className="form-control"
                                  name={`image`}
                                  onChange={async (e) => {
                                    const data = [...stepFields];
                                    let file = e.target.files[0];
                                    let fileURL = URL.createObjectURL(file);
                                    file.fileURL = fileURL;
                                    let formData = new FormData();
                                    formData.append("image", file);
                                    await ApiUpload(
                                      "upload/compress_image/profile_image",
                                      formData
                                    )
                                      .then((res) => {
                                        data[index] = {
                                          ...data[index],
                                          ingredients: [
                                            {
                                              ...data[index]?.ingredients[
                                                index1
                                              ],
                                              image: res?.data?.data?.image,
                                            },
                                          ],
                                        };
                                        setStepFields(data);
                                      })
                                      .catch((err) =>
                                        console.log("res_blob", err)
                                      );
                                  }}
                                />
                                <span className="errorInput"></span>
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group>
                                <Form.Label>Detail</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter detail"
                                  label="detail"
                                  required
                                  name={`details`}
                                  value={item1?.details}
                                  onChange={(event) =>
                                    handleIngredientChange(index, index1, event)
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="col-md-3">
                              <Form.Group>
                                <Form.Label>Volume</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter volume"
                                  label="volume"
                                  required
                                  name={`volume`}
                                  onChange={(event) =>
                                    handleIngredientChange(index, index1, event)
                                  }
                                  value={item1?.volume}
                                />
                              </Form.Group>
                            </div>
                            {index1 > 0 && (
                              <div className="col-md-1 text-end">
                                <div
                                  className={`h-100 text-center pt-12 text-danger `}
                                  role="button"
                                  onClick={() =>
                                    handleRemoveIngredient(index, index1)
                                  }
                                >
                                  <AiOutlineMinusCircle fontSize="25" />
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <button
                            className="btn btn-color btn-sm"
                            onClick={() => handleAddIngredient(index)}
                          >
                            Add more Ingredients
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                </div> */}

              {/* <div>
                  {ingredientFields.map((field, index) => (
                    <div className="row" key={field.id}>
                      <div className="col-md-4">
                        <Form.Group>
                          <Form.Label>Image</Form.Label>
                          <Form.Control
                            type="file"
                            label="ingredientsimg"
                            id={`ingredientsimg_${field.id}`}
                            required
                            className="form-control"
                            name={`ingredientsimg_${field.id}`}
                            // onChange={(event) =>
                            //   handleImageChange(event, field.id)
                            // }
                          />
                          <span className="errorInput"></span>
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group>
                          <Form.Label>Detail</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter detail"
                            label="detail"
                            id={`detail_${field.id}`}
                            required
                            name={`detail_${field.id}`}
                            onChange={(event) => handleChange(event, field.id)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-3">
                        <Form.Group>
                          <Form.Label>Volume</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter volume"
                            label="volume"
                            id={`volume_${field.id}`}
                            required
                            name={`volume_${field.id}`}
                            onChange={(event) => handleChange(event, field.id)}
                          />
                        </Form.Group>
                      </div>
                      {index > 0 && (
                        <div className="col-md-1 text-end">
                          <div
                            className={`h-100 text-center pt-12 text-danger `}
                            role="button"
                            // onClick={() => handleRemoveIngredient(field.id)}
                          >
                            <AiOutlineMinusCircle fontSize="25" />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-md-4">
                      <button
                        className="btn btn-color"
                        // onClick={handleAddIngredient}
                      >
                        Add more Ingredients
                      </button>
                    </div>
                  </div>
                </div> */}
              {/* </div> */}

              {/* <Form.Group md="12">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                label="description"
                id="description"
                required
                name="description"
                onChange={handleChange}
                value={newData.description}
              />
              <span className="errorInput">
              
              </span>
            </Form.Group>
            <Form.Group md="12">
              <Form.Label>Tag</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tag"
                label="tag"
                id="tag"
                required
                name="tag"
                onChange={handleChange}
                value={newData.tag}
              />
              <span className="errorInput">
             
              </span>
            </Form.Group> */}
              {/* <Form.Group md="12">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Url"
                label="url"
                id="url"
                required
                name="url"
                onChange={handleChange}
                value={newData.url}
              />
              <span className="errorInput">
                 {newData.url?.length > 0 ? "" : errors["url"]}
              </span>
            </Form.Group> */}
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          {isEdit ? (
            <button className="btn btn-color" onClick={onUpdate}>
              Update Changes
            </button>
          ) : (
            <button className="btn btn-color" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default Recipe;
