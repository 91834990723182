import React, { useState } from "react";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NoDataTable from "../../../common/noDataTable";
import {
  ApiDelete,
  ApiPost,
  ApiPut,
  ApiUpload,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";
import thumbDefault from "../../../media/thumbDefault.jpg";
import { MdCancel } from "react-icons/md";

function Dietary() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searching, setsearching] = useState("");
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [newData, setNewData] = useState({
    title: "",
    url: "",
  });
  const columns = [
    {
      dataField: "image",
      text: "Image",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50px symbol-light mr-4">
                <img
                  src={row?.image ? row?.image : thumbDefault}
                  className="img-fluid"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/media/img/fitnessLogo.png";
                  }}
                />
              </div>
              {/* <div>
                <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                  {cell ? cell : "-"}
                </a>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      dataField: "details",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell && moment(cell).format("DD-MM-YYYY HH:mm A")}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => editBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>

            <a
              title="Delete customer"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to Delete ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Delete`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onDelete(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </a>
          </>
        );
      },
    },
  ];
  // console.log("newData", newData);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setNewData({ ...newData, [name]: value });
  };
  const editBtnClick = (row) => {
    console.log("row", row);
    setIsEdit(true);
    setModal(true);
    let tag = row?.tag?.length > 1 ? row.tag.join(", ") : row?.tag;
    setNewData({
      id: row?._id,
      title: row?.details,
      url: row?.image,
    });
  };
  const onDelete = async (Id) => {
    await ApiDelete(`/dietary/${Id}`)
      .then((res) => {
        fetchData(currentpage, pagesize, "");
        Swal.fire({
          text: "You have Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onUpdate = async () => {
    setLoading(true);
    let tagArr = newData?.tag?.includes(",")
      ? newData?.tag?.split(", ")
      : newData?.tag;
    let body = {
      id: newData?.id,
      details: newData.title,
      image: newData.url,
    };
    await ApiPut("/dietary", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(currentpage, pagesize, "");
        setLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setLoading(false);
      });
  };
  // const validateForm = () => {
  //   console.log("valid");
  //   let errors = {};
  //   let formIsValid = true;

  //   if (!data.title) {
  //     console.log("a");
  //     formIsValid = false;
  //     document.getElementById("title").style.border = "2px solid #f64e60";
  //     errors["title"] = "Please Enter title";
  //   }
  //   if (!data.description) {
  //     console.log("a");
  //     formIsValid = false;
  //     document.getElementById("description").style.border = "2px solid #f64e60";
  //     errors["description"] = "Please Enter Description";
  //   }
  //   setError(errors);

  //   return formIsValid;
  // };
  const handleSubmit = async () => {
    setLoading(true);
    let tagArr = newData?.tag.split(", ");
    let body = {
      details: newData.title,
      //   description: newData.description,
      //   tag: tagArr,
      image: newData.url,
    };
    await ApiPost("/dietary", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(currentpage, pagesize, "");
        setLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setLoading(false);
      });
  };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), "");
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, "");
  };
  const handlesearch = (e) => {
    setsearching(e.target.value);
    fetchData(currentpage, pagesize, e.target.value);
  };
  const imageChange = async (e) => {
    let file = e.target.files[0];
    const isValidImageType = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
    if (!isValidImageType) {
      ErrorToast("Invalid file type. Please select a valid image.");
      document.getElementById("url").value = null;
      return;
    }

    setLoading(true);
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("upload/compress_image/profile_image", formData)
      .then((res) => {
        setNewData({ ...newData, url: res?.data?.publicUrl[0] });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const fetchData = async (page, limit, search) => {
    let body = {
      page,
      limit,
      search,
    };
    await ApiPost("/dietary/get", body)
      .then((res) => {
        console.log("resData", res?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };

  useEffect(() => {
    fetchData(currentpage, pagesize, "");
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Dietary Lists</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Dietary
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              {/* <h3 class="card-label">Video Playlist</h3> */}
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Name"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="card-toolbar">
              <a
                class="btn btn-color font-weight-bolder btn-sm"
                onClick={() => {
                  setIsEdit(false);
                  setModal(true);
                  setNewData({ title: "", description: "", tag: "", url: "" });
                }}
              >
                Add dietary
              </a>
            </div>
          </div>
          <div className="card-body mb-5">
            {/* <div class="row align-items-center">
              <div class="col-lg-12 col-xl-12">
                <div class="row align-items-center">
                  <div class="col-md-3 my-2">
                    <div class="input-icon">
                      <input
                        type="text"
                        class="form-control bg-light"
                        name="searchText"
                        placeholder="Search by Name"
                        value={searching}
                        onChange={(e) => handlesearch(e)}
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div class="row align-items-center">
              <div class="col-lg-12 col-xl-12">
                <div class="row align-items-center">
                  <div className="col-md-3 my-2">
                    <Dropdown
                      onSelect={handleonchnagestatus}
                      defaultValue="request"
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="text-capitalize"
                      >
                        {state1}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="approve">
                          Apporved
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="request">
                          Pending
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="reject">
                          Rejected
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-md-3 my-2">
                    <DateRangePicker onApply={apply} onCancel={cancel}>
                      <input
                        type="text"
                        className="form-control bg-light"
                        value={valll}
                        placeholder="Select Date Range"
                      />
                    </DateRangePicker>
                  </div>
                  <div className="col-md-3 my-2">
                    <Dropdown onSelect={handleonchnagestatus2}>
                      <Dropdown.Toggle id="dropdown-basic">
                        {state2 === true ? "Active" : "Inactive"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                        <Dropdown.Item eventKey="Inactive">
                          Inactive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div> */}
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => <NoDataTable />}
            // filter={filterFactory()}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {!isEdit ? " Add Dietary" : "Dietary Edit Details"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group md="12">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                label="Title"
                id="title"
                required
                name="title"
                onChange={handleChange}
                value={newData.title}
              />
              <span className="errorInput">
                {/* {newData.title?.length > 0 ? "" : errors["title"]} */}
              </span>
            </Form.Group>
            {/* <Form.Group md="12">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                label="description"
                id="description"
                required
                name="description"
                onChange={handleChange}
                value={newData.description}
              />
              <span className="errorInput">
              
              </span>
            </Form.Group>
            <Form.Group md="12">
              <Form.Label>Tag</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tag"
                label="tag"
                id="tag"
                required
                name="tag"
                onChange={handleChange}
                value={newData.tag}
              />
              <span className="errorInput">
             
              </span>
            </Form.Group> */}
            {/* <Form.Group md="12">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Url"
                label="url"
                id="url"
                required
                name="url"
                onChange={handleChange}
                value={newData.url}
              />
              <span className="errorInput">
                 {newData.url?.length > 0 ? "" : errors["url"]}
              </span>
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Thumbnail URL</Form.Label>
              <Form.Control
                type="file"
                label="url"
                id="url"
                required
                className="form-control"
                name="url"
                accept=".pdf, .png, .jpg, .jpeg, .gif"
                onChange={imageChange}
              />
              <span className="errorInput">
                {/* {newData.url?.length > 0 ? "" : errors["url"]} */}
              </span>
              {newData?.url && <div className="position-relative w-auto mt-3">
                <img
                  src={newData?.url}
                  alt="dietary image"
                  style={{ width: "100px", height: "100px" }}
                  className="rounded-3"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/media/img/fitnessLogo.png";
                  }}
                />
                <span
                  onClick={() => {
                    setNewData({ ...newData, url: "" });
                    document.getElementById("url").value = null;
                  }}
                >
                  <MdCancel color="red" fontSize={20} />
                </span>
              </div>}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button
              className="btn btn-color"
              onClick={onUpdate}
              disabled={loading}
            >
              {loading ? "Loading" : "Update Changes"}
            </button>
          ) : (
            <button
              className="btn btn-color"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Loading" : "Submit"}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Dietary;
