import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Navigation } from "swiper";
import { ApiGet } from "../../../helpers/API/ApiData";
import { IoIosArrowDropleft } from "react-icons/io";
import { IoIosArrowDropright } from "react-icons/io";

function PlanDetails() {
    const history = useHistory();
    const [data, setData] = useState([]);
    const Id = window.location.pathname.split("/")[2];
    const [image, setImage] = useState({ data: [], ind: 0 });
    let day = { 0: "Monday", 1: "Tuesday", 2: "Wednesday", 3: "Thursday", 4: "Friday", 5: "Saturday", 6: "Sunday" }
    useEffect(() => {
        if (Id) {
            fetchData();
        }
    }, [Id]);

    const fetchData = async () => {
        await ApiGet(`/recipeplan/get/${Id}`)
            .then((res) => {
                setData(res?.data?.data[0]);
                setImage({ ...image, data: res?.data?.data[0]?.image })
            })
            .catch((err) => console.log("err", err));
    };

    const handleLeft = () => {
        setImage({
            ...image,
            ind: image?.ind === 0 ? image?.data.length - 1 : image?.ind - 1,
        });
    }

    const handleRight = () => {
        setImage({
            ...image,
            ind: image?.ind === image?.data.length - 1 ? 0 : image?.ind + 1,
        });
    }
    return (
        <div className="plan-details">
            <div className="d-flex justify-content-between mb-4">
                <div className="title">
                    <div className="fs-20px fw-bolder">Plan Details</div>
                    <div>
                        <span
                            role="button"
                            onClick={() => history.push("/dashboard")}
                            className="text-hover-info text-muted"
                        >
                            Home
                        </span>{" "}
                        -{" "}
                        <span
                            className="text-hover-info text-muted"
                            role="button"
                            onClick={() => history.push("/recipe-plan")}
                        >
                            {" "}
                            Recipe Plan
                        </span>{" "}
                        -{" "}
                        <span className="text-muted" role="button">
                            {" "}
                            Recipe Plan Details
                        </span>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body p-lg-20">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="h-100 d-flex flex-column justify-content-between pe-lg-6 mb-lg-0 mb-10">
                                <div class="h-300px d-flex justify-content-center align-items-center position-relative">
                                    {image?.data.length > 1 && <div className='pointer position-absolute bg-white rounded-circle' style={{ left: "4px" }} onClick={handleLeft}><IoIosArrowDropleft fontSize={30} /></div>}
                                    <img
                                        className="embed-responsive-item card-rounded"
                                        src={
                                            image.data[image.ind] ||
                                            toAbsoluteUrl("/media/img/loader.gif")
                                        }
                                    />
                                    {image?.data.length > 1 && <div className='pointer position-absolute bg-white rounded-circle' style={{ right: "4px" }} onClick={handleRight} ><IoIosArrowDropright fontSize={30} /></div>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="mb-5 pb-5">
                                <a class="fs-2 text-dark fw-bold text-hover-info text-dark lh-base text-capitalize">
                                    {data?.title}
                                </a>
                                <div class="fw-semibold fs-5 text-gray-600 text-dark mt-4 text-justify">
                                    {data?.decription || "No description"}
                                </div>
                            </div>
                            <div className="">
                                <div className="text-hover-danger cursor-default my-3">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/assets/media/svg/icons/Cooking/Fork-spoon.svg"
                                        )}
                                        className="me-4"
                                    />
                                    Type:{" "}
                                    <span className="text-uppercase">
                                        {" "}
                                        {data?.type || "-"}
                                    </span>
                                </div>
                                <div className="text-hover-danger cursor-default my-3">
                                    <SVG
                                        src={toAbsoluteUrl(
                                            "/assets/media/svg/icons/Home/Book-open.svg"
                                        )}
                                        className="me-4"
                                    />
                                    Notes: {data?.notes || "-"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        {data?.result?.map((v, ind) => {
                            return (
                                <div class="mb-5 mt-10" key={v?._id}>
                                    <div class="d-flex flex-stack">
                                        <div className="">
                                            <h3>
                                                {day[v.day]}
                                            </h3>
                                        </div>
                                    </div>
                                    <div class="separator separator-dashed mb-9"></div>
                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={20}
                                        slidesPerGroup={1}
                                        navigation={{
                                            nextEl: `.nextBtn${ind}`,
                                            prevEl: `.previousBtn${ind}`,
                                            disabledClass: "disabledBtn",
                                        }}
                                        modules={[Navigation]}
                                        loopFillGroupWithBlank={true}
                                        className="mySwiper"
                                        breakpoints={{
                                            280: { slidesPerView: 1 },
                                            600: { slidesPerView: 2 },
                                            768: { slidesPerView: 3 },
                                            992: { slidesPerView: 3 },
                                            1500: { slidesPerView: 4 },
                                        }}
                                    >
                                        {v?.result1 && v?.result1?.length > 0 ? v?.result1?.map((item) => {
                                            return (
                                                <SwiperSlide key={item._id}>
                                                    <div class="">
                                                        <div class="card-xl-stretch">
                                                            <div className="slider-img h-200px">
                                                                <img src={item?.image[0]} alt="recipe image" className="w-100 h-100" />
                                                            </div>
                                                            <div class="mt-3">
                                                                <div class="fs-4 text-dark fw-bold  text-dark lh-base" > {item?.title}</div>
                                                                <div class="fw-semibold fs-5 text-gray-600 text-dark my-4">
                                                                    {item?.time}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        }) : <div>No data found</div>}
                                    </Swiper>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlanDetails;
