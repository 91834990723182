import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Navigation, Pagination } from "swiper";
import { ApiGet } from "../../../helpers/API/ApiData";
import moment from "moment";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

function PlanPreview({ row }) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [otherData, setOtherData] = useState("");
  console.log("dataasd", row);

  const fetchData = async () => {
    await ApiGet(`/plan/get/${row}`)
      .then((res) => {
        console.log("/plan/get", res.data);
        setData(res?.data?.data[0]);
        let a = res?.data?.data[0]?.equipment;
        let b = a.join(" , ");
        setOtherData({ ...otherData, equipment: b });
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="plan-details">
        <div className="row">
          <div className="col-md-6">
            <div class="h-100 d-flex flex-column justify-content-between mb-lg-0 mb-10">
              <div class="h-250px">
                <img
                  className="embed-responsive-item card-rounded"
                  src={
                    data?.thumbnail ||
                    "https://thumbs.dreamstime.com/b/closeup-portrait-muscular-man-workout-barbell-gym-brutal-bodybuilder-athletic-six-pack-perfect-abs-shoulders-55122231.jpg"
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div class="mb-5">
              <a
                href="#"
                class="fs-2 text-dark fw-bold text-hover-info text-dark lh-base text-capitalize"
              >
                {data?.title}
              </a>
              <div class="fw-semibold text-gray-600 text-dark mt-2 text-justify">
                {data?.description || "No description"}
              </div>
            </div>
            <div className="">
              <div className="text-hover-danger cursor-default">
                <img
                  src="/assets/media/logos/power.png"
                  className="me-3 h-30px"
                  alt=""
                />
                Intensity {data?.intensity || "null"}
              </div>
              <div className="my-3 text-hover-danger cursor-default">
                <img
                  src="/assets/media/logos/time.png"
                  className="me-3 h-30px"
                  alt=""
                />
                Duration {data?.duration || "null"}
              </div>
              <div className="text-hover-danger cursor-default">
                <img
                  src="/assets/media/logos/award.png"
                  className="me-3 h-30px"
                  alt=""
                />
                Equipments {otherData?.equipment || "null"}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          {data?.adminData?.map((v, ind) => {
            return (
              <>
                <div class="mb-5 mt-10">
                  <div class="d-flex flex-stack">
                    <div className="">
                      {v?.workout_data?.length !== 0 && (
                        <div class="text-danger mb-1 text-capitalize">
                          {v?.exercise || ""}
                        </div>
                      )}
                      <h3>
                        {v?.day === 0
                          ? "Monday"
                          : v?.day === 1
                            ? "Tuesday"
                            : v?.day === 2
                              ? "Wednesday"
                              : v?.day === 3
                                ? "Thursday"
                                : v?.day === 4
                                  ? "Friday"
                                  : v?.day === 5
                                    ? "Saturday"
                                    : v?.day === 6
                                      ? "Sunday"
                                      : ""}
                      </h3>
                    </div>
                    {v?.calories && (
                      <div className="">
                        <SVG
                          src={toAbsoluteUrl(
                            "/assets/media/svg/icons/Home/calary.svg"
                          )}
                        />
                        <span className="ms-3">{v?.calories}</span>
                      </div>
                    )}
                  </div>
                  <div class="separator separator-dashed mb-9"></div>
                  {v?.workout_data?.length !== 0 ? (
                    <div className="swiper-main position-relative">
                      <Swiper
                        slidesPerView={3}
                        spaceBetween={10}
                        slidesPerGroup={1}
                        navigation={{
                          nextEl: `.nextBtn${ind}`,
                          prevEl: `.previousBtn${ind}`,
                          disabledClass: "disabledBtn",
                        }}
                        // navigation={true}
                        modules={[Navigation]}
                        loopFillGroupWithBlank={true}
                        className="mySwiper"
                        breakpoints={{
                          // when window width is >= 640px
                          450: { slidesPerView: 1 },
                          600: { slidesPerView: 2 },
                          992: { slidesPerView: 2 },
                          1200: { slidesPerView: 3 },
                        }}
                      >
                        {v?.workout_data?.map((work) => {
                          console.log("work?.difficulty", work?.difficulty[0]);
                          return (
                            <SwiperSlide>
                              <div class="">
                                <div class="card-xl-stretch">
                                  <div className="slider-img h-150px">
                                    <img
                                      src={
                                        work?.thumbnail ||
                                        "https://www.nerdfitness.com/wp-content/uploads/2020/01/gym-dumbbells.jpg"
                                      }
                                      alt=""
                                      className="w-100 h-100"
                                    />
                                  </div>
                                  <div class="mt-3">
                                    <a class="fs-5 text-dark fw-bolder text-hover-info text-dark lh-base">
                                      {work?.title}
                                    </a>
                                    <div class="text-gray-600">
                                      {work?.description}
                                    </div>
                                    <div className="d-flex justify-content-between flex-wrap">
                                      <div className="fw-bold text-grey-600">
                                        {work?.focusArea.join(", ") || "-"}
                                      </div>
                                      <div className="fw-bold">
                                        {work?.difficulty[0] === "0"
                                          ? "Advance"
                                          : work?.difficulty[0] === "1"
                                            ? "Medium"
                                            : work?.difficulty[0] === "2"
                                              ? "Easy"
                                              : "-"}
                                      </div>
                                    </div>
                                    <div class="fw-bold">
                                      {/* <a
                 href="/metronic8/demo1/../demo1/pages/user-profile/overview.html"
                 class="text-gray-700 text-hover-primary"
               >
                 Jane Miller
               </a> */}
                                      <span class="text-muted">
                                        on{" "}
                                        {moment(work?.createdAt).format(
                                          "MMM DD YYYY"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                      <div
                        className={`nextBtn${ind}`}
                        style={{ width: "35px", height: "35px", right: "-2px" }}
                      >
                        <BsChevronRight fontSize="20px" />
                      </div>
                      <div
                        className={`previousBtn${ind}`}
                        style={{ width: "35px", height: "35px", left: "-2px" }}
                      >
                        <BsChevronLeft fontSize="20px" />
                      </div>
                    </div>
                  ) : (
                    <div className="bg-light-warning d-flex justify-content-between align-items-center h-60px rounded">
                      <div className="h4 my-0 ms-4 text-danger">Rest day</div>
                      <div className="me-5">
                        <SVG
                          src={toAbsoluteUrl(
                            "/assets/media/svg/icons/Home/sunRest.svg"
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default PlanPreview;
