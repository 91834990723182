import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Navigation, Pagination } from "swiper";
import { ApiGet } from "../../../helpers/API/ApiData";
import moment from "moment";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

function PlanDetails() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [otherData, setOtherData] = useState("");
  const Id = window.location.pathname.split("/")[2];
  console.log("data", data);
  const fetchData = async () => {
    await ApiGet(`/plan/get/${Id}`)
      .then((res) => {
        console.log("/plan/get", res.data);
        setData(res?.data?.data[0]);
        const names = res?.data?.data[0]?.equipment_Data.map(item => item.title);
        const name = names.join(', ');
        setOtherData({ ...otherData, equipment_Data: name });
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    fetchData();
    let b = [];
  }, []);
  return (
    <div className="plan-details">
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Plan Details</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span
              className="text-hover-info text-muted"
              role="button"
              onClick={() => history.push("/plan")}
            >
              {" "}
              Plan
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Plan Details
            </span>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body p-lg-20">
          <div className="row">
            <div className="col-md-6">
              <div class="h-100 d-flex flex-column justify-content-between pe-lg-6 mb-lg-0 mb-10">
                <div class="h-300px">
                  <img
                    className="embed-responsive-item card-rounded"
                    src={
                      data?.thumbnail ||
                      toAbsoluteUrl("/media/img/loader.gif")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="mb-5 pb-5">
                <a class="fs-2 text-dark fw-bold text-hover-info text-dark lh-base text-capitalize">
                  {data?.title}
                </a>
                <div class="fw-semibold fs-5 text-gray-600 text-dark mt-4 text-justify">
                  {data?.description || "No description"}
                </div>
              </div>
              <div className="">
                <div className="text-hover-danger cursor-default">
                  <img
                    src="/assets/media/logos/power.png"
                    className="me-3"
                    alt=""
                  />
                  Intensity{" "}:
                  <span className="text-uppercase">
                    {" "}
                    {data?.intensity || "-"}
                  </span>
                </div>
                <div className="my-3 text-hover-danger cursor-default">
                  <img
                    src="/assets/media/logos/time.png"
                    className="me-3"
                    alt=""
                  />
                  Duration : {data?.duration || "-"}
                </div>
                <div className="text-hover-danger cursor-default">
                  <img
                    src="/assets/media/logos/award.png"
                    className="me-3"
                    alt=""
                  />
                  Equipments : {otherData?.equipment_Data || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {data?.adminData?.map((v, ind) => {
              return (
                <>
                  <div class="mb-5 mt-10">
                    <div class="d-flex flex-stack">
                      <div className="">
                        {v?.workout_data?.length !== 0 && (
                          <div class="text-danger mb-2">
                            {v?.exercise || ""}
                          </div>
                        )}
                        <h3>
                          {v?.day === 0
                            ? "Monday"
                            : v?.day === 1
                              ? "Tuesday"
                              : v?.day === 2
                                ? "Wednesday"
                                : v?.day === 3
                                  ? "Thursday"
                                  : v?.day === 4
                                    ? "Friday"
                                    : v?.day === 5
                                      ? "Saturday"
                                      : v?.day === 6
                                        ? "Sunday"
                                        : ""}
                        </h3>
                      </div>
                      {v?.calories ? (
                        <div className="">
                          <SVG
                            src={toAbsoluteUrl(
                              "/assets/media/svg/icons/Home/calary.svg"
                            )}
                          />
                          <span className="ms-3">{v?.calories}</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="separator separator-dashed mb-9"></div>
                    {v?.video_data.length !== 0 ? (
                      <div className="swiper-main position-relative">
                        <Swiper
                          slidesPerView={3}
                          spaceBetween={20}
                          slidesPerGroup={1}
                          navigation={{
                            nextEl: `.nextBtn${ind}`,
                            prevEl: `.previousBtn${ind}`,
                            disabledClass: "disabledBtn",
                          }}
                          // navigation={true}
                          modules={[Navigation]}
                          loopFillGroupWithBlank={true}
                          className="mySwiper"
                          breakpoints={{
                            // when window width is >= 640px
                            280: { slidesPerView: 1 },
                            600: { slidesPerView: 2 },
                            768: { slidesPerView: 3 },
                            992: { slidesPerView: 3 },
                            1500: { slidesPerView: 4 },
                          }}
                        >
                          {v?.video_data?.map((work) => {
                            if (work.length === 0) {
                              return (
                                <>
                                  <div>No data found</div>
                                </>
                              );
                            } else {
                              return (
                                <SwiperSlide>
                                  <div class="">
                                    <div class="card-xl-stretch">
                                      <div className="slider-img h-200px">
                                        <img
                                          src={
                                            work?.thumbnail ||
                                            "https://www.nerdfitness.com/wp-content/uploads/2020/01/gym-dumbbells.jpg"
                                          }
                                          alt=""
                                          className="w-100 h-100"
                                        />
                                      </div>
                                      <div class="mt-3">
                                        {/* <a
                                          href="/metronic8/demo1/../demo1/pages/user-profile/overview.html"
                                          class="fs-4 text-dark fw-bold text-hover-info text-dark lh-base"
                                        >
                                          {work?.title}
                                        </a> */}
                                        <div class="fs-4 text-dark fw-bold text-hover-info text-dark lh-base"> {work?.title}</div>
                                        <div class="fw-semibold fs-5 text-gray-600 text-dark my-4">
                                          {work?.description}
                                        </div>
                                        <div className="d-flex justify-content-between flex-wrap">
                                          <div className="fs-6 fw-bold text-grey-600">
                                            {/* {work?.focusArea.join(", ") || "-"} */}
                                          </div>
                                          {work?.difficulty && <div className="fs-5 fw-bold">
                                            {work?.difficulty[0] === "0"
                                              ? "Advance"
                                              : work?.difficulty[0] === "1"
                                                ? "Haladó"
                                                : work?.difficulty[0] === "2"
                                                  ? "Kezdő"
                                                  : "-"}
                                          </div>}
                                        </div>
                                        <div class="fs-6 fw-bold">
                                          {/* <a
                                      href="/metronic8/demo1/../demo1/pages/user-profile/overview.html"
                                      class="text-gray-700 text-hover-primary"
                                    >
                                      Jane Miller
                                    </a> */}
                                          <span class="text-muted">
                                            on{" "}
                                            {moment(work?.createdAt).format(
                                              "MMM DD YYYY"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            }
                          })}
                        </Swiper>
                        <div className={`nextBtn${ind}`}>
                          <BsChevronRight fontSize="20px" />
                        </div>
                        <div className={`previousBtn${ind}`}>
                          <BsChevronLeft fontSize="20px" />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="bg-light-warning d-flex justify-content-between align-items-center h-75px rounded">
                          <div className="h4 my-0 ms-4 text-danger">
                            Rest day
                          </div>
                          <div className="me-5">
                            <SVG
                              src={toAbsoluteUrl(
                                "/assets/media/svg/icons/Home/sunRest.svg"
                              )}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanDetails;
