import React, { useEffect, useState } from 'react'
import { ApiDelete, ApiGet, ApiPost, ApiUpload } from '../../../helpers/API/ApiData';
import { useHistory } from "react-router-dom";
import NoDataTable from '../../../common/noDataTable';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "@material-ui/lab/Pagination";
import moment from 'moment';
import { Form, Modal } from "react-bootstrap";
import { MdCancel } from "react-icons/md";
import Select from "react-select";
import { ErrorToast, SuccessToast } from '../../../helpers/Toast';

export default function RecipePlan() {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [count, setCount] = useState({ totalPage: 0, pageSize: 10, currentPage: 1, search: "" });
    const [searching, setSearching] = useState("");
    const [timer, setTimer] = useState(null);
    const [tabPan, setTabPan] = useState(1);
    const [recipeModal, setRecipeModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [update, setUpdate] = useState(false);
    const dayArray = ["Recipe Plan Details", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const dayRecipeArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const [step1Data, setStep1Data] = useState({ title: "", decription: "", image: [], certificateBackgroundImage: "", notes: "", type: "", id: "" });
    const [chkOutErr, setChkOutErr] = useState({});
    const [loading, setLoading] = useState(false);
    const [recipeData, setRecipeData] = useState([]);
    const [recipe, selectRecipe] = useState({ 0: { _id: "", recipeId: [] }, 1: { _id: "", recipeId: [] }, 2: { _id: "", recipeId: [] }, 3: { _id: "", recipeId: [] }, 4: { _id: "", recipeId: [] }, 5: { _id: "", recipeId: [] }, 6: { _id: "", recipeId: [] } });
    const columns = [
        {
            dataField: "title",
            text: "Title",
            sort: true,
            headerStyle: {
                textAlign: 'start',
            },
            style: {
                textAlign: 'center',
            },
            formatter: (cell, row) => {
                return (
                    <div
                        onClick={() => history.push(`/recipe-plan-details/${row?._id}`)}
                        role="button"
                    >
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-50 symbol-light mr-4">
                                <img
                                    src={row?.image[0]}
                                    className="img-fluid"
                                    style={{ width: "50px" }}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/media/img/fitnessLogo.png";
                                    }}
                                />
                            </div>
                            <div>
                                <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                    {cell ? cell : "-"}
                                </a>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            dataField: "type",
            text: "Type",
            sort: true,
            headerStyle: {
                textAlign: 'center',
            },
            style: {
                textAlign: 'center',
            },
            formatter: (cell, row) => {
                return <div>{cell || "-"}</div>;
            },
        },
        {
            dataField: "notes",
            text: "Notes",
            sort: true,
            headerStyle: {
                textAlign: 'center',
            },
            style: {
                textAlign: 'center',
            },
            formatter: (cell, row) => {
                return <div>{cell || "-"}</div>;
            },
        },
        {
            dataField: "createdAt",
            text: "Created Date",
            sort: true,
            headerStyle: {
                textAlign: 'center',
            },
            style: {
                textAlign: 'center',
            },
            formatter: (cell, row) => {
                return <div className="pointer">{moment(cell).format("DD-MM-YYYY") || "-"}</div>;
            },
        },
        {
            dataField: "action",
            text: "Action",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <>
                        <a
                            title="Edit RecipePlan"
                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                            onClick={() => {
                                editRecipePlan(row._id);
                            }}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG title="Edit RecipePlan" src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
                            </span>
                        </a>

                        <a
                            title="Delete RecipePlan"
                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                            onClick={() => {
                                Swal.fire({
                                    text: `Are you sure you want to Delete Recipe Plan ?`,
                                    icon: "warning",
                                    showCancelButton: true,
                                    showConfirmButton: true,
                                    confirmButtonText: `Yes, Delete`,
                                    confirmButtonColor: "#D72852",
                                    cancelButtonColor: "transparent",
                                    cancelButtonText: "No, Cancel",
                                }).then((res) => {
                                    if (res.isConfirmed) {
                                        onDelete(row?._id);
                                    }
                                });
                            }}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG title="Delete RecipePlan" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                            </span>
                        </a>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        fetchData(count?.currentPage, count?.pageSize, searching);
        fetchRecipe()
    }, []);

    const fetchData = async (page, limit, search) => {
        let body = { page, limit, search };
        await ApiPost("/recipeplan/get", body)
            .then((res) => {
                setData(res?.data?.data?.response);
                setCount({ ...count, totalPage: res?.data?.data?.state?.page_limit, currentPage: page, pageSize: limit, search: search })
            })
            .catch((err) => ErrorToast(err?.message));
    };

    const handleSearch = (e) => {
        let searchValue = e.target.value;
        setSearching(e.target.value);

        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            fetchData(1, count?.pageSize, searchValue);
        }, 300);
        setTimer(newTimer);
    };

    const handlePageSize = (e) => {
        fetchData(1, parseInt(e.target.value), searching);
    };
    const onPaginationChange = (e, i) => {
        fetchData(i, count?.pageSize, searching);
    };

    const onDelete = async (rowId) => {
        await ApiDelete(`/recipeplan/delete/${rowId}`).then((res) => {
            Swal.fire({
                text: "Recipe Plan Deleted Successfully!!!",
                icon: "success",
                confirmButtonText: "Ok, Got it!",
                confirmButtonColor: "#338DE6",
            });
            fetchData(count?.currentPage, count?.pageSize, searching);
        });
    };

    const onChangeStep1 = (e) => {
        let value = e?.target?.value || ''
        setStep1Data({ ...step1Data, [e.target.name]: value });
    }

    const validate = (values, name) => {
        const err = {};

        if (name === "RecipePlanDetails") {
            if (!values?.title?.trim()) {
                err.title = "Plan Name required!";
            }
            if (!values?.decription?.trim()) {
                err.decription = "Description required!";
            }
            if (values.image.length === 0) {
                err.image = "Image required!";
            }
            if (!values.certificateBackgroundImage) {
                err.certificateBackgroundImage = "Certificate Image required!";
            }
            if (!values?.notes?.trim()) {
                err.notes = "Notes required!";
            }
            if (!values?.type?.trim()) {
                err.type = "Type required!";
            }
        }
        return err;
    };

    const submitStep1 = async (flag) => {
        let err = validate(step1Data, "RecipePlanDetails")
        setChkOutErr(err);
        if (!step1Data?.id) {
            delete step1Data["id"]
        }
        if (Object.keys(err).length === 0) {
            setLoading(true)
            await ApiPost(step1Data?.id ? "/recipeplan/update" : "/recipeplan/add", step1Data)
                .then((res) => {
                    SuccessToast(`Recipe Plan ${step1Data?.id ? "Details Update" : "Details Created"} Successfully !!!`);
                    if (flag === "close") {
                        defaultFlag()
                    } else {
                        setTabPan(tabPan + 1);
                        setStep1Data({ ...step1Data, id: res?.data?.data?._id });
                        setIsEdit(true)
                        setLoading(false)
                    }
                    fetchData(1, 10, "");
                    setChkOutErr({});
                })
                .catch((err) => {
                    setLoading(false)
                    ErrorToast(err?.message);
                });
        }
    }

    const imageChange = async (e, name) => {
        let files = e.target.files;
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const isValidImageType = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
            if (isValidImageType) {
                formData.append("image", file)
            } else {
                ErrorToast(`Invalid file type for ${file.name}. Please select a valid image.`);
            }
        }

        setLoading(true);
        try {
            const res = await ApiUpload("upload/compress_image/profile_image", formData);
            if (name === "image") {
                setStep1Data({ ...step1Data, image: [...step1Data.image, ...res?.data?.publicUrl] })
            } else {
                setStep1Data({ ...step1Data, certificateBackgroundImage: res?.data?.publicUrl[0] })
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const fetchRecipe = async () => {
        await ApiPost("/recipe/get", { page: 1, limit: 1000, search: "" })
            .then((res) => {
                setRecipeData(res?.data?.data?.response);
            })
            .catch((err) => ErrorToast(err?.message));
    };

    const handleMultiSelect = (item, i) => {
        const selectedIds = item?.map(item => item["_id"]) || [];
        selectRecipe({ ...recipe, [i]: { ...recipe[i], recipeId: selectedIds } })
    }

    const handleRemoveImage = (remove) => {
        let removeImage = step1Data?.image?.length > 0 && step1Data?.image.filter((img, i) => i !== remove) || []
        if (removeImage.length === 0) document.getElementById("image").value = null;
        setStep1Data({ ...step1Data, image: removeImage });
    }

    const editRecipePlan = async (id) => {
        try {
            const res = await ApiGet(`/recipeplan/get/${id}`);
            const { title, decription, image, certificateBackgroundImage, notes, type, _id, result } = res?.data?.data[0]
            let updatedRecipe = {};
            result.length > 0 && result.forEach((item) => {
                if (item.recipiId.length > 0) {
                    updatedRecipe[item.day] = { _id: item._id, recipeId: item.recipiId }
                }
            });
            selectRecipe((prev) => ({ ...prev, ...updatedRecipe }));
            setStep1Data({ title, decription, image, certificateBackgroundImage, notes, type, id: _id });
            setRecipeModal(true);
            setIsEdit(true)
            setUpdate(true)
        } catch (err) {
            ErrorToast(err?.message);
        }

    }

    const handleNext = async (index, flag) => {
        const currentData = recipe[index];
        try {
            if (currentData.recipeId?.length > 0 && currentData?._id === "") {
                setLoading(true)
                const apiEndpoint = '/admin_recplan/add';
                const requestData = { day: index, recipiId: currentData.recipeId, recipeplanId: step1Data.id };

                const res = await ApiPost(apiEndpoint, requestData);
                const newRecipeData = { ...recipe, [index]: { ...recipe[index], _id: res?.data?.data?._id } };
                setLoading(false)
                if (flag === "close") {
                    defaultFlag();
                } else {
                    setTabPan(tabPan + 1);
                    selectRecipe(newRecipeData);
                }
            } else if (currentData?._id !== "") {
                setLoading(true)
                const apiEndpoint = '/admin_recplan/update';
                const requestData = { day: index, recipiId: currentData.recipeId, recipeplanId: step1Data.id, _id: currentData?._id };

                const res = await ApiPost(apiEndpoint, requestData);
                const updatedRecipeData = { ...recipe, [index]: { ...recipe[index], _id: res?.data?.data?._id } };
                setLoading(false)
                if (flag === "close") {
                    defaultFlag();
                } else {
                    setTabPan(tabPan + 1);
                    selectRecipe(updatedRecipeData);
                }
            } else if (tabPan === 8) {
                defaultFlag();
            } else {
                if (flag === "close") {
                    defaultFlag();
                } else {
                    setTabPan(tabPan + 1);
                }
            }
        } catch (err) {
            const errorMessage = `Error ${currentData?._id ? 'update' : 'creating'} Recipe Plan for Day ${index}: ${err?.message}`;
            ErrorToast(errorMessage);
        }
    };

    const defaultFlag = () => {
        selectRecipe({ 0: { _id: "", recipeId: [] }, 1: { _id: "", recipeId: [] }, 2: { _id: "", recipeId: [] }, 3: { _id: "", recipeId: [] }, 4: { _id: "", recipeId: [] }, 5: { _id: "", recipeId: [] }, 6: { _id: "", recipeId: [] } });
        setChkOutErr({})
        setStep1Data({ title: "", decription: "", image: [], certificateBackgroundImage: "", notes: "", type: "", id: "" })
        setTabPan(1)
        setIsEdit(false)
        setUpdate(false)
        setLoading(false)
        setRecipeModal(false)
    }
    return (
        <>
            <div className="plan-list">
                <div className="d-flex justify-content-between mb-4 ">
                    <div className="title">
                        <div className="fs-20px fw-bolder">Recipe Plan List</div>
                        <div>
                            <span
                                role="button"
                                onClick={() => history.push("/dashboard")}
                                className="text-hover-info text-muted"
                            >
                                Home
                            </span>{" "}
                            -{" "}
                            <span className="text-muted" role="button">
                                {" "}
                                Recipe Plan
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-column flex-column-fluid h-100"
                    id="kt_content"
                >
                    <div className="card card-custom">
                        <div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
                            <div className="card-title ">
                                <div className="input-icon">
                                    <input
                                        type="text"
                                        className="form-control bg-light"
                                        name="searchText"
                                        placeholder="Search by Name"
                                        value={searching}
                                        onChange={(e) => handleSearch(e)}
                                        id="kt_datatable_search_query"
                                    />
                                    <span>
                                        <i className="flaticon2-search-1 text-muted"></i>
                                    </span>
                                </div>
                            </div>

                            <div className="card-toolbar">
                                <a
                                    className="btn btn-color font-weight-bolder btn-sm"
                                    onClick={() => setRecipeModal(true)}
                                >
                                    Add Recipe Plan
                                </a>
                            </div>
                        </div>
                        <div className="card-body mb-5">
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                headerWrapperClasses="border-0"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                keyField="_id"
                                data={data || []}
                                columns={columns}
                                noDataIndication={() => <NoDataTable />}
                            />
                            <div className="d-flex justify-content-between  pt-10">
                                <div className="my-2">
                                    <Pagination
                                        count={count?.totalPage}
                                        page={count?.currentPage}
                                        onChange={onPaginationChange}
                                        variant="outlined"
                                        shape="rounded"
                                        className="pagination_"
                                    />
                                </div>
                                <div className="my-2">
                                    <div className="d-flex align-items-center pagination-drpdown">
                                        <select
                                            className="form-control pagination-drpdown1 dropdownPage"
                                            id="kt_datatable_search_status"
                                            onChange={(e) => handlePageSize(e)}
                                            value={count?.pageSize}
                                        >
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={50}>50</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {
                recipeModal ? <Modal
                    show={recipeModal}
                    onHide={defaultFlag}
                    backdrop="static"
                    className="add-plan-modal"
                    centered
                >
                    < Modal.Header closeButton >
                        <Modal.Title>
                            {update ? "Update Recipe Plan" : "New Recipe Plan"}
                        </Modal.Title>
                    </Modal.Header >
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-4 col-lg-3">
                                <ul className="p-0">
                                    {dayArray.map((day, i) => {
                                        return (
                                            <li
                                                key={day}
                                                className={
                                                    tabPan === i + 1
                                                        ? `bg-light-primary side-arrow rounded ps-4 py-3`
                                                        : `rounded ps-4 py-3 ${isEdit && "cursor-pointer"} `
                                                }
                                                onClick={() => {
                                                    if (isEdit) {
                                                        setTabPan(i + 1);
                                                    }
                                                }}
                                            >
                                                <div className="d-flex">
                                                    <div className="icon align-self-center">
                                                        <div className="">
                                                            <SVG
                                                                src={toAbsoluteUrl(
                                                                    "/assets/media/svg/icons/Cooking/KnifeAndFork2.svg"
                                                                )}
                                                                className="me-4"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="second ms-3">
                                                        <div className="sidebarTitle">{day}</div>
                                                        {i === 0 ? <div className="subTitle">{`Setup Your ${day} `}</div> :
                                                            <div className="subTitle">{`Set Recipe For ${day} `}</div>}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div
                                className="col-md-8 col-lg-9 ps-lg-20"
                                style={{
                                    height: "555px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                }}
                            >
                                <div className="row h-100">
                                    {tabPan === 1 && (
                                        <>
                                            <Form.Group className="col-md-12">
                                                <Form.Label>
                                                    Recipe Plan Name<span className="text-danger"> *</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Recipe Plan Name"
                                                    label="title"
                                                    id="title"
                                                    name="title"
                                                    onChange={onChangeStep1}
                                                    value={step1Data?.title}
                                                    disabled={loading}
                                                />
                                                {chkOutErr.title && (
                                                    <div style={{ color: "red" }}>{chkOutErr.title}</div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="col-md-12">
                                                <Form.Label>Description<span className="text-danger"> *</span></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Enter Description"
                                                    label="decription"
                                                    id="decription"
                                                    name="decription"
                                                    onChange={onChangeStep1}
                                                    value={step1Data?.decription}
                                                    disabled={loading}
                                                />
                                                {chkOutErr.decription && (
                                                    <div style={{ color: "red" }}>{chkOutErr.decription}</div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="col-md-6">
                                                <Form.Label>
                                                    Image<span className="text-danger"> *</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    multiple
                                                    label="image"
                                                    id="image"
                                                    className="form-control"
                                                    name="image"
                                                    accept=".pdf, .png, .jpg, .jpeg"
                                                    onChange={(e) => imageChange(e, e.target.id)}
                                                    disabled={loading}
                                                />
                                                {chkOutErr.image && (
                                                    <div style={{ color: "red" }}>
                                                        {chkOutErr.image}
                                                    </div>
                                                )}
                                                {step1Data?.image?.length > 0 &&
                                                    <div className="position-relative w-auto mt-3">
                                                        {step1Data.image.map((img, i) => {
                                                            return (
                                                                <React.Fragment key={i + 3000}>
                                                                    <img
                                                                        src={img}
                                                                        alt="image"
                                                                        style={{ width: "100px", height: "100px" }}
                                                                        className="rounded-3 me-1 mt-2 recipe_image"
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null; // prevents looping
                                                                            currentTarget.src = "/media/img/fitnessLogo.png";
                                                                        }}
                                                                    />
                                                                    <span
                                                                        className='position-absolute recipe_close opacity-0'
                                                                        style={{ marginLeft: "-25px", marginTop: "10px" }}
                                                                        onClick={() => handleRemoveImage(i)}
                                                                    >
                                                                        <MdCancel color="red" fontSize={20} />
                                                                    </span>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </Form.Group>
                                            <Form.Group className="col-md-6">
                                                <Form.Label>
                                                    Upload Certificate<span className="text-danger"> *</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    label="certificateBackgroundImage"
                                                    id="certificateBackgroundImage"
                                                    className="form-control"
                                                    name="certificateBackgroundImage"
                                                    accept=".pdf, .png, .jpg, .jpeg"
                                                    onChange={(e) => imageChange(e, e.target.id)}
                                                    disabled={loading}
                                                />
                                                {chkOutErr.certificateBackgroundImage && (
                                                    <div style={{ color: "red" }}>
                                                        {chkOutErr.certificateBackgroundImage}
                                                    </div>
                                                )}
                                                {step1Data?.certificateBackgroundImage && <div className="position-relative w-auto mt-3">
                                                    <img
                                                        src={step1Data?.certificateBackgroundImage}
                                                        alt="certificate image"
                                                        style={{ width: "100px", height: "100px" }}
                                                        className="rounded-3 me-1 mt-2 recipe_image"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = "/media/img/fitnessLogo.png";
                                                        }}
                                                    />
                                                    <span
                                                        className='position-absolute recipe_close opacity-0'
                                                        style={{ marginLeft: "-25px", marginTop: "10px" }}
                                                        onClick={() => {
                                                            setStep1Data({ ...step1Data, certificateBackgroundImage: "" })
                                                            document.getElementById("certificateBackgroundImage").value = null;
                                                        }}
                                                    >
                                                        <MdCancel color="red" fontSize={20} />
                                                    </span>
                                                </div>}
                                            </Form.Group>
                                            <Form.Group className="col-md-12">
                                                <Form.Label>
                                                    Type<span className="text-danger"> *</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Type"
                                                    label="type"
                                                    id="type"
                                                    name="type"
                                                    onChange={onChangeStep1}
                                                    value={step1Data?.type}
                                                    disabled={loading}
                                                />
                                                {chkOutErr.type && (
                                                    <div style={{ color: "red" }}>{chkOutErr.type}</div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="col-md-12">
                                                <Form.Label>
                                                    Notes<span className="text-danger"> *</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Notes"
                                                    label="notes"
                                                    id="notes"
                                                    name="notes"
                                                    onChange={onChangeStep1}
                                                    value={step1Data?.notes}
                                                    disabled={loading}
                                                />
                                                {chkOutErr.notes && (
                                                    <div style={{ color: "red" }}>{chkOutErr.notes}</div>
                                                )}
                                            </Form.Group>
                                            <div className="text-end">
                                                {isEdit && (
                                                    <button className="btn btn-secondary me-4" onClick={() => submitStep1("close")} disabled={loading}> Save & Close </button>
                                                )}
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={submitStep1}
                                                    disabled={loading}
                                                >
                                                    {loading ? "Loading..." : "Next"}
                                                </button>
                                            </div>
                                        </>
                                    )}

                                    {dayRecipeArray.map((day, i) => {
                                        return (
                                            <>
                                                {tabPan === i + 2 &&
                                                    <div className='d-flex flex-column justify-content-between' key={`${day}${i} `}>
                                                        <div>
                                                            <h3 className="ms-4 mb-5">{`${day} Recipe`}</h3>
                                                            <Form.Group className="col-md-12">
                                                                <Form.Label>
                                                                    Recipe<span className="text-danger"> *</span>
                                                                </Form.Label>
                                                                <Select
                                                                    isMulti
                                                                    name="recipe"
                                                                    options={recipeData}
                                                                    placeholder="Select Recipe"
                                                                    getOptionLabel={(recipeData) => recipeData["title"]}
                                                                    getOptionValue={(recipeData) => recipeData["_id"]}
                                                                    onChange={(item) => handleMultiSelect(item, i)}
                                                                    defaultValue={recipeData.filter(item => recipe[i]?.recipeId?.includes(item["_id"]))}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="d-flex justify-content-between px-4">
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => {
                                                                    if (isEdit) {
                                                                        setTabPan(tabPan - 1);
                                                                    }
                                                                }}
                                                                disabled={!step1Data?.id || loading}
                                                            >
                                                                Previous
                                                            </button>
                                                            <div>
                                                                {isEdit && (
                                                                    <button className={`btn btn-secondary ${tabPan < 8 && "me-4"} `} onClick={() => handleNext(i, "close")} disabled={loading}> Save & Close </button>
                                                                )}
                                                                {tabPan < 8 && <button className="btn btn-primary" onClick={() => handleNext(i)} disabled={loading}>
                                                                    {loading ? "Loading..." : "Next"}
                                                                </button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal > : null
            }
        </>
    )
}
