import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiPut1,
  ApiUpload,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Dropdown, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { IoCopyOutline } from "react-icons/io5";
import ProgressBar from "react-bootstrap/ProgressBar";
import Auth from "../../../helpers/Auth";
import { Line, Circle } from "rc-progress";
import AWS from "aws-sdk";
import S3 from "react-aws-s3";
import { Uploader } from "../../../helpers/utils";
import VideoListModal from "./VideoListModal";
function VideoList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [playlistData, setPlaylistData] = useState([]);
  const [workoutTypeData, setWorkoutTypeData] = useState([]);
  const [focusAreaData, setFocusAreaData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([])
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesize, setPageSize] = useState(10);
  const [searching, setSearching] = useState("");
  const [playState, setPlayState] = useState("Select By Playlist");
  const [byPlaylist, setByPlaylist] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploader, setUploader] = useState(undefined);
  const [file, setFile] = useState(undefined);
  // const s3 = new AWS.S3({
  //   accessKeyId: "AKIASCEGOUD4DJKWWP5P",
  //   secretAccessKey: "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",
  //   region: "us-east-1",
  //   signatureVersion: "v4",
  //   ServerSideEncryption: "aws:kms",
  //   SSEKMSKeyId: "37b64f83-8e3d-46d3-9878-f48b2360bff5",
  // });

  const adminid = localStorage.getItem("userInfo");
  const adminouth = JSON.stringify(adminid);
  // console.log("adminouth", adminouth?.status);

  const s3 = new AWS.S3({
    accessKeyId: "AKIAWPY6H6SXCTQDHMBS",
    secretAccessKey: "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",
    region: "eu-central-1",
    signatureVersion: "v4",
    ServerSideEncryption: "aws:kms",
    SSEKMSKeyId: "67a4dfdf-7d5c-4ee5-949c-d729b01779d9",
  });
  const [newData, setNewData] = useState({
    title: "",
    description: "",
    tag: "",
    url: "",
    playlist: "0",
    thumbnailUrl: "",
    difficulty: "",
    calories: "",
    duration: "",
  });

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <div className="symbol symbol-50px symbol-light mr-4">
              <img src={row?.thumbnail} className="img-fluid" onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/media/img/fitnessLogo.png";
              }} />
            </div>
            <div>
              <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {cell ? cell : "-"}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "video_playlist",
      text: "Playlist Name",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell.length ? cell[0].title : "-"}</div>;
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell && moment(cell).format("DD-MM-YYYY HH:mm A")}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      style: { width: "150px" },
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Copy workout video link"
              className="btn btn-icon btn-light btn-hover-secondary btn-sm me-3"
              onClick={() => {
                navigator.clipboard
                  .writeText(row?.url || "")
                  .then(() => {
                    SuccessToast("video URL copied");
                  })
                  .catch((error) => {
                    ErrorToast("Video Not Found");
                  });
              }}
            >
              <IoCopyOutline fontSize={20} />
            </a>
            <a
              title="Edit workout"
              className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
              onClick={() => editBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>
            <a
              title="Delete workout"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to Delete ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Delete`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onDelete(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </a>
          </>
        );
      },
    },
  ];
  console.log("newData", newData);
  const handleChange = (e) => {
    const { value, name } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const editBtnClick = async (row) => {
    console.log("row", row);
    let typeArr = [];
    let focusAreaArr = [];
    let equipmentArr = [];
    setIsEdit(true);
    setModal(true);
    setUploadSuccess(false);
    setProgress(0);
    setUploadError(false);
    let tag = row?.tag?.length > 1 ? row.tag.join(", ") : row?.tag;
    let a = await row?.workoutType_Data?.map((v) => {
      typeArr.push({ value: v?._id, label: v?.title });
    });
    let b = await row?.focusArea_Data?.map((v) => {
      focusAreaArr.push({ value: v?._id, label: v?.title });
    });
    let c = await row?.equipment_Data?.map((v) => {
      equipmentArr.push({ value: v?._id, label: v?.title });
    });
    setNewData({
      id: row?._id,
      title: row?.title,
      description: row?.description,
      tag: tag,
      url: row?.url,
      thumbnailUrl: row?.thumbnail,
      difficulty: row?.difficulty ? row?.difficulty[0] : "",
      workoutType: typeArr,
      // focusArea: focusAreaArr,
      equipment: equipmentArr,
      playlist: row?.videoPlayListId,
      calories: row?.calories,
      duration: row?.duration,
    });
  };
  const onDelete = async (Id) => {
    await ApiDelete(`/video/${Id}`)
      .then(() => {
        fetchData(currentPage, pagesize, searching, byPlaylist, sortBy);
        Swal.fire({
          text: "You have Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onUpdate = async () => {
    let typeArr = [];
    let focusAreaArr = [];
    let equipmentArr = [];
    if (newData?.workoutType) {
      let a = newData?.workoutType?.filter((v) => {
        typeArr.push(v?.value);
        return;
      });
    }
    if (newData?.focusArea) {
      let a = newData?.focusArea?.filter((v) => {
        focusAreaArr.push(v?.value);
        return;
      });
    }
    if (newData?.equipment) {
      let a = newData?.equipment?.filter((v) => {
        equipmentArr.push(v?.value);
        return;
      });
    }
    let tagArr = newData?.tag?.includes(",")
      ? newData?.tag?.split(", ")
      : newData?.tag;
    let body = {
      id: newData?.id,
      title: newData.title,
      videoPlayListId: newData?.playlist,
      description: newData.description,
      url: newData.url,
      tag: tagArr,
      thumbnail: newData.thumbnailUrl,
      workoutType: typeArr.length === 0 ? null : typeArr,
      // focusArea: focusAreaArr.length === 0 ? null : focusAreaArr,
      equipment: equipmentArr.length === 0 ? null : equipmentArr,
      difficulty: newData?.difficulty,
      calories: newData?.calories,
      duration: newData?.duration,
    };

    if (validateData()) {
      // if (equipmentArr.length === 0) {
      //   ErrorToast("Equipment is Required!");
      //   return false;
      // }
      if (typeArr.length === 0) {
        ErrorToast("WorkoutType is Required!");
        return false;
      }

      setLoading(true);
      await ApiPut("/video", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModal(false);
          fetchData(currentPage, pagesize, searching, byPlaylist, sortBy);
        })
        .catch((err) => {
          ErrorToast(err?.message);
        });
      setLoading(false);
    }
  };

  const validateData = () => {
    if (!newData.title) {
      ErrorToast("Title is Required!");
      return false;
    }
    if (!newData.difficulty) {
      ErrorToast("Difficulty is Required!");
      return false;
    }
    if (!newData.duration) {
      ErrorToast("Duration is Required!");
      return false;
    }
    if (!newData.url) {
      ErrorToast("Video url is Required!");
      return false;
    }
    if (!newData.thumbnailUrl) {
      ErrorToast("Thumbnail url is Required!");
      return false;
    }
    // if (!newData.calories) {
    //   ErrorToast("Calories is Required!");
    //   return false;
    // }
    if (!newData.description) {
      ErrorToast("Description is Required!");
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    let tagArr = newData?.tag.split(", ");
    let typeArr = [];
    if (newData?.workoutType) {
      let a = newData?.workoutType?.filter((v) => {
        typeArr.push(v?.value);
        return;
      });
    }
    // let focusAreaArr = [];
    // if (newData?.focusArea) {
    //   let a = newData?.focusArea?.filter((v) => {
    //     focusAreaArr.push(v?.value);
    //     return;
    //   });
    // }
    let equipmentArr = [];
    if (newData?.equipment) {
      let b = newData?.equipment?.filter((v) => {
        equipmentArr.push(v?.value);
        return;
      });
    }
    let body = {
      title: newData.title,
      videoPlayListId: newData?.playlist || "",
      description: newData.description,
      url: newData.url,
      tag: tagArr,
      thumbnail: newData.thumbnailUrl,
      difficulty: newData?.difficulty,
      calories: newData?.calories,
      duration: newData?.duration,
      workoutType: typeArr.length === 0 ? null : typeArr,
      // focusArea: focusAreaArr.length === 0 ? null : focusAreaArr,
      equipment: equipmentArr.length === 0 ? null : equipmentArr
    };
    console.log("body", body);
    if (validateData()) {

      // if (equipmentArr.length === 0) {
      //   ErrorToast("Equipment is Required!");
      //   return false;
      // }
      if (typeArr.length === 0) {
        ErrorToast("WorkoutType is Required!");
        return false;
      }

      setLoading(true);
      await ApiPost("/video", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModal(false);
          fetchData(currentPage, pagesize, searching, byPlaylist, sortBy);
        })
        .catch((err) => {
          ErrorToast(err?.message);
        });
      setLoading(false);
    }
  };
  const getPlaylistData = async () => {
    await ApiGet("/video_playlist")
      .then((res) => setPlaylistData(res.data.data))
      .catch((err) => ErrorToast(err?.message));
  };
  const getWorkoutType = async () => {
    await ApiGet("/workoutType")
      .then((res) =>
        setWorkoutTypeData(
          res?.data?.data?.map((v) => {
            return { value: v?._id, label: v?.title };
          })
        )
      )
      .catch((err) => ErrorToast(err?.message));
  };
  const getFocusArea = async () => {
    await ApiGet("/focusArea")
      .then((res) =>
        setFocusAreaData(
          res?.data?.data?.map((v) => {
            return { value: v?._id, label: v?.title };
          })
        )
      )
      .catch((err) => ErrorToast(err?.message));
  };
  const getEquipment = async () => {
    await ApiGet("/equipment")
      .then((res) =>
        setEquipmentData(
          res?.data?.data?.map((v) => {
            return { value: v?._id, label: v?.title };
          })
        )
      )
      .catch((err) => ErrorToast(err?.message));
  };
  const handleOnChangesPagination = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
    fetchData(1, parseInt(e.target.value), searching, byPlaylist, sortBy);
  };
  const onPaginationChange = (e, i) => {
    setCurrentPage(i);
    fetchData(i, pagesize, searching, byPlaylist, sortBy);
  };
  // const imageChange = async (e) => {
  //   setLoading(true);
  //   let file = e.target.files[0];
  //   let fileURL = URL.createObjectURL(file);
  //   file.fileURL = fileURL;
  //   let formData = new FormData();
  //   formData.append("image", file);
  //   if (e.target.name === "url") {
  //     await ApiUpload("upload/image/profile_image", formData)
  //       .then((res) => {
  //         setNewData({ ...newData, url: res?.data?.data?.image });
  //         setLoading(false);
  //       })
  //       .catch((err) => setLoading(false));
  //   } else {
  //     await ApiUpload("upload/compress_image/profile_image", formData)
  //       .then((res) => {
  //         setNewData({ ...newData, thumbnailUrl: res?.data?.data?.image });
  //         setLoading(false);
  //       })
  //       .catch((err) => setLoading(false));
  //   }
  // };

  //running code
  // const videoupload = async (id) => {
  //   console.log(id.target.files[0]);
  //   let finalvideoarray = [];
  //   let videoarray = [];
  //   setLoading(true);
  //   setProgress(0);
  //   setUploadSuccess(false);
  //   videoarray.push(id.target.files[0]);

  //   for (let i = 0; i < videoarray?.length; i++) {
  //     console.log("a");
  //     let temp = await videoarray[i]?.type?.split("/")[
  //       videoarray[i]?.type?.split("/")?.length - 1
  //     ];
  //     console.log("b", temp);
  //     var params = {
  //       Bucket: "fitness-development1",
  //       Key: `444/video/${Date.now().toString()}.${
  //         temp == "quicktime" ? "mp4" : temp
  //       }`,
  //     };

  //     const xhr = new XMLHttpRequest();
  //     console.log("c");
  //     const uploadURL = await s3.getSignedUrlPromise("putObject", params);
  //     console.log("d", uploadURL);

  //     xhr.open("PUT", uploadURL, true);

  //     // Add progress event listener
  //     xhr.upload.onprogress = (event) => {
  //       if (event.lengthComputable) {
  //         const progress = (event.loaded / event.total) * 100;
  //         setProgress(progress);
  //         console.log("Progress: ", progress);
  //         // Update progress UI or do whatever you need with the progress value
  //       }
  //     };

  //     // Upload the video using the xhr object
  //     xhr.send(videoarray[i]);

  //     // Wait for the video to finish uploading
  //     await new Promise((resolve) => {
  //       xhr.onload = resolve;
  //     });

  //     console.log("e");

  //     let obj = {
  //       url:
  //         "https://fitness-development1.s3-accelerate.amazonaws.com/" +
  //         params.Key,
  //       size: (videoarray[i]?.size * 9.31) / 10000000000,
  //     };
  //     finalvideoarray.push(obj);

  //     console.log(finalvideoarray);
  //     console.log(finalvideoarray[0]?.url);
  //     setNewData({ ...newData, url: finalvideoarray[0]?.url });
  //     setLoading(false);
  //     setUploadSuccess(true);
  //   }
  //   console.log("videoupload function end");
  //   return finalvideoarray;
  // };

  // const videouploadnew = async (e) => {

  //   let file = e.target?.files?.[0];
  //   console.log("a", file);
  //   if (file) {
  //     const videoUploaderOptions = {
  //       fileName: Date.now().toString(),
  //       file: file,
  //     };
  //     console.log("b");
  //     let percentage = undefined;
  //     const uploader = new Uploader(videoUploaderOptions);
  //     setUploader(uploader);
  //     console.log("c");
  //     uploader.onProgress(({ percentage: newPercentage }) => {
  //       console.log("d");
  //       // to avoid the same percentage to be logged twice
  //       if (newPercentage !== percentage) {
  //         percentage = newPercentage;
  //         console.log(`${percentage}%`);
  //       }
  //     });
  //     console.log("e").onError((error) => {
  //       // setFile(undefined);
  //       console.error(error);
  //     });
  //     uploader.start();
  //   }
  //   console.log("e");
  // };
  useEffect(() => {
    if (file) {
      console.log("a", file);
      setLoading(true);

      const videoUploaderOptions = {
        fileName: Date.now().toString(),
        file: file,
      };
      let percentage = undefined;
      const uploader = new Uploader(videoUploaderOptions);
      setUploader(uploader);
      console.log("b", uploader);
      uploader

        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            console.log(`${percentage}%`);
            setProgress(percentage);
            if (percentage == 100) {
              setWaiting(true);
              setLoading(false);
            }
          }
        })
        .onError((error) => {
          setFile(undefined);
          setLoading(false);
          setUploadSuccess(false);
          setUploadError(true);
          console.error(error);
        })
        .onSuccess(() => {
          console.log("urldata");
          setWaiting(false);
          setNewData({
            ...newData,
            url: `https://fitness-development1.s3-accelerate.amazonaws.com/${uploader?.fileKey}`,
          });
          // ApiPut1("upload/video/metadata",{fileLocation:uploader?.fileKey})
          setUploadSuccess(true);
        });
      uploader.start();
    }
  }, [file]);
  // const videoupload = async (id) => {
  //   try {
  //     console.log(id.target.files[0]);
  //     let finalvideoarray = [];
  //     let videoarray = [];
  //     setLoading(true);
  //     setProgress(0);
  //     setUploadSuccess(false);
  //     videoarray.push(id.target.files[0]);

  //     for (let i = 0; i < videoarray?.length; i++) {
  //       console.log("a");
  //       let temp = await videoarray[i]?.type?.split("/")[
  //         videoarray[i]?.type?.split("/")?.length - 1
  //       ];
  //       console.log("b", temp);
  //       var params = {
  //         Bucket: "fitness-development1",
  //         Key: `444/video/${Date.now().toString()}.${
  //           temp == "quicktime" ? "mp4" : temp
  //         }`,
  //       };

  //       const xhr = new XMLHttpRequest();
  //       console.log("c");
  //       const uploadURL = await s3.getSignedUrlPromise("putObject", params);
  //       console.log("d", uploadURL);

  //       xhr.open("PUT", uploadURL, true);

  //       // Add progress event listener
  //       xhr.upload.onprogress = (event) => {
  //         if (event.lengthComputable) {
  //           const progress = (event.loaded / event.total) * 100;
  //           setProgress(progress);
  //           console.log("Progress: ", progress);
  //           // Update progress UI or do whatever you need with the progress value
  //         }
  //       };

  //       // Add error event listener
  //       xhr.onerror = (error) => {
  //         console.error("Upload Error:", error);
  //         // Handle the upload error here
  //       };

  //       // Upload the video using the xhr object
  //       xhr.send(videoarray[i]);

  //       // Wait for the video to finish uploading
  //       await new Promise((resolve) => {
  //         xhr.onload = resolve;
  //       });

  //       console.log("e");

  //       let obj = {
  //         url:
  //           "https://fitness-development1.s3-accelerate.amazonaws.com/" +
  //           params.Key,
  //         size: (videoarray[i]?.size * 9.31) / 10000000000,
  //       };
  //       finalvideoarray.push(obj);

  //       console.log(finalvideoarray);

  //       setLoading(false);
  //       setUploadSuccess(true);
  //     }
  //     console.log("videoupload function end");
  //     return finalvideoarray;
  //   } catch (error) {
  //     console.error("Error:", error);
  //     // Handle the error here, you may want to show an error message to the user
  //     setLoading(false); // Reset loading state to false
  //     setUploadSuccess(false); // Reset upload success state to false
  //     setUploadError(true);
  //     // Additional error handling if needed
  //     throw error; // Rethrow the error if you want to handle it in the calling code as well
  //   }
  // };

  //   console.log(id.target.files[0]);
  //   let finalvideoarray = [];
  //   let videoarray = [];
  //   setLoading(true);
  //   setProgress(0);
  //   setUploadSuccess(false);
  //   setUploadError(false);

  //   videoarray.push(id.target.files[0]);

  //   try {
  //     for (let i = 0; i < videoarray?.length; i++) {
  //       console.log("a");
  //       let temp = await videoarray[i]?.type?.split("/")[
  //         videoarray[i]?.type?.split("/")?.length - 1
  //       ];
  //       console.log("b", temp);
  //       var params = {
  //         Bucket: "fitness-development1",
  //         Key: `444/video/${Date.now().toString()}.${
  //           temp === "quicktime" ? "mp4" : temp
  //         }`,
  //       };

  //       const xhr = new XMLHttpRequest();
  //       console.log("c");
  //       const uploadURL = await s3.getSignedUrlPromise("putObject", params);
  //       console.log("d", uploadURL);

  //       xhr.open("PUT", uploadURL, true);

  //       // Add progress event listener
  //       xhr.upload.onprogress = (event) => {
  //         if (event.lengthComputable) {
  //           const progress = (event.loaded / event.total) * 100;
  //           setProgress(progress);
  //           console.log("Progress: ", progress);
  //           // Update progress UI or do whatever you need with the progress value
  //         }
  //       };

  //       // Upload the video using the xhr object
  //       xhr.send(videoarray[i]);

  //       // Wait for the video to finish uploading
  //       await new Promise((resolve) => {
  //         xhr.onload = resolve;
  //       });

  //       console.log("e");

  //       let obj = {
  //         url:
  //           "https://fitness-development1.s3-accelerate.amazonaws.com/" +
  //           params.Key,
  //         size: (videoarray[i]?.size * 9.31) / 10000000000,
  //       };
  //       finalvideoarray.push(obj);

  //       console.log(finalvideoarray);
  //     }

  //     setLoading(false);
  //     setUploadSuccess(true);
  //     console.log("videoupload function end");
  //     return finalvideoarray;
  //   } catch (error) {
  //     setLoading(false);
  //     if (error.response && error.response.status === 400) {
  //       // If the status code is 400 (client-side error)
  //       console.error("Client-side error:", error.response.data);
  //       // Handle the client-side error as needed (e.g., show an error message to the user)
  //       // ...
  //     } else {
  //       // If the error is not related to the status code (e.g., network error, server-side error)
  //       console.error("Error uploading video:", error);
  //       // Handle other types of errors as needed
  //       // ...
  //     }

  //     setUploadError(true); // Set uploadError state to true regardless of the error type
  //   }
  // };

  // const videoupload = async (id) => {
  //   console.log(id.target.files[0]);
  //   let finalvideoarray = [];
  //   let videoarray = [];

  //   videoarray.push(id.target.files[0]);
  //   // console.log("videolegth", videolegth);
  //   // console.log("videoupload function called");

  //   for (let i = 0; i < videoarray?.length; i++) {
  //     console.log("a");
  //     let temp = await videoarray[i]?.type?.split("/")[
  //       videoarray[i]?.type?.split("/")?.length - 1
  //     ];
  //     console.log("b", temp);
  //     var params = {
  //       Bucket: "fitness-development1",
  //       Key: `444/video/${Date.now().toString()}.${
  //         temp == "quicktime" ? "mp4" : temp
  //       }`,
  //     };
  //     const xhr = new XMLHttpRequest();
  //     console.log("c");
  //     const uploadURL = await s3.getSignedUrlPromise("putObject", params);
  //     console.log("d", uploadURL);

  //     await fetch(uploadURL, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //       body: videoarray[i],
  //     });
  //     console.log("e");
  //     // await Apiupload(params)
  //     // .then((res) => {
  //     //   console.log(res)
  //     let obj = {
  //       url:
  //         "https://fitness-development1.s3-accelerate.amazonaws.com/" +
  //         params.Key,
  //       size: (videoarray[i]?.size * 9.31) / 10000000000,
  //     };
  //     finalvideoarray.push(obj);

  //     console.log(finalvideoarray);
  //     console.log(finalvideoarray[0]?.url);
  //   }
  //   console.log("videoupload function end");
  //   return finalvideoarray;
  // };

  const imageChange = async (e) => {
    console.log("e", e?.target?.name);
    let data = e?.target?.name;
    if (!e || !e.target) {
      console.error("Invalid event object or target is missing.");
      return;
    }
    setLoading(true);
    setProgress(0);
    setUploadSuccess(false);
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);

    const onProgress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        if (data === "url") {
          console.log("111111111111111111111111111");
          setProgress(progress);
        }
      }
    };

    try {
      let apiUrl;

      if (data === "url") {
        apiUrl =
          // "https://development.api.harcoskepzoapp.hu/upload/image/profile_image";
          "http://192.168.29.20:8080/upload/image/profile_image";
      } else {
        apiUrl =
          "https://development.api.harcoskepzoapp.hu/upload/compress_image/profile_image";
        // "http://192.168.29.20:8080/upload/compress_image/profile_image";
      }
      const headers = {
        Authorization: Auth.getToken(),
      };
      console.log("headers", headers);
      const response = await uploadFileWithProgress(
        apiUrl,
        formData,
        onProgress,
        headers
      );

      console.log("urlresponse", response);
      // if (e.target.name === "url") {
      if (data === "url") {
        // setNewData({ ...newData, url: response?.data?.data?.image });
        setNewData({ ...newData, url: response?.data?.image });
        setUploadSuccess(true);
      } else {
        setNewData({ ...newData, thumbnailUrl: response?.data?.image });
        if (uploadSuccess == true) {
          setUploadSuccess(true);
        } else {
          setUploadSuccess(false);
        }
      }
      // setUploadSuccess(true);
      setLoading(false);
      console.log("file uploaded");
    } catch (error) {
      setLoading(false);
      setUploadError(true);

      // ErrorToast(error);
      // setUploadSuccess(true);

      console.error("Error uploading file:", error);
      // ErrorToast(error);
    }
  };

  // Function to handle the upload with progress tracking using XMLHttpRequest.
  function uploadFileWithProgress(apiUrl, formData, onProgress, headers) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", apiUrl, true);

      for (const key in headers) {
        xhr.setRequestHeader(key, headers[key]);
      }

      xhr.upload.onprogress = onProgress;
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          const responseData = JSON.parse(xhr.responseText);
          resolve(responseData);
        } else {
          reject(new Error("Request failed with status: " + xhr.status));
        }
      };

      xhr.onerror = function () {
        reject(new Error("Request failed"));
      };

      xhr.send(formData);
    });
  }
  const handleSearch = (e) => {
    setSearching(e.target.value);
    fetchData(currentPage, pagesize, e.target.value, byPlaylist, sortBy);
  };
  const handleOnChangeStatus = (e) => {
    let item = JSON.parse(e);
    setPlayState(item?.title);
    setByPlaylist(item?._id);
    fetchData(currentPage, pagesize, searching, item?._id, sortBy);
  };
  const handleOnChangeStatus2 = (e) => {
    setSortBy(e);
    fetchData(currentPage, pagesize, searching, byPlaylist, e);
  };
  const fetchData = async (page, limit, search, by_playlist, sort_by) => {
    let body = { page, limit, search, by_playlist, sort_by };
    await ApiPost("/video/get", body)
      .then((res) => {
        console.log("admin/video/get", res?.data);
        setData(res?.data?.data?.video_data);
        setTotalPage(res?.data?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  console.log('sortBy', sortBy);
  useEffect(() => {
    fetchData(currentPage, pagesize, searching, byPlaylist, sortBy);
    getPlaylistData();
    getWorkoutType();
    // getFocusArea();
    getEquipment();
  }, []);

  const modalHide = () => {
    setNewData({ title: "", description: "", tag: "", url: "", playlist: "0", thumbnailUrl: "", difficulty: "", calories: "", duration: "" })
    setModal(false);
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Video List</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Video List
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title" style={{ width: "90%" }}>
              <div class="row align-items-center w-100">
                <div class="col-md-3 my-2">
                  <div class="input-icon">
                    <input
                      type="text"
                      class="form-control bg-light"
                      name="searchText"
                      placeholder="Search by Name"
                      value={searching}
                      onChange={handleSearch}
                    />
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
                <div className="col-md-3 my-2">
                  <Dropdown onSelect={handleOnChangeStatus}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="text-capitalize w-300px"
                      style={{ textWrap: "pretty" }}
                    >
                      {playState}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ minWidth: "100%" }}>
                      {playlistData.map((item, i) => {
                        return (
                          <Dropdown.Item eventKey={JSON.stringify(item)}>
                            {item.title}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-md-3 my-2">
                  <Dropdown onSelect={handleOnChangeStatus2}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="text-capitalize"
                    >
                      Sort By {sortBy === "A-Z" ?
                        "Name" :
                        sortBy === "1" ?
                          "Date" :
                          ""}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ minWidth: "100%" }}>
                      <Dropdown.Item eventKey="A-Z">By Name</Dropdown.Item>
                      <Dropdown.Item eventKey="1">By Date</Dropdown.Item>
                      {/* {playlistData.map((item, i) => {
                          return (
                            <Dropdown.Item eventKey={item.title}>
                              {item.title}
                            </Dropdown.Item>
                          );
                        })} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div class="card-toolbar">
              <a
                class="btn btn-color font-weight-bolder btn-sm"
                onClick={() => {
                  setIsEdit(false);
                  setModal(true);
                  setUploadSuccess(false);
                  setProgress(0);
                  setUploadError(false);

                  setNewData({
                    title: "",
                    description: "",
                    tag: "",
                    url: "",
                    playlist: "0",
                    thumbnailUrl: "",
                  });
                }}
              >
                Add New Workout
              </a>
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              noDataIndication={() => <NoDataTable />}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalPage}
                  page={currentPage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    onChange={(e) => handleOnChangesPagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && <Modal backdrop="static" show={modal} size="lg" onHide={modalHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Update" : "Add New"} Workout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VideoListModal handleChange={handleChange} newData={newData} setNewData={setNewData} workoutTypeData={workoutTypeData} equipmentData={equipmentData} playlistData={playlistData} />
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button
              className="btn btn-color"
              onClick={onUpdate}
              disabled={loading}
            >
              {loading ? "Loading" : "Update Changes"}
            </button>
          ) : (
            <button
              className="btn btn-color"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Loading" : "Submit"}
            </button>
          )}
        </Modal.Footer>
      </Modal>}
    </>
  );
}

export default VideoList;
