import React, { useState } from "react";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NoDataTable from "../../../common/noDataTable";
import { ApiPost } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import Pagination from "@material-ui/lab/Pagination";
import { Modal, Form } from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";

export default function Notification() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [totalpage, settotalpage] = useState(0);
	const [currentpage, setcurrentpage] = useState(1);
	const [pagesize, setpagesize] = useState(10);
	const [searching, setsearching] = useState("");
	const [fLoading, setFLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [newData, setNewData] = useState({ title: "", description: "", types: 0 });
	const [selectedRow, setSelectedRow] = useState([])
	const columns = [
	// {
	// 	dataField: "_id",
	// 	text: "",
	// 	sort: true,
	// 	formatter: (cell, row) => {
	// 		return (<div className="d-flex align-items-center">
	// 			<input
	// 				type="checkbox"
	// 				className="form-check-input cursor-pointer"
	// 				onChange={({ target }) => {
	// 					console.log('target.checked', target.checked);
	// 					setSelectedRow(prevSelectedRow => {
	// 						if (target.checked) {
	// 							return [...prevSelectedRow, cell];
	// 						} else {
	// 							const removeIndex = prevSelectedRow.findIndex(row => row === cell);
	// 							const newSelected = [...prevSelectedRow];
	// 							newSelected.splice(removeIndex, 1);
	// 							return newSelected;
	// 						}
	// 					});
	// 				}}
	// 			/>
	// 		</div>);

		// 	},
		// },
		{
			dataField: "title",
			text: "title",
			sort: true,
			formatter: (cell, row) => {
				return (<>
					<div className="d-flex align-items-center">
						<div className="symbol symbol-50 symbol-light mr-4">
							<img
								src="/media/img/fitnessLogo.png"
								className="img-fluid object-cover"
							/>
						</div>
						<div>
							<a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
								{cell || "-"}
							</a>
						</div>
					</div>
				</>);

			},
		},
		{
			dataField: "description",
			text: "message",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell || "-"}</div>;
			},
		},
		{
			dataField: "types",
			text: "type",
			sort: true,
			formatter: (cell, row) => {
				return <div>{
					cell === 1 ? "Video Playlist" :
						cell === 2 ? "Plan" :
							cell === 3 ? "Workout" :
								cell === 4 ? "Recipe" :
									"-"}</div>;
			},
		},
		{
			dataField: "totalClicks",
			text: "total Clicks",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell || 0}</div>;
			},
		},
		{
			dataField: "createdAt",
			text: "createdAt",
			sort: true,
			formatter: (cell, row) => {
				return <div>{moment(cell).format("DD-MM-YYYY hh:mm A")}</div>;
			},
		},

	]
	const handleChange = (e) => {
		const { name, value } = e.target;
		setNewData({ ...newData, [name]: value })
	}
	const handleSubmit = async () => {
		if (!newData?.title) {
			ErrorToast("Title is required!")
			return;
		}
		if (!newData?.description) {
			ErrorToast("Description is required!");
			return;
		}
		setFLoading(true);
		let body = {
			title: newData?.title,
			description: newData?.description,
			types: Number(newData?.types) || 0,
			isAllUser: true
		}
		await ApiPost("/notification/sent", body)
			.then(res => { SuccessToast("Notification sent successfully"); setFLoading(false); setModal(false); fetchData(currentpage, pagesize, searching); })
			.catch(err => { ErrorToast(err?.message); setFLoading(false); })
	}

	const handleDeleteSelected = () => {
		Swal.fire({
			title: `Are you sure you want to Delete selected Notification ?`,
			icon: "warning",
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: `Yes, Delete`,
			confirmButtonColor: "#D72852",
			cancelButtonColor: "transparent",
			cancelButtonText: "No, Cancel",
			showLoaderOnConfirm: true,
			preConfirm: async () => {
				try {
					await ApiPost("/notification", { ids: selectedRow })
					fetchData(currentpage, pagesize, searching);
					setSelectedRow([]);
				} catch (error) {
					ErrorToast(error?.message);
					return false;
				}
			}
		})
	}

	const handleonchnagespagination = (e) => {
		setpagesize(e.target.value);
		fetchData(currentpage, parseInt(e.target.value), searching);
	};
	const onPaginationChange = (e, i) => {
		setcurrentpage(i);
		fetchData(i, pagesize, searching);
	};

	const handleOnSelect = (row, isSelect) => {
		setSelectedRow(prevSelectedRow => {
			if (isSelect) {
				return [...prevSelectedRow, row?._id];
			} else {
				const removeIndex = prevSelectedRow.findIndex(row => row === row?._id);
				const newSelected = [...prevSelectedRow];
				newSelected.splice(removeIndex, 1);
				return newSelected;
			}
		});
	}

	const handlesearch = (e) => {
		setsearching(e.target.value);
		fetchData(currentpage, pagesize, e.target.value);
	};
	const fetchData = async (page, limit, search) => {
		let body = {
			page,
			limit,
			search,
		};
		await ApiPost("/notification/get", body)
			.then((res) => {
				console.log("resData", res?.data);
				setData(res?.data?.data?.announcement_data);
				settotalpage(res?.data?.data?.state?.page_limit);
			})
			.catch((err) => ErrorToast(err?.message));
	};
	useEffect(() => {
		fetchData(currentpage, pagesize, searching);
	}, []);
	return (
		<>
			<div className="d-flex justify-content-between mb-4">
				<div className="title">
					<div className="fs-20px fw-bolder">Notifications</div>
					<div>
						<span
							role="button"
							onClick={() => history.push("/dashboard")}
							className="text-hover-info text-muted"
						>
							Home
						</span>{" "}
						-{" "}
						<span className="text-muted" role="button">
							{" "}
							Notifications
						</span>
					</div>
				</div>
			</div>
			<div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
				<ToastContainer position="top-right" />
				<div class="card card-custom">
					<div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
						<div class="card-title ">
							{/* <div class="input-icon">
								<input
									type="text"
									class="form-control bg-light"
									name="searchText"
									placeholder="Search by Name"
									value={searching}
									onChange={handlesearch}
									id="kt_datatable_search_query"
								/>
								<span>
									<i class="flaticon2-search-1 text-muted"></i>
								</span>
							</div> */}
						</div>

						<div class="card-toolbar">
							{selectedRow?.length ? <a
								className="btn btn-danger font-weight-bolder btn-sm me-4"
								onClick={handleDeleteSelected}
							>
								Delete
							</a> : null}
							<a
								class="btn btn-color font-weight-bolder btn-sm"
								onClick={() => { setFLoading(false); setModal(true); setNewData({ title: "", description: "" }) }}
							>
								Send Notification
							</a>
						</div>
					</div>
					<div className="card-body mb-5">
						<BootstrapTable
							wrapperClasses="table-responsive"
							bordered={false}
							rowClasses="userRow"
							classes="table table-head-custom table-vertical-center overflow-hidden"
							bootstrap4
							keyField="_id"
							data={data || []}
							columns={columns}
							noDataIndication={() => <NoDataTable />}
							selectRow={{
								mode: 'checkBox',
								clickToSelect: true,
								style: {
									backgroundColor: '#ffede3', borderRadius: "10px",
									padding: "0 10px",
									hideSelectAll: false
								},
								color: '#def3ff',
								onSelect: handleOnSelect,
							}}
						/>
						<div class="d-flex justify-content-between  pt-10">
							<div className="my-2">
								<Pagination
									count={totalpage}
									page={currentpage}
									onChange={onPaginationChange}
									variant="outlined"
									shape="rounded"
									className="pagination_"
								/>
							</div>
							<div class="my-2">
								<div class="d-flex align-items-center pagination-drpdown">
									<select
										class="form-control pagination-drpdown1 dropdownPage"
										id="kt_datatable_search_status"
										onChange={(e) => handleonchnagespagination(e)}
										value={pagesize}
									>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={30}>30</option>
										<option value={50}>50</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={modal} onHide={() => setModal(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>Send Notification</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<Form.Group md="12">
							<Form.Label>Title</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Title"
								label="Title"
								id="title"
								required
								name="title"
								onChange={handleChange}
								value={newData?.title}
							/>
							<span className="errorInput">
								{/* {newData.title?.length > 0 ? "" : errors["title"]} */}
							</span>
						</Form.Group>
						<Form.Group md="12">
							<Form.Label>Type</Form.Label>
							<select name="types" className="form-select" onChange={handleChange}
								value={newData?.types}>
								<option value={0} selected>Select Type</option>
								<option value={1}>Video Playlist</option>
								<option value={2}>Plan</option>
								<option value={3}>Workout</option>
								<option value={4}>Recipe</option>
							</select>
						</Form.Group>
						<Form.Group md="12">
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								placeholder="Enter Description"
								label="Description"
								id="description"
								required
								name="description"
								onChange={handleChange}
								value={newData?.description}
							/>
							<span className="errorInput">
								{/* {newData.title?.length > 0 ? "" : errors["title"]} */}
							</span>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-color" onClick={handleSubmit} disabled={fLoading}>
						{fLoading ? "Loading" : "Send"}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
