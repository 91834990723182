import React, { useState } from "react";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NoDataTable from "../../../common/noDataTable";
import { ApiPost } from "../../../helpers/API/ApiData";
import { ErrorToast } from "../../../helpers/Toast";
import Pagination from "@material-ui/lab/Pagination";

function UserList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");

  const columns = [
    {
      dataField: "member_id",
      text: "Member Id",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "first_name",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? `${row?.first_name} ${row?.last_name}` : "-"}</div>;
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "phone",
      text: "Phone Number",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "days_as_member",
      text: "Days as Member",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "billing_country",
      text: "Billing Country",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
    {
      dataField: "status_name",
      text: "Status Name",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell ? cell : "-"}</div>;
      },
    },
  ];
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    fetchData(currentpage, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  const handlesearch = (e) => {
    setsearching(e.target.value);
    fetchData(currentpage, pagesize, e.target.value);
  };
  const fetchData = async (page, limit, search) => {
    let body = {
      page,
      limit,
      search,
    };
    await ApiPost("/user/get", body)
      .then((res) => {
        console.log("resData", res?.data);
        const output = res?.data?.data?.user_data?.filter((v, i) => {
          if (
            v?.email === "email@address.com" ||
            v?.email === "admin@gmail.com"
          ) {
            return;
          } else {
            return v;
          }
        });
        // setData(res?.data?.data?.user_data);
        setData(output);
        settotalpage(res?.data?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  console.log("data", data);
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Users</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Users
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <ToastContainer position="top-right" />
        <div class="card card-custom">
          <div className="card-body mb-5">
            <div class="row align-items-center">
              <div class="col-lg-12 col-xl-12">
                <div class="row align-items-center">
                  <div class="col-md-3 my-2">
                    <div class="input-icon">
                      <input
                        type="text"
                        class="form-control bg-light"
                        name="searchText"
                        placeholder="Search by Name or Email"
                        value={searching}
                        onChange={(e) => handlesearch(e)}
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              rowClasses="userRow"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => <NoDataTable />}
              // filter={filterFactory()}
              // headerClasses="header-class"
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserList;
