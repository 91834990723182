import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./Dashboard/DashboardPage";
import Plan from "./pages/Plan/Plan";
import UserList from "./pages/User/User_List";
import VideoList from "./pages/VideoList/VideoList";
import VideoPlaylist from "./pages/VideoPlaylist/VideoPlaylist";
import Workout from "./pages/Workout/Workout";
import PlanDetails from "./pages/Plan/PlanDetails";
import Notification from "./pages/Notifications/Notification";
import Nutrition from "./pages/Nutrition/Nutrition";
import Dietary from "./pages/Dietary/Dietary";
import Meal from "./pages/Meal/Meal";
import Recipe from "./pages/Recipe/Recipe";
import WorkoutType from "./pages/WorkoutType/WorkoutType";
import Equipment from "./pages/Equipment/Equipment";
import FocusArea from "./pages/FocusArea/FocusArea";
import RecipePlan from "./pages/Recipe/RecipePlan";
import RecipeDetails from "./pages/Recipe/RecipeDetails";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/user_list" component={UserList} />
        <ContentRoute path="/video_playlist" component={VideoPlaylist} />
        <ContentRoute path="/video_list" component={VideoList} />
        <ContentRoute path="/workout" component={Workout} />
        <ContentRoute path="/workout_type" component={WorkoutType} />
        <ContentRoute path="/equipment" component={Equipment} />
        <ContentRoute path="/focus_area" component={FocusArea} />
        <ContentRoute path="/plan" component={Plan} />
        <ContentRoute path="/plan-details/:id" component={PlanDetails} />
        <ContentRoute path="/recipe-plan-details/:id" component={RecipeDetails} />
        <ContentRoute path="/notification" component={Notification} />
        <ContentRoute path="/nutrition" component={Nutrition} />
        <ContentRoute path="/dietary" component={Dietary} />
        <ContentRoute path="/meal" component={Meal} />
        <ContentRoute path="/recipe" component={Recipe} />
        <ContentRoute path="/recipe-plan" component={RecipePlan} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
