import Select from "react-select";
import React from 'react'
import { Form } from 'react-bootstrap';

export default function VideoListModal({ handleChange, newData, setNewData, workoutTypeData, equipmentData, playlistData }) {
    return (
        <div className="row">
            <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Title"
                    label="Title"
                    id="title"
                    required
                    name="title"
                    onChange={handleChange}
                    value={newData?.title}
                />
            </Form.Group>
            <Form.Group className="col-md-6">
                <Form.Label>Equipments</Form.Label>
                <Select
                    isMulti
                    maxMenuHeight={200}
                    name="colors"
                    placeholder="Select Equipments"
                    isClearable={false}
                    options={equipmentData}
                    className="basic-multi-select"
                    classNamePrefix="selected"
                    value={newData?.equipment}
                    onChange={(e) => setNewData({ ...newData, equipment: e })}
                    styles={{
                        option: (provided) => ({
                            ...provided,
                            color: "black",
                            padding: "0 10px",
                            fontSize: "1.1rem",
                            "&:hover": {
                                backgroundColor: "#007eff",
                                color: "white",
                                fontWeight: "500",
                            },
                            "&:focus-visible": {
                                backgroundColor: "red",
                                color: "white",
                                fontWeight: "500",
                            },
                        }),
                        menu: (provided) => ({
                            ...provided,
                            margin: "0",
                        }),
                        placeholder: (provided) => {
                            return {
                                ...provided,
                                color: "#181c32",
                                fontWeight: "500",
                            };
                        },
                    }}
                />
            </Form.Group>
            <Form.Group className="col-md-6">
                <Form.Label>Workout Type (Edzés típusa)</Form.Label>
                <Select
                    isMulti
                    maxMenuHeight={200}
                    name="colors"
                    placeholder="Select Workout Type"
                    isClearable={false}
                    options={workoutTypeData}
                    className="basic-multi-select"
                    classNamePrefix="selected"
                    value={newData?.workoutType}
                    onChange={(e) => setNewData({ ...newData, workoutType: e })}
                    styles={{
                        option: (provided) => ({
                            ...provided,
                            color: "black",
                            padding: "0 10px",
                            fontSize: "1.1rem",
                            "&:hover": {
                                backgroundColor: "#007eff",
                                color: "white",
                                fontWeight: "500",
                            },
                            "&:focus-visible": {
                                backgroundColor: "red",
                                color: "white",
                                fontWeight: "500",
                            },
                        }),
                        menu: (provided) => ({
                            ...provided,
                            margin: "0",
                        }),
                        placeholder: (provided) => {
                            return {
                                ...provided,
                                color: "#181c32",
                                fontWeight: "500",
                            };
                        },
                    }}
                />
            </Form.Group>
            <Form.Group className="col-md-6">
                <Form.Label>Difficulty (Nehézségi szint)</Form.Label>
                <Form.Control
                    as="select"
                    custom
                    id="difficulty"
                    required
                    className="form-control"
                    name="difficulty"
                    value={newData?.difficulty}
                    onChange={handleChange}
                >
                    <option value="" disabled selected>
                        Select Difficulty
                    </option>
                    <option value="1">Haladó (Harcosképző)</option>
                    <option value="2">Kezdő (Light)</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="col-lg-6">
                <Form.Label>Duration (Hosszúság)</Form.Label>
                <Form.Control
                    as="select"
                    custom
                    id="duration"
                    required
                    className="form-control"
                    name="duration"
                    value={newData?.duration}
                    onChange={handleChange}
                >
                    <option value="" selected disabled>
                        Select Duration
                    </option>
                    <option value="0-5">0-5</option>
                    <option value="5-10">5-10</option>
                    <option value="10-20">10-20</option>
                    <option value="20-30">20-30</option>
                    <option value="30-40">30-40</option>
                    <option value="40-50">40-50</option>
                    <option value="50-60">50-60</option>
                    <option value="60-70">60-70</option>
                    <option value="70-80">70-80</option>
                    <option value="80-90">80-90</option>
                </Form.Control>
            </Form.Group>
            <Form.Group className="col-lg-6">
                <Form.Label>Video Playlist</Form.Label>
                <Form.Control
                    as="select"
                    custom
                    id="playlist"
                    required
                    className="form-control"
                    name="playlist"
                    value={newData?.playlist}
                    onChange={handleChange}
                >
                    <option value="0" disabled>
                        Select Playlist
                    </option>
                    {playlistData.length > 0 && playlistData.map((item, i) => {
                        return <option value={item._id}>{item.title}</option>;
                    })}
                </Form.Control>
            </Form.Group>
            <Form.Group className="col-md-6">
                <Form.Label>Video</Form.Label>
                <Form.Control
                    type="input"
                    // type="file"
                    placeholder="Enter video Url"
                    label="url"
                    id="url"
                    accept="video/mp4,video/x-m4v,video/*"
                    className="form-control"
                    required
                    name="url"
                    onChange={handleChange}
                    value={newData?.url}
                />
            </Form.Group>
            <Form.Group className="col-md-6">
                <Form.Label>Thumbnail</Form.Label>
                <Form.Control
                    type="input"
                    label="thumbnailUrl"
                    id="thumbnailUrl"
                    required
                    className="form-control"
                    name="thumbnailUrl"
                    placeholder="Enter thumbnail Url"
                    onChange={handleChange}
                    value={newData?.thumbnailUrl}

                />
                <span className="errorInput">
                    {/* {newData.thumbnailUrl?.length > 0 ? "" : errors["thumbnailUrl"]} */}
                </span>
            </Form.Group>
            <Form.Group className="col-lg-6">
                <Form.Label>Calories</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter calories"
                    label="calories"
                    id="calories"
                    required
                    name="calories"
                    onChange={handleChange}
                    value={newData?.calories}
                />
                <span className="errorInput">
                    {/* {newData.calories?.length > 0 ? "" : errors["calories"]} */}
                </span>
            </Form.Group>
            <Form.Group className="col-lg-12">
                <Form.Label>Tag</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Tag"
                    label="tag"
                    id="tag"
                    required
                    name="tag"
                    onChange={handleChange}
                    value={newData?.tag}
                />
                <span className="errorInput">
                    {/* {newData.tag?.length > 0 ? "" : errors["tag"]} */}
                </span>
            </Form.Group>
            <Form.Group md="12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Enter Description"
                    label="description"
                    id="description"
                    required
                    name="description"
                    onChange={handleChange}
                    value={newData?.description}
                />
                <span className="errorInput">
                    {/* {newData.description?.length > 0 ? "" : errors["description"]} */}
                </span>
            </Form.Group>
        </div>
    )
}
