import React, { useState } from "react";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import NoDataTable from "../../../common/noDataTable";
import {
	ApiDelete,
	ApiPost,
	ApiPut,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import moment from "moment";
import { Form, Modal } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import Swal from "sweetalert2";

export default function WorkoutType() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [modal, setModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [searching, setSearching] = useState("");
	const [totalPage, setTotalPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [newData, setNewData] = useState({
		title: "",
	});
	const columns = [
		{
			dataField: "title",
			text: "Name",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell || "-"}</div>;
			},
		},
		{
			dataField: "createdAt",
			text: "Created At",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell && moment(cell).format("DD-MM-YYYY HH:mm A")}</div>;
			},
		},
		{
			dataField: "action",
			text: "Action",
			sort: true,
			formatter: (cell, row) => {
				return (
					<>
						<a
							title="Edit customer"
							className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
							onClick={() => editBtnClick(row)}
						>
							<span className="svg-icon svg-icon-md svg-icon-primary">
								<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Communication/Write.svg"
									)}
								/>
							</span>
						</a>

						<a
							title="Delete customer"
							className="btn btn-icon btn-light btn-hover-danger btn-sm"
							onClick={() => {
								Swal.fire({
									text: `Are you sure you want to Delete ?`,
									icon: "warning",
									showCancelButton: true,
									showConfirmButton: true,
									confirmButtonText: `Yes, Delete`,
									confirmButtonColor: "#D72852",
									cancelButtonColor: "transparent",
									cancelButtonText: "No, Cancel",
								}).then((res) => {
									if (res.isConfirmed) {
										onDelete(row?._id);
									}
								});
							}}
						>
							<span className="svg-icon svg-icon-md svg-icon-danger">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
								/>
							</span>
						</a>
					</>
				);
			},
		},
	];
	const handleChange = (e) => {
		const { value, name } = e.target;
		setNewData({ ...newData, [name]: value });
	};
	const editBtnClick = (row) => {
		console.log("row", row);
		setIsEdit(true);
		setModal(true);
		setNewData({
			id: row?._id,
			title: row?.title,
		});
	};
	const onDelete = async (Id) => {
		await ApiDelete(`/workoutType/${Id}`)
			.then(() => {
				fetchData(currentPage, pageSize, "");
				Swal.fire({
					text: "You have Deleted Successfully!!!",
					icon: "success",
					confirmButtonText: "Ok, Got it!",
					confirmButtonColor: "#338DE6",
				});
			})
			.catch((err) => ErrorToast(err?.message));
	};
	const onUpdate = async () => {
		let body = {
			id: newData?.id,
			title: newData.title,
		};
		await ApiPut("/workoutType", body)
			.then((res) => {
				SuccessToast(res?.data?.message);
				setModal(false);
				fetchData(currentPage, pageSize, "");
			})
			.catch((err) => ErrorToast(err?.message));
	};

	const handleSubmit = async () => {
		let body = {
			title: newData.title,
		};
		await ApiPost("/workoutType", body)
			.then((res) => {
				SuccessToast(res?.data?.message);
				setModal(false);
				fetchData(currentPage, pageSize, "");
			})
			.catch((err) => ErrorToast(err?.message));
	};
	const handleOnChangePagination = (e) => {
		setPageSize(e.target.value);
		setCurrentPage(1);
		fetchData(1, parseInt(e.target.value), "");
	};
	const onPaginationChange = (e, i) => {
		setCurrentPage(i);
		fetchData(i, pageSize, "");
	};
	const handleSearch = (e) => {
		setSearching(e.target.value);
		fetchData(currentPage, pageSize, e.target.value);
	};
	const fetchData = async (page, limit, search) => {
		let body = {
			page,
			limit,
			search,
		};
		await ApiPost("/workoutType/get", body)
			.then((res) => {
				console.log("resData", res?.data);
				setData(res?.data?.data?.response);
				setTotalPage(res?.data?.data?.state?.page_limit);
			})
			.catch((err) => ErrorToast(err?.message));
	};

	useEffect(() => {
		fetchData(currentPage, pageSize, "");
	}, []);
	return (
		<>
			<div className="d-flex justify-content-between mb-4">
				<div className="title">
					<div className="fs-20px fw-bolder">Workout Types</div>
					<div>
						<span
							role="button"
							onClick={() => history.push("/dashboard")}
							className="text-hover-info text-muted"
						>
							Home
						</span>{" "}
						-{" "}
						<span className="text-muted" role="button">
							{" "}
							Workout Type
						</span>
					</div>
				</div>
			</div>
			<div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
				<div class="card card-custom">
					<div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
						<div class="card-title ">
							{/* <h3 class="card-label">Video Playlist</h3> */}
							<div class="input-icon">
								<input
									type="text"
									class="form-control bg-light"
									name="searchText"
									placeholder="Search by Name"
									value={searching}
									onChange={handleSearch}
									id="kt_datatable_search_query"
								/>
								<span>
									<i class="flaticon2-search-1 text-muted"></i>
								</span>
							</div>
						</div>

						<div class="card-toolbar">
							<a
								class="btn btn-color font-weight-bolder btn-sm"
								onClick={() => {
									setIsEdit(false);
									setModal(true);
									setNewData({ title: "" });
								}}
							>
								Add Workout Type
							</a>
						</div>
					</div>
					<div className="card-body mb-5">
						<BootstrapTable
							wrapperClasses="table-responsive"
							bordered={false}
							classes="table table-head-custom table-vertical-center overflow-hidden"
							bootstrap4
							keyField="_id"
							// selectRow={selectRow}
							data={data}
							columns={columns}
							// pagination={paginationFactory(options)}
							// defaultSorted={defaultSorted}
							noDataIndication={() => <NoDataTable />}
						// filter={filterFactory()}
						/>
						<div class="d-flex justify-content-between  pt-10">
							<div className="my-2">
								<Pagination
									count={totalPage}
									page={currentPage}
									onChange={onPaginationChange}
									variant="outlined"
									shape="rounded"
									className="pagination_"
								/>
							</div>
							<div class="my-2">
								<div class="d-flex align-items-center pagination-drpdown">
									<select
										class="form-control pagination-drpdown1 dropdownPage"
										id="kt_datatable_search_status"
										onChange={(e) => handleOnChangePagination(e)}
										value={pageSize}
									>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={30}>30</option>
										<option value={50}>50</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={modal} onHide={() => setModal(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>
						{!isEdit ? "Add" : "Edit"}{" "}Workout Type
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<Form.Group md="12">
							<Form.Label>Workout Type</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Workout Type"
								label="Title"
								id="title"
								required
								name="title"
								onChange={handleChange}
								value={newData.title}
							/>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{isEdit ? (
						<button className="btn btn-color" onClick={onUpdate}>
							Update Changes
						</button>
					) : (
						<button className="btn btn-color" onClick={handleSubmit}>
							Submit
						</button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}
